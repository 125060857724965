import {createSlice} from '@reduxjs/toolkit'
const initialState={shopDate:'2025-01-01',
                    }
/*Slice najpierw klonuje i potem nadpisuje dlatego nie trzeba
kazdej wlasności wpisywać jeszcze raz i mozna uzywać ++*/
const envSlice = createSlice({
    name: "env",
    initialState:initialState,
    reducers:{
        
      setShopDate(state,value){
        state.shopDate=value
      },
        
        
       
    }
})
export const envActions =envSlice.actions
export default envSlice.reducer