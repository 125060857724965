import styles from './accountLine.module.css'
function AccountLine(props){
    return(
        <div className={styles.block}>
            <p className={styles.label}>{props.label}</p>
            {props.data!=null && props.data!='' && <p className={styles.data}>{props.data}</p>}
            {props.data==null && <p className={styles.data}>-</p>}
            {props.data=='' && <p className={styles.data}>-</p>}
        </div>
    )
}
export default AccountLine