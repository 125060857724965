import Sidebar from "../../sidebar/sidebar"
import styles from "./editSlug.module.css"
import Menu from "./menu"
import Tlo from "../../../landing/blob2.png"
function EditSlug(){
    return (
    <div className={styles.block}>
        <Sidebar/>
        <div className={styles.menu}>
            <img src={Tlo} alt="" className={styles.tlo}/>
            <Menu/>
        </div>
        
    </div>

    )
}

export default EditSlug