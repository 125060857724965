import styles from "../manageMenu/modal.module.css"
import {AiFillDelete} from 'react-icons/ai'
import axios from 'axios'
import { useSelector } from "react-redux"
function DeleteModal(props){

    const authToken=useSelector((state)=>state.auth.access)

    function okChandler(e){
        props.setDeleteModal(0)
    }

    function okChandlerDel(e){
        props.setDeleteModal(0)
        window.location.reload()
    }

    async function editPut(){
        const putData=JSON.stringify({id:props.id})
            
        
        const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/nam_delete`,putData, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
             
                    props.setDeleteModal(2)
                    
                   
                })
                .catch(function(error){
                    
                     
                  
                    
                })
                .finally(function(){
                    
                })
    }

    const style={color:"dark-red",fontSize:'30px'}
    return(
        <div className={styles.modal}>
            {props.deleteModal==1  &&
                <div className={styles.modal_content}>

            
                
                    <AiFillDelete style={style}/>
                    <div className={styles.label}>Are you sure you want to delete this record? </div>
                    <div className={styles.buttonRow}>
                        <button className={styles.OkGood} onClick={editPut}>Yes</button>
                        <button className={styles.OkGood} onClick={okChandler}>No</button>
                    </div>
                
                
                </div>
            }
            {props.deleteModal==2  &&
                <div className={styles.modal_content}>
                    <AiFillDelete style={style}/>
                    <div className={styles.label}>Record was deleted </div>
                    
                        
                        <button className={styles.OkGood} onClick={okChandlerDel}>Ok</button>
                    
                </div>
                }
                
            
        </div>
    )
}
export default DeleteModal