import logo from './logoNet.png'
import styles from './logo.module.css'
import { Link } from 'react-router-dom';
function Logo() {
    return (
        <div className={styles.block}>
            <div className={styles.logo}>
                   
            <Link className={styles.link} to="/">
                <img src={logo} alt="Logo" className={styles.logoSize}/>
                
            </Link>
                    

            </div>  
            
        </div>
    );
  }
  
  export default Logo;