import styles from "./ciastek.module.css";
import { useState, useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import ReactGA from "react-ga4";
import { Link, useLocation } from "react-router-dom";

function Ciastek() {
	const TRACKING_ID = "G-2PCHSVWYG0";
	const location = useLocation();
	const curUrl = location.pathname;

	const [data, setData] = useState(sessionStorage.getItem("key"));

	function accept() {
		sessionStorage.setItem("key", "close C");
		ReactGA.initialize(TRACKING_ID);
		setData(sessionStorage.getItem("key"));
	}
	function close() {
		sessionStorage.setItem("key", "close C");
		setData(sessionStorage.getItem("key"));
	}
	return (
		<div>
			{data != "close C" && curUrl != "/cookie_policy" ? (
				<div className={styles.block}>
					<div className={styles.header}>Your privacy</div>
					<div className={styles.desc}>
						By clicking "Accept all cookies" you agree Nams Network
						can store cookies on your device and disclose
						information in accordance with our{" "}
						<Link to="/cookie_policy">Cookie policy</Link>
					</div>
					<div className={styles.buttonRow}>
						<button className={styles.accept} onClick={accept}>
							Accept all cookies
						</button>
						<button className={styles.nec} onClick={close}>
							Necessary cookies only
						</button>
					</div>
				</div>
			) : (
				<div className={styles.blockClosed}></div>
			)}
		</div>
	);
}
export default Ciastek;
