import styles from "./aopKeMie.module.css"
function AopKeMie(props){
    return(
        <div>
                <div className={styles.subTitle2}>This NAM is linked to an AOP/KE/MIE</div>


                <div className={styles.breakAop}></div>


                {props.aop!=undefined &&
                <div className={styles.row}>
                    <div className={styles.subTitle}>AOP:</div>
                    <div className={styles.genElement}>{props.aop}</div>
                </div>
                }

                {props.aopRef!='-' &&
                <div className={styles.row}>
                    <div className={styles.subTitle}>Link:</div>
                    <div className={styles.genElement}>{props.aopRef!='-'?<a target="_blank" href={props.aopRef}>{props.aopRef}</a>:<div>{props.aopRef}</div>}</div>
                </div>
                }

                {props.keyEvent!=undefined &&
                <div className={styles.row}>
                    <div className={styles.subTitle}>KE:</div>
                    <div className={styles.genElementReg}>{props.keyEvent}</div>
                </div>
                }

                { props.keyEventRef!='-'&&
                <div className={styles.row}>
                    <div className={styles.subTitle}>Link(s):</div>
                    <div className={styles.genElement}>{props.keyEventRef!='-'?<a target="_blank" href={props.keyEventRef}>{props.keyEventRef}</a>:<div>{props.keyEventRef}</div>}</div>
                </div>
                }

                {props.mie!=undefined &&
                <div className={styles.row}>
                    <div className={styles.subTitle}>MIE:</div>
                    <div className={styles.genElementReg}>{props.mie}</div>
                </div>
                }

                {props.mieRef!=undefined &&
                <div className={styles.row}>
                    <div className={styles.subTitle}>Link(s):</div>
                    <div className={styles.genElement}>{props.mieRef!='-'?<a target="_blank" href={props.mieRef} >{props.mieRef}</a>:<div>{props.mieRef}</div>}</div>
                </div>
                }

                
            </div>
    )
}
export default AopKeMie