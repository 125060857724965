import { components } from "react-select";
import  Select  from "react-select";
import { useSelector, useDispatch } from 'react-redux'
import {filterActions} from  '../../../../store/filterSlice'
import { addActions } from "../../../../store/addSlice";
import {useEffect} from 'react'
import styles from "./dropdown_F.module.css"
import { MultiSelect } from 'primereact/multiselect';
import {useEffetc,useState} from 'react'
function DropdownOrgan(props) {
    const dispatch = useDispatch()
  
    const [selectedCities, setSelectedCities] = useState();
    const name=props.name
    const [isStart,setIsStart]=useState(0)
    let dataSes=[]
    
    if (name=='organ'){
        dataSes=JSON.parse(localStorage.getItem('organ'))
      
        
    }
    if (name=='test'){
        dataSes=JSON.parse(localStorage.getItem('test'))
    }
    if (name=='regulations'){
        dataSes=JSON.parse(localStorage.getItem('regulations'))
    }
    if (name=='stage'){
        dataSes=JSON.parse(localStorage.getItem('stage'))
    }
    if (name=='endpoints'){
        dataSes=JSON.parse(localStorage.getItem('endpoints'))
    }
    if (name=='aops'){
        dataSes=JSON.parse(localStorage.getItem('aops'))
    }
    if (name=='type'){
        dataSes=JSON.parse(localStorage.getItem('type'))
    }
    if (name=='used'){
        dataSes=JSON.parse(localStorage.getItem('used'))
    }
    
    if (name=='name'){
        dataSes=JSON.parse(localStorage.getItem('name'))
    }
    
    
    const handleChangeTest  = (selected) => {
       
       
        
       
        setSelectedCities(selected.value
             )
        
       
      
       if (props.name==="organ"){
       
            if (selected.length!=0){
            
                dispatch(filterActions.setOrgans(selected.value))
                localStorage.setItem('organ', JSON.stringify(selected.value))
            
            }
            else{   
          
                dispatch(filterActions.setOrgans())  
                localStorage.removeItem('organ')
                
            }
        }

        if (props.name==="test"){
            if (selected.length!=0){
                dispatch(filterActions.setTests(selected.value))
                localStorage.setItem('test', JSON.stringify(selected.value))
       
            }
            else{   
                dispatch(filterActions.setTests())  
                localStorage.removeItem('test')
            }
        }

        if (props.name==="regulations"){

          if (selected.length!=0){
              dispatch(filterActions.setRegulations(selected.value))
              localStorage.setItem('regulations', JSON.stringify(selected.value))
          
          }
          else{   
              dispatch(filterActions.setRegulations())  
              localStorage.removeItem('regulations')
          }
      }

      if (props.name==="stage"){
   
        if (selected.length!=0){
            dispatch(filterActions.setStages(selected.value))
            localStorage.setItem('stage', JSON.stringify(selected.value))
   
        }
        else{   
            dispatch(filterActions.setStages())  
            localStorage.removeItem('stage')
        }
    }

    if (props.name==="endpoints"){
  
      if (selected.length!=0){
          dispatch(filterActions.setEndpoints(selected.value))
          localStorage.setItem('endpoints', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(filterActions.setEndpoints())  
          localStorage.removeItem('endpoints')
      }
  

    }

    if (props.name==="aops"){
   
      if (selected.length!=0){
          dispatch(filterActions.setAops(selected.value))
          localStorage.setItem('aops', JSON.stringify(selected.value))
      
      }
      else{   
          dispatch(filterActions.setAops())  
          localStorage.removeItem('aops')
      }
  

    }

    if (props.name==="type"){
   
      if (selected.length!=0){
          dispatch(filterActions.setNamType(selected.value))
          localStorage.setItem('type', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(filterActions.setNamType())  
          localStorage.removeItem('type')
      }
  

    }


    if (props.name==="used"){
   
      if (selected.length!=0){
          dispatch(filterActions.setUsed(selected.value))
          localStorage.setItem('used', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(filterActions.setUsed())  
          localStorage.removeItem('used')
      }
  

    }


    if (props.name==="name"){
   
        if (selected.length!=0){
            dispatch(filterActions.setName(selected.value))
            localStorage.setItem('name', JSON.stringify(selected.value))
       
        }
        else{   
            dispatch(filterActions.setName())  
            localStorage.removeItem('name')
        }
    
  
      }


    

    }

    

    useEffect(()=>{
       
        
        
        
        
        
      
    },[props.data])
    
    return(
        <div>
           {isStart==0 && <div><MultiSelect value={dataSes} onChange={handleChangeTest} options={[...new Set(dataSes.concat(props.data).map(item => item.name))].sort().map(item=>({name:item,code:item}))} optionLabel="name" display="chip"
       placeholder="Select"  className={styles.mypanel} filter/></div>}
            {isStart==1 && <div>
                <MultiSelect value={selectedCities} onChange={handleChangeTest} options={[...new Set(dataSes.concat(props.data).map(item => item.name))].map(item=>({name:item,code:item}))} optionLabel="name" display="chip"
       placeholder="Select"  className={styles.mypanel} />
                </div>}
          
        </div>
    )
}
export default DropdownOrgan