import styles from "./metaDataProvider.module.css"
import MainForm from "./mainForm"
import { useDispatch } from "react-redux"
import { addActions } from "../../../store/addSlice"
function MetaDataProvider (){
    const dispatch=useDispatch()
    function nameChandler(e){
        dispatch(addActions.setName(e.target.value))
    }
    
    return(
    <div className={styles.formBlock}>
        <div className={styles.row}>
            <div className={styles.label}>Name:</div>
            <div className={styles.name}>
                <input onChange={nameChandler} type="text" id={styles.name_in} placeholder="Insert NAM name here" required/>
            </div>
        </div>
        <div className={styles.row}>
            <MainForm/>
        </div>
        
        
    </div>
    )
}

export default MetaDataProvider