import UserButton from './UserButton';
import styles from './menu.module.css'
import { Link } from 'react-router-dom';
import { useSelector} from 'react-redux'
import {useState} from 'react'
import{GiCoins} from 'react-icons/gi'
import { useEffect } from 'react';
import axios from 'axios';
import {FaUserCircle} from 'react-icons/fa'

function Menu() {
    const email= useSelector((state) => state.auth.email)
    const authToken=useSelector((state)=>state.auth.access)
    const isAdmin = useSelector(state=>state.auth.isAdmin)
    const iconStyles={color:'rgb(177, 177, 177)', fontSize:'40px'}
    const [coins,setCoins]=useState(0)
    const shopDate=Date.parse(useSelector((state)=>state.env.shopDate))
    
    const today = new Date()
  
    
    async function getCoins () {
      
        
       
         let data=JSON.stringify({'email':email})
         
         const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/check_tokens`,data, {
             headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
             }
         })
         
             .then(function(response){
                 setCoins(response.data)
                
                
             })
             .catch(function(error){
                
             })
             .finally(function(){
                 
             })
             return
     }

     useEffect(()=>{
    getCoins()
      
    },[])
    
    return (
        <div className={styles.block}>
            <div className={styles.menu_el}>
                <Link className={styles.link} to="/">{ window.location.pathname.split( '/' )[1]==='' ?<div className={styles.text_active}>Home</div>:
                <div className={styles.text}>Home</div>}</Link>
                <Link className={styles.link} to="/explore">{ window.location.pathname.split( '/' )[1]==='explore' ?<div className={styles.text_active}>NAMs Database</div>:
                <div className={styles.text}>NAMs Database</div>}</Link> 
             
                <Link className={`${styles.link} ${styles.extraLink}`} to="/about">{ window.location.pathname.split( '/' )[1]==='about' ?<div className={styles.text_active}>About Us</div>:
                <div className={styles.text}>About Us</div>}</Link> 
 
            </div>
            <div className={styles.user_menu}>
                
                <div className={styles.emAndBut}>
                    {email &&<div className={styles.email}>Hello, {email}</div>}
                    {isAdmin!=null &&<Link to='/userInfo'><button className={styles.userIconBut}><FaUserCircle className={styles.userIcon}/></button></Link>}
                    <UserButton/>
                </div>
            </div>
        </div>
    );
  }
  
  export default Menu;