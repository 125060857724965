import styles from './userBlock.module.css'
import { Link } from 'react-router-dom'
import { LiaUserCogSolid } from "react-icons/lia";
import { LiaUserEditSolid } from "react-icons/lia";
import { LiaUser } from "react-icons/lia";
function UserBlock(props){
    return(
       <Link to={`/accounts/${props.id}`} className={styles.accountLink}>
            <div className={styles.mainBlock}>
                
                
                <div className={styles.firstRow}>
                    {props.admin ?<div className={`${styles.adminBlock} ${styles.block}`}><LiaUserCogSolid className={styles.icon}/></div>:
                    props.moderator? <div className={`${styles.moderatorBlock} ${styles.block}`}><LiaUserEditSolid className={styles.icon}/></div>:
                    props.ambasador?<div className={`${styles.ambasadorBlock} ${styles.block}`}><LiaUser className={styles.icon}/></div>:
                    <div className={`${styles.userBlock} ${styles.block}`}><LiaUser className={styles.icon}/></div>}
                    <div className={styles.email}>{props.email}</div>
                    {props.admin ?  <div className={styles.company}>{props.admin && <div className={styles.adminTxt}>Admin</div>} {props.moderator&& <div className={styles.moderatorTxt}>Moderator</div>} {props.ambasador&& <div className={styles.ambasadorTxt}>Ambasador</div>}</div>:
                    props.moderator? <div className={styles.company}>{props.admin && <div className={styles.adminTxt}>Admin</div>} {props.moderator&& <div className={styles.moderatorTxt}>Moderator</div>} {props.ambasador&& <div className={styles.ambasadorTxt}>Ambasador</div>}</div>:
                    props.ambasador?<div className={styles.company}>{props.admin && <div className={styles.adminTxt}>Admin</div>} {props.moderator&& <div className={styles.moderatorTxt}>Moderator</div>} {props.ambasador&& <div className={styles.ambasadorTxt}>Ambasador</div>}</div>:null}
                    {!props.admin && !props.moderator && ! props.ambasador &&<div className={styles.company}><div className={styles.userTxt}>User</div></div>}
                </div>
                <div className={styles.secondRow}>
                    <div className={styles.dateLabel}>Creation Date:</div>
                    <div className={styles.date}>{props.date}</div>
                </div>
                
                
                
                
            </div>
        </Link> 
    )
}
export default UserBlock