import styles from "./modal.module.css"
function Modal(props){

    function okChandler(e){
        props.setModal(0)
    }

    return(
        <div className={styles.modal}>
            <div className={styles.modal_content}>
                <div>{props.error}</div>
                <button className={styles.Ok} onClick={okChandler}>Ok</button>
            </div>
        </div>
    )
}
export default Modal