import Table from "./table/table";

import Filter from "./filter/filter";
import styles from "./expMenu.module.css"
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { useSelector } from "react-redux";
import { authActions } from '../../../store/authSlice'
import { useDispatch } from 'react-redux'
import { filterActions } from "../../../store/filterSlice"
import FiltersExpander from "./filter/filterExpander";
import Tlo from '../../landing/blob2.png'
function ExpMenu() {
    const dispatch=useDispatch()
    const authToken=useSelector((state)=>state.auth.access)
    const stages= useSelector((state) => state.filter.stages)
    const organs= useSelector((state) => state.filter.organs)
  
    const endpoints= useSelector((state) => state.filter.endpoints)
    const tests= useSelector((state) => state.filter.tests)
    const regulations = useSelector((state)=>state.filter.regulations)
    const aops= useSelector((state)=>state.filter.aops)
    const used= useSelector((state)=>state.filter.used)
    const namType= useSelector((state)=>state.filter.namType)
    const name= useSelector((state)=>state.filter.name)
    

    
    


    

    
   
    
    
    
        
    return (
        <div className={styles.menu}>
            <img src={Tlo} alt="" className={styles.tlo}/>
            <div className={styles.filter}>
           <FiltersExpander stages={stages} endpoints={endpoints} tests={tests} regulations={regulations} aops={aops} used={used} namType={namType} authToken={authToken} organs={organs} name={name}/>
           </div>
           <Table />
        </div>
           
        
    );
  }
  
  export default ExpMenu;