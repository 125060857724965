import { useEffect, Fragment, useState } from "react"
import ListNews from "./ListNews";
import ModalNews from "./ModalNews";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { Button, Row, Col } from "react-bootstrap";
import { addDays } from 'date-fns';
import styles from "./ManageNews.module.css"
import Tlo from "../landing/blob2.png"
function PatchNotes(){

    const [modalIsOpen, setIsOpen] = useState(false);
    const [news, setNews] = useState([])
    const [filteredNews, setFilteredNews] = useState([])
    
    function openModal() {
        setIsOpen(true);
  }

    function closeModal() {
        setIsOpen(false);
  }

    const [dates, setDates] = useState([
        {
        startDate: null,
        endDate: null,
        key: 'selection'
        }
    ]);

    useEffect(() => {
        try {
            if ((Number.isInteger(dates[0].startDate.getTime())) && (Number.isInteger(dates[0].endDate.getTime()))) {
                
                setFilteredNews(news.filter(
                    (obj) => {
                        return new Date(obj.date.substring(0, 19)).getTime() >= dates[0].startDate.getTime() && new Date(obj.date.substring(0, 19)).getTime() <= dates[0].endDate.getTime()+6000000 // + bc dif timezone
                    }
                ))
            } else {
                
                setFilteredNews(news)
            }
        } catch (error) {}
        
    },[dates, news])

    function clearHandler() {
        setDates([
            {
            startDate: null,
            endDate: null,
            key: 'selection'
            }
        ])
    }

    return (
       
        <div className={styles.rowMargin}>
            <img src={Tlo} alt="" className={styles.tlo}/>
            <Col  >
                <div className={styles.wrapDiv}>
                    <h2 className={`${styles.h2} ${styles.inline}`}>Patch Notes</h2>
                </div>
                {(dates[0].startDate) ? (<ListNews news={filteredNews} setNews={setNews} editable={false}/>)
                :<ListNews news={news} setNews={setNews} editable={false}/>}
               
            </Col>
            
            <div className={styles.center}  >
                <h2 className={styles.h2}>Select dates</h2>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => setDates([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dates}
                    rangeColors={['#8eca6c']}
                    scroll={true}
                />
                <Button onClick={clearHandler} className={`${styles.button} ${styles.buttonClear}`}>CLEAR</Button>
            </div>
            
        </div>
   
    )
}

export default PatchNotes