import { components } from "react-select";
import  Select  from "react-select";
import { useSelector, useDispatch } from 'react-redux'
import {LabsFilterActions} from "../../../../../store/labsFilterSlice"
import { addActions } from "../../../../../store/addSlice";
import {useEffect} from 'react'
import styles from "./dropdown_F.module.css"
import { MultiSelect } from 'primereact/multiselect';
import {useEffetc,useState} from 'react'
function DropdownOrgan(props) {
    const dispatch = useDispatch()
  
    const [selectedCities, setSelectedCities] = useState();
    const name=props.name
    const [isStart,setIsStart]=useState(0)
    let dataSes=[]
    
    if (name=='organ'){
        dataSes=JSON.parse(localStorage.getItem('organLabs'))
      
        
    }
    if (name=='test'){
        dataSes=JSON.parse(localStorage.getItem('testLabs'))
    }
    if (name=='regulations'){
        dataSes=JSON.parse(localStorage.getItem('regulationsLabs'))
    }
    if (name=='stage'){
        dataSes=JSON.parse(localStorage.getItem('stageLabs'))
    }
    if (name=='endpoints'){
        dataSes=JSON.parse(localStorage.getItem('endpointsLabs'))
    }
    if (name=='aops'){
        dataSes=JSON.parse(localStorage.getItem('aopsLabs'))
    }
    if (name=='type'){
        dataSes=JSON.parse(localStorage.getItem('typeLabs'))
    }
    if (name=='used'){
        dataSes=JSON.parse(localStorage.getItem('usedLabs'))
    }
    
    if (name=='name'){
        dataSes=JSON.parse(localStorage.getItem('nameLabs'))
    }
    
    
    const handleChangeTest  = (selected) => {
       
       
        
       
        setSelectedCities(selected.value
             )
        
       
      
       if (props.name==="organ"){
       
            if (selected.length!=0){
            
                dispatch(LabsFilterActions.setOrgans(selected.value))
                localStorage.setItem('organLabs', JSON.stringify(selected.value))
            
            }
            else{   
          
                dispatch(LabsFilterActions.setOrgans())  
                localStorage.removeItem('organLabs')
                
            }
        }

        if (props.name==="test"){
            if (selected.length!=0){
                dispatch(LabsFilterActions.setTests(selected.value))
                localStorage.setItem('testLabs', JSON.stringify(selected.value))
       
            }
            else{   
                dispatch(LabsFilterActions.setTests())  
                localStorage.removeItem('testLabs')
            }
        }

        if (props.name==="regulations"){

          if (selected.length!=0){
              dispatch(LabsFilterActions.setRegulations(selected.value))
              localStorage.setItem('regulationsLabs', JSON.stringify(selected.value))
          
          }
          else{   
              dispatch(LabsFilterActions.setRegulations())  
              localStorage.removeItem('regulationsLabs')
          }
      }

      if (props.name==="stage"){
   
        if (selected.length!=0){
            dispatch(LabsFilterActions.setStages(selected.value))
            localStorage.setItem('stageLabs', JSON.stringify(selected.value))
   
        }
        else{   
            dispatch(LabsFilterActions.setStages())  
            localStorage.removeItem('stageLabs')
        }
    }

    if (props.name==="endpoints"){
  
      if (selected.length!=0){
          dispatch(LabsFilterActions.setEndpoints(selected.value))
          localStorage.setItem('endpointsLabs', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(LabsFilterActions.setEndpoints())  
          localStorage.removeItem('endpointsLabs')
      }
  

    }

    if (props.name==="aops"){
   
      if (selected.length!=0){
          dispatch(LabsFilterActions.setAops(selected.value))
          localStorage.setItem('aopsLabs', JSON.stringify(selected.value))
      
      }
      else{   
          dispatch(LabsFilterActions.setAops())  
          localStorage.removeItem('aopsLabs')
      }
  

    }

    if (props.name==="type"){
   
      if (selected.length!=0){
          dispatch(LabsFilterActions.setNamType(selected.value))
          localStorage.setItem('typeLabs', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(LabsFilterActions.setNamType())  
          localStorage.removeItem('typeLabs')
      }
  

    }


    if (props.name==="used"){
   
      if (selected.length!=0){
          dispatch(LabsFilterActions.setUsed(selected.value))
          localStorage.setItem('usedLabs', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(LabsFilterActions.setUsed())  
          localStorage.removeItem('usedLabs')
      }
  

    }


    if (props.name==="name"){
   
        if (selected.length!=0){
            dispatch(LabsFilterActions.setName(selected.value))
            localStorage.setItem('nameLabs', JSON.stringify(selected.value))
       
        }
        else{   
            dispatch(LabsFilterActions.setName())  
            localStorage.removeItem('nameLabs')
        }
    
  
      }


    

    }

 

    useEffect(()=>{
       
        
        
        
        
        
      
    },[props.data])
    
    return(
        <div>
           {isStart==0 && <div className={styles.test}><MultiSelect value={dataSes} onChange={handleChangeTest} options={[...new Set(dataSes.concat(props.data).map(item => item.name))].sort().map(item=>({name:item,code:item}))} optionLabel="name" display="chip"
       placeholder="Select"  className={styles.mypanel} filter/></div>}
            {isStart==1 && <div>
                <MultiSelect value={selectedCities} onChange={handleChangeTest} options={[...new Set(dataSes.concat(props.data).map(item => item.name))].map(item=>({name:item,code:item}))} optionLabel="name" display="chip"
       placeholder="Select"  className={styles.mypanel} />
                </div>}
          
        </div>
    )
}
export default DropdownOrgan