import styles from './emailConfirm.module.css'
import {useParams} from "react-router-dom"
import { GiConfirmed} from "react-icons/gi";
import { useEffect, useState } from 'react';
import FourHundred from './404';
import axios from 'axios';

function EmailConfirm(){
    const {slug} = useParams()
    const [check,setCheck]=useState()
    async function confirm(){
        const data=JSON.stringify({
            user_token:slug})
      
        const requestData= await axios.post(`${process.env.REACT_APP_DOMAIN}/api/confirm_account/`,data, {
            headers:{
                'Content-Type':'application/json'
            }})

        .then(function(response){
           setCheck(true)

            
        })
        .catch(function(error){
          
            setCheck(false)
        })
        .finally(function(){
            
        })
        return
    }
    useEffect(()=>{
        confirm()
    },[])
    return (
        <div className={styles.block}>
            {check == true &&
            <div className={styles.subBlock}>
                <div className={styles.header}>Your account has been confirmed</div>
                
            
                <GiConfirmed className={`${styles.icon} ${styles.confirm}`}/>
            
            </div>
            }
            {check == false &&
            <div className={styles.fhBlock}><FourHundred/></div>
            
            }

        </div>
            
    )
}
export default EmailConfirm