import {useState, useEffect, useCallback} from 'react'
import {Link} from "react-router-dom"
import jwt_decode from "jwt-decode"
import axios from 'axios'
import { authActions } from "../../store/authSlice"
import { useDispatch,useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './login.module.css'
import chemistry from './chemistry4.png'
import Tlo from "../landing/blob2.png"
import Footer from '../footer/footer'
import logo from '../navbar/logoNet.png'
function Login(){
    const dispatch = useDispatch()
   
    const [loginError,setLoginError]=useState(false)
    const detail=useSelector((state)=>state.details.value.id)
    
    const navigate= useNavigate()
    
    
    async function getToken (e) {
       e.preventDefault()
        setLoginError(false)
        let data=JSON.stringify({'email':e.target.email.value,'password':e.target.password.value})
        
        const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/token/`,data, {
            headers:{
                'Content-Type':'application/json'
            }
        })
        
            .then(function(response){
               dispatch(authActions.setEmail(jwt_decode(response.data.access).email))
               dispatch(authActions.setAdmin(jwt_decode(response.data.access).isAdmin))
               dispatch(authActions.setAmb(jwt_decode(response.data.access).isAmb))
               dispatch(authActions.setCoins(jwt_decode(response.data.access).tokens))
               dispatch(authActions.setAccess(response.data.access))
               dispatch(authActions.setLoged(true))
               dispatch(authActions.setReload(response.data.refresh))
               dispatch(authActions.setIsSub(jwt_decode(response.data.access).is_sub))
               dispatch(authActions.setIsTestAccount(jwt_decode(response.data.access).is_test_account))
               dispatch(authActions.setTrial(jwt_decode(response.data.access).trial_check))
               dispatch(authActions.setIsConfirmed(jwt_decode(response.data.access).is_confirmed))
    
               localStorage.setItem('authTokens', JSON.stringify(response.data))
               {detail == undefined ? navigate("/") : navigate(`/details/${detail}`)}
            })
            .catch(function(error){
                setLoginError(true)
            })
            .finally(function(){
                
            })
            return
    }

    
    const openGoogleLoginPage = useCallback(() => {
        const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
        
        const scope = [
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
        ].join(" ");
    
        const params = new URLSearchParams({
          response_type: "code",
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          redirect_uri: `${process.env.REACT_APP_DOMAIN}/google`,
          prompt: "select_account",
          access_type: "offline",
          scope,
        });
    
        const url = `${googleAuthUrl}?${params}`;
    
        window.location.href = url;
      }, []);

    return(
    <div><img src={Tlo} alt="" className={styles.tlo}/>
    <div className={styles.block}>
        
        <div className={styles.icon}>
           
        </div>
        
        <div className={styles.logBox}>
            <div className={styles.title}>
                <img src={logo} alt="Logo" className={styles.logo}/>
          
            </div>
            <div className={styles.loginTitle}>Login</div>
            <div className={styles.boxMeat}>
                
                <div className={styles.logMinBox}>
                   <div className={styles.formBox}>
                        <form onSubmit={getToken} className={styles.logForm}>

                            <div className={styles.logFormElement}>
                                <label>Email:</label>
                                <input required type="email" name="email" placeholder="Enter your email address..." className={styles.email}></input>
                            </div>
                            <div className={styles.logFormElement}>
                                <label>Password:</label>
                                <input required type="password" name="password" placeholder="Enter your password..." className={styles.password}></input>
                            </div>
                            {loginError &&<div className={styles.error}>Wrong email, or password</div>}
                            <div className={styles.buttons}>
                                <button className={styles.logButton} type="submit" >Login</button>
                                <Link className={styles.registerLink} to="/register"><button className={styles.registerButton} type="button" >Register</button></Link>
                            </div>
                            <Link className={styles.resetLink} to="/reset"><div className={styles.password_reset}><button type="button"  className={styles.password_reset_button}>Forgot your password?</button></div></Link>

                        </form>
                        <div class={styles.socialLogins}>
                            <button className={styles.googleLogin} onClick={openGoogleLoginPage}>
                                <svg className={styles.googleIcon} xmlns="http://www.w3.org/2000/svg" width="30" height="30" preserveAspectRatio="xMidYMid" viewBox="0 0 256 262" id="google"><path fill="#4285F4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"></path><path fill="#34A853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"></path><path fill="#FBBC05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"></path><path fill="#EB4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"></path></svg>
                                <div className={styles.googleLoginText}>Log in with Google</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
           
        </div>
        
        <Footer/>
    </div>
    </div>
    )
}

export default Login