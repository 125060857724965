import { useSelector } from 'react-redux'
import {useEffect, useState} from 'react'
import axios from 'axios'
import Carousel from 'react-bootstrap/Carousel';
import tlo from './testNewsBack.png'
import tlo1 from './1300.png'
import tlo2 from './1500.png'
import tlo3 from './1800.png'
import tlo4 from './2300.png'
import tlo5 from './2800.png'
import tlo6 from './3600.png'
import styles from "./Home.module.css"
import { BsFillBookmarkFill } from "react-icons/bs";
import Tlo from "../explore/menu/Tlo.png"
function Home(){
    const [news, setNews] = useState([])
    const [starNews, setStarNews] = useState([])
    const [noStarNews, setNoStarNews] = useState([])
    const authToken=useSelector((state)=>state.auth.access)
    const [transformData, setTransformData] = useState([])
    function linkify(text) {
        var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
    } 

    async function NewsFetch(){
        const request=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/news_test_get`, {
            headers:{
                'Content-Type':'application/json'
            }})
        .then(function(response){
            
            
          //  setNews(response.data.map(x=>{
          //      let temp=Object.assign({},x)
          //      temp.text=linkify(x.text)
          //      return temp
          //  }
          //  ))
          setNews(response.data)
            
        })
    }

    useEffect(()=>{
        NewsFetch()
    },[])

     
    useEffect(() => {
        setStarNews(news.filter( el => {
            return el.stared == true
         }))

        
         setNoStarNews(news.filter( el => {
            return el.stared == false
         }))


        

    },[news])

    
     

    return (
        <div className={styles.wrap}>
            
            <Carousel className={`${styles.car} `}>
            {
                starNews.map((newsItem) => {return (<Carousel.Item key={newsItem.id} interval={2000000}>
                    <img
                    className={`${styles.carImg1} `}
                    src={tlo}
                    alt="First slide"
                    />
                    <img
                    className={` ${styles.carImg2} `}
                    src={tlo1}
                    alt="First slide"
                    />
                    <img
                    className={` ${styles.carImg3} `}
                    src={tlo2}
                    alt="First slide"
                    />
                    <img
                    className={` ${styles.carImg4} `}
                    src={tlo3}
                    alt="First slide"
                    />
                    <img
                    className={`${styles.carImg5} `}
                    src={tlo4}
                    alt="First slide"
                    />
                    <img
                    className={` ${styles.carImg6} `}
                    src={tlo5}
                    alt="First slide"
                    />
                    <img
                    className={` ${styles.carImg7} `}
                    src={tlo6}
                    alt="First slide"
                    />
                    <Carousel.Caption className={`${styles.capt} `}>
                    <div className={`${styles.leftLatest} `}>Latest news</div>
                    <div className={`${styles.hr} `}/>
                    <div className={`${styles.leftTitle} `}>{newsItem.date} - {newsItem.title}</div>
                    <p className={`${styles.left} ${styles.last} ${styles.linkifyer}`} dangerouslySetInnerHTML={{__html:linkify(newsItem.text)}}></p>
                    </Carousel.Caption>
                </Carousel.Item>)})
                
            }
            </Carousel>
            {
            noStarNews.map((newsItem) => {return ( 
            <div key={newsItem.id} >
                <p className={styles.head}> <BsFillBookmarkFill/> <div className={styles.newsTitle}>{newsItem.date} - {newsItem.title}</div></p>
                <p className={styles.newsText} dangerouslySetInnerHTML={{__html:linkify(newsItem.text)}}></p>
            </div> )})
            }
        </div>
    )
}

export default Home