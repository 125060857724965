import {createSlice} from '@reduxjs/toolkit'
const initialState={
    value:[]
      }

const detailsSlice = createSlice({
    name: "details",
    initialState:initialState,
    reducers:{
        setDetails(state,value){
            state.value=value.payload
        }
        
        
        
    }
})
export const detailsActions =detailsSlice.actions
export default detailsSlice.reducer