import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../store/authSlice'
import React, {useEffect, useState, Fragment} from 'react'
import axios from 'axios'
import NewsItem from './NewsItem'

function ListNews(props){

    const dispatch=useDispatch()
    const authToken=useSelector((state)=>state.auth.access)

    async function NewsFetch(){
        
        const request=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/news_test_get`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
        .then(function(response){
           
            props.setNews(response.data)
        })
        .catch(function(error){
             
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
                dispatch(authActions.setAccess(null))
                dispatch(authActions.setReload(null))
                dispatch(authActions.setLoged(false))
                localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return 
    }

    useEffect(()=>{
        NewsFetch()
    },[])

    
    return (
            <div>
                {
                props.news.map((newsItem) => {return <NewsItem key={newsItem.id} newsItem={newsItem} NewsFetch={NewsFetch} authToken={authToken} news={props.news} editable={props.editable}/>})
                }
            </div>
    )
}

export default ListNews