import styles from "./register.module.css"
import axios from 'axios'
import {useState} from  'react'
import {Link} from "react-router-dom"
import logo from '../navbar/logoNet.png'
import chemistry from './chemistry4.png'
import Footer from "../footer/footer"
import Tlo from "../landing/blob2.png"
function ForgotPassword(){
    const [emailError,setEmailError]=useState()
    const [succes,setSucces]= useState(false)
    async function submiter(e){
        e.preventDefault()
        let data=JSON.stringify({'email':e.target.email.value})
        setSucces(false)
        setEmailError()
        const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/password_reset/`,data, {
            headers:{
                'Content-Type':'application/json'
            }
        })
        
            .then(function(response){
                
               
               setSucces(true)
               
            })
            .catch(function(error){
                setEmailError(error.response.data.email)
            })
            .finally(function(){
                
            })
            return
    }
    return (
        <div>
           <img src={Tlo} alt="" className={styles.tlo}/>
        <div className={styles.block}>
        <div className={styles.icon}>
            
        </div>

        <div className={styles.forgotBox}>

            <div className={styles.title}>
                <img src={logo} alt="Logo" className={styles.logo}/>
            </div>
            <div className={styles.forgotFormBox}> 
                <div className={styles.forgotBlock}>
                
                    <div className={styles.registerForgotBox}>
                        <div className={styles.registerForgotEmail}>Enter your email address</div>
                     
                        <form onSubmit={submiter} className={styles.forgotForm}>
                            <div className={styles.forgotBoxMeat}>
                                <label>Email</label>
                                <input type="email" required name="email" className={styles.forgotEmailInput}></input>
                            </div>
                            <button type="submit" className={styles.forgotBut}>Confirm</button>
                            {emailError && <div className={styles.forgotError}>{emailError}</div>}
                            {succes && <div className={styles.forgotError}>Mail with further instructions has been sent</div>}
                        </form>
                        
                    </div>
                </div>
                
            </div>
            
          
       </div>
   
    </div>
    </div>

    )
}
export default ForgotPassword