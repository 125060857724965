
import styles from './Settings.module.css'
import { Link } from 'react-router-dom'
function Settings(){
    

   

    return(
        <div className={styles.firstBlock}>
            <div className={styles.block}>
               <Link to="/userInfo" className={styles.link}> <div className={styles.square}><div className={styles.userInfo}>Edit User Information</div></div></Link>
               <Link to="/userInfo" className={styles.link}><div className={styles.square}>Security</div></Link>
            </div>
        </div>
    )
}
export default Settings