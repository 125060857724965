import { Link } from "react-router-dom";
import styles from "./About.module.css";
import { useRef, useState } from "react";
import Footer from "../footer/footer";
import logo from "./smallLogoColor.png";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import blob from "../landing/blob3.png";
function About() {
	const [currSlide, setCurrSlide] = useState(0);

	function next(e) {
		setCurrSlide((prevSlide) => (prevSlide + 1) % 2);
		if (currSlide === 0) {
			scrollTo(section1);
		} 
		// else if (currSlide === 1) {
		// 	scrollTo(section2);
		// } 
		else if (currSlide === 1) {
			scrollTo(section0);
		}
	}
	function prev() {
		if (currSlide == 0) {
			setCurrSlide(2);
		} else {
			setCurrSlide(currSlide - 1);
		}
		if (currSlide === 1) {
			scrollTo(section0);
		} else if (currSlide === 2) {
			scrollTo(section1);
		} else if (currSlide === 0) {
			scrollTo(section2);
		}
	}
	function scrollTo(section) {
		section.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
		});
	}
	const section0 = useRef();
	const section1 = useRef();
	const section2 = useRef();
	return (
		<div className={styles.block}>
			<img src={blob} className={styles.blob1}></img>
			<img src={blob} className={styles.blob2}></img>
			{/* <div className={styles.logoDiv}>
				<img src={logo} className={styles.logo}></img>
				<div className={`${styles.colorLine} `}></div>
			</div> */}

			<div className={styles.rest}>
				<div className={styles.mainPart} id="mainPart">
					<div
						className={`${styles.section} ${styles.first}`}
						ref={section0}
					>
						<div className={styles.sectionBody}>
							{
								<div className={styles.bars}>
									{currSlide != 0 ? (
										<button className={styles.slideBut}>
											<MdKeyboardArrowUp
												className={styles.butArr}
												onClick={prev}
											/>
										</button>
									) : (
										<button
											className={`${styles.slideBut} ${styles.noDisplay}`}
										>
											<MdKeyboardArrowUp
												className={styles.butArr}
											/>
										</button>
									)}

									<div
										className={
											currSlide == 0
												? styles.activeBar
												: styles.inactiveBar
										}
										onClick={() => {
											setCurrSlide(0);
											scrollTo(section0);
										}}
									></div>
									<div
										className={
											currSlide == 1
												? styles.activeBar
												: styles.inactiveBar
										}
										onClick={() => {
											setCurrSlide(1);
											scrollTo(section1);
										}}
									></div>
									{/* <div
										className={
											currSlide == 2
												? styles.activeBar
												: styles.inactiveBar
										}
										onClick={() => {
											setCurrSlide(2);
											scrollTo(section2);
										}}
									></div> */}
									{currSlide != 2 ? (
										<button
											className={`${styles.slideBut} ${styles.downBut}`}
										>
											<MdKeyboardArrowDown
												className={styles.butArr}
												onClick={next}
											/>
										</button>
									) : (
										<button
											className={`${styles.slideBut} ${styles.downBut} ${styles.noDisplay}`}
										>
											<MdKeyboardArrowDown
												className={styles.butArr}
											/>
										</button>
									)}
								</div>
							}
							<div className={styles.sectionBodyMain}>
								<div className={styles.logoDiv}>
									<img
										src={logo}
										className={styles.logo}
									></img>
									<div
										className={`${styles.colorLine} `}
									></div>
								</div>
								<div className={styles.text}>
									QSAR Lab moves chemistry from traditional
									laboratories to virtual space. The company's
									activity is a synergy of Digitization, Green
									Deal, and SSbD assumptions in computational
									chemistry.
								</div>
								<div
									className={`${styles.colorLine} ${styles.short} `}
								></div>
								<div className={styles.text}>
									QSAR Lab's research, development, and
									implementation activities focus on
									developing its own IT products, especially
									digital tools, enabling a comprehensive risk
									assessment posed by new chemicals and
									materials (including nanomaterials) to the
									natural environment and to human health and
									life.
								</div>
							</div>
						</div>
					</div>

					<div
						className={`${styles.section} ${styles.second}`}
						ref={section1}
					>
						<div className={styles.sectionBody}>
							<div className={styles.sectionBodyMain}>
								<div className={styles.logoDiv}>
									<img
										src={logo}
										className={styles.logo}
									></img>
									<div
										className={`${styles.colorLine} `}
									></div>
								</div>
								<div className={styles.text}>
									Company founders have been involved in
									developing chemo-informatics,
									nanoinformatics, and computational chemistry
									methods for over{" "}
									<span className={styles.greenTxt}>
										20 years
									</span>
									. The company, as a spin-off, successfully
									creates a bridge between the scientific
									world and business.
								</div>
								<div
									className={`${styles.colorLine} ${styles.short} `}
								></div>
								<div className={styles.text}>
									We engage{" "}
									<span className={styles.greenTxt}>
										40 specialists
									</span>{" "}
									in computer-aided design and analysis of
									new, safe chemicals and materials,
									bioinformatics, digital (nano)toxicology,
									statistics, and chemometrics for the future
									of safe chemistry.
								</div>
							</div>
						</div>
					</div>

					{/* <div
						className={`${styles.section} `}
						ref={section2}
					>
						<div className={`${styles.sectionBody} `}>
							<div className={styles.sectionBodyMain}>
								<div className={styles.logoDiv}>
									<img
										src={logo}
										className={styles.logo}
									></img>
									<div
										className={`${styles.colorLine} `}
									></div>
								</div>
								<div className={styles.text}>
									The QSAR Lab company implements
									international research and development
									projects financed by the{" "}
									<span className={styles.greenTxt}>
										European Union’s HORIZON 2020 and
										Horizon Europe{" "}
									</span>
									framework programs.
								</div>
								<div
									className={`${styles.colorLine}  ${styles.short}`}
								></div>
								<div className={styles.text}>
									Between 2020 and 2024, the company was
									involved in{" "}
									<span className={styles.greenTxt}>
										11 international projects
									</span>
									, cooperating with over{" "}
									<span className={styles.greenTxt}>
										150partners
									</span>
									. At the same time, QSAR Lab also
									implemented national grants.
								</div>
							</div>
						</div>
					</div> */}
				</div>
				{currSlide == 2 && <Footer />}
			</div>
		</div>
	);
}
export default About;
