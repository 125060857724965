import {useState, useEffect} from 'react'
import styles from "./filterExpander.module.css"
import {RiArrowDropDownLine,RiArrowDropUpLine} from 'react-icons/ri'
import Filter from './filter'
import Name from '../table/name'
import { useSelector, useDispatch } from 'react-redux'
import { cssActions } from '../../../../../store/cssSlice'
function FiltersExpander(props){
    const dispatch=useDispatch()
    const [filterSearch,setFilterSearch]=useState(1)
    function filterChandler(){
        
        if (filterSearch==0){
            setFilterSearch(1)
            dispatch(cssActions.setExpH_F(1))
        }

        if (filterSearch==1){
            setFilterSearch(0)
            dispatch(cssActions.setExpH_F(0))
        }
        
    }

    const [nameSearch,setNameSearch]=useState(1)
    function nameChandler(){
        
        if (nameSearch==0){
            setNameSearch(1)
            dispatch(cssActions.setExpH_N(1))
        }

        if (nameSearch==1){
            setNameSearch(0)
            dispatch(cssActions.setExpH_N(0))
        }
        
    }

    useEffect(()=>{
        dispatch(cssActions.setExpH_F(1))
        dispatch(cssActions.setExpH_N(1))
       
    },[])
    return(
        <div className={styles.block}>
            <div className={styles.row}>
                <div className={styles.title}>Search </div>
                
                <button onClick={filterChandler} className={styles.filterButton}>
                    {filterSearch==0 ? <RiArrowDropDownLine/>:<RiArrowDropUpLine/>}
                    {filterSearch==0 ? <div>Click to expand</div>:<div>Click to hide</div>}
                </button>
            
            </div>
            {filterSearch==1 &&<div className={styles.subTitle}>by filter:</div>}

            

            {filterSearch==1 &&
            <Filter name={props.name} stages={props.stages} endpoints={props.endpoints} organs={props.organs} tests={props.tests} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} regulations={props.regulations}/>
            }

            < div className={styles.rowName}>
            {filterSearch==1 && <div className={styles.subTitle}> by name:</div>}
               
            
            </div>

            {filterSearch==1 &&
            <Name name={props.name} stages={props.stages} endpoints={props.endpoints} organs={props.organs} tests={props.tests} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} regulations={props.regulations}/>
            }
        </div>



    )
}
export default FiltersExpander