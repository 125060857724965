import { Fragment } from "react"
import styles from './namStatus.module.css'
import { ImCheckboxChecked} from "react-icons/im";
import { MdDisabledByDefault} from "react-icons/md";
import {MdCircle} from "react-icons/md"
import {ImCheckmark} from "react-icons/im"
import {BsInfoSquareFill} from "react-icons/bs"
import {TbInfoSquareRoundedFilled} from "react-icons/tb"
function NamStatus(props) {
    let refs=[]
    if (props.usedRef){
     refs=(props.usedRef.split(','))
    }
    
    const styleG = {color:"green", fontSize:"15px","margin-left":"5px"}
    const styleR = {color:"#F44336", fontSize:"19px","margin-left":"5px"}
    const styleP = {color:"#569c59", fontSize:"19px"}
    const styleCh = {color:"#569c59", fontSize:"17px"}
    const styleI = {color:"#5a676d", fontSize:"18px","margin-left":"5px"}
    return(
        <Fragment>
            <div>
                <div className={styles.status}>Status of NAM</div>
                {props.actualData &&
                    <div className={styles.blue}>
                        <TbInfoSquareRoundedFilled style={styleI}/>
                        <div className={styles.GRText}> Last updated: {props.actualData}</div>
                    </div>
                    }
                {props.nanoVal == 'Yes' && props.nanoVal!=undefined && 
                <div className={styles.green}> 
                    <ImCheckboxChecked style={styleG}/>
                    <div className={styles.GRText}> This NAM was officially validated for nanomaterial chemicals</div>
                </div>}
                {props.nanoVal == 'No' && props.nanoVal!=undefined && 
                <div className={styles.red}>
                     <MdDisabledByDefault style={styleR}/>
                    <div className={styles.GRText}>This NAM is not yet officially validated for nanomaterials</div>
                </div>}
                
                {refs[0] !='-' && refs[0]!=undefined &&
                <div className={styles.refs}>
                    <div className={styles.refTitle}>
                        <MdCircle style={styleP}/>
                        <div className={styles.refTitleText}>This NAM was already used and applied in literature to test nanomaterials. See references below:</div>
                    </div>
                    <div className={styles.refsList}>
                        {refs.map(x=>x!="-"&&
                        <div className={styles.refRow}>
                            <ImCheckmark style={styleCh}/>
                            <a className={styles.link} target="_blank" href={x}>{x}</a>
                        </div>
                        )}
                    </div>
                </div>}
            </div>
        </Fragment>
    )
}
export default NamStatus