
import { configureStore} from '@reduxjs/toolkit'
import FiltersSlice from './filterSlice'
import detailsSlice from './detailsSlice'
import authSlice from './authSlice'
import manageEditSlice from './manageEditSlice'
import editSlice from './editSlice'
import addSlice from './addSlice'
import manageFilterSlice from './manageFilterSlice'
import cssSlice from './cssSlice'
import envSlice from './enviroment'
import labsFilterSlice from './labsFilterSlice'
import editLabFilterSlice from './editLabFilterSlice'
const store = configureStore({
    reducer:{filter: FiltersSlice,
             details:detailsSlice,
            auth:authSlice,
            manageEdit:manageEditSlice,
            edit:editSlice,
            add:addSlice,
            manageFilter:manageFilterSlice,
            css:cssSlice,
            env:envSlice,
            labsFilter:labsFilterSlice,
            editLabsFilter:editLabFilterSlice}
})


export default store