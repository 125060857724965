import { useState, useEffect } from 'react'
import styles from './UserInfo.module.css'
import {BiPencil} from 'react-icons/bi'
import {AiOutlineCheck} from 'react-icons/ai'
import { useDispatch,useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { authActions } from '../../store/authSlice'
import { FaUserCircle } from "react-icons/fa";
import axios from 'axios'
import Tlo from '../landing/blob3.png'
function UserInfo(){

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }

    const [checked,setChecked]=useState()
    const authToken=useSelector((state)=>state.auth.access) 
    const reloadToken=useSelector((state)=>state.auth.reload) 
    const email=useSelector((state)=>state.auth.email) 
    const checker=()=>{
        setChecked(!checked)
    }

    const [first,setFirst]=useState(useSelector((state)=>state.auth.firstName))

    const [last,setLast]=useState(useSelector((state)=>state.auth.lastName))
   

    const [country,setCountry]=useState(useSelector((state)=>state.auth.country))
  

    const [postalCode,setPostalCode]=useState(useSelector((state)=>state.auth.postalCode))
  

    const [phone,setPhone]=useState(useSelector((state)=>state.auth.phone))

    const [company,setCompany]=useState(useSelector((state)=>state.auth.company))

    const [nipVat,setNipVat]=useState(useSelector((state)=>state.auth.nipVat))

    const [compCountry,setCompCountry]=useState(useSelector((state)=>state.auth.compCountry))

    const [compPostal,setCompPostal]=useState(useSelector((state)=>state.auth.compPostal))

    const [www,setWww]=useState(useSelector((state)=>state.auth.www))

    const [compNumber,setCompNumber]=useState(useSelector((state)=>state.auth.compNumber))

    
    
    
    const dispatch = useDispatch()
   
   


    async function getUserInfo(){
        
            
          
       
            const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/getUser?email=${email}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
            .then(function(response){
                
        
               dispatch(authActions.setFirstName(response.data.name))
               setFirst(response.data.name)

               dispatch(authActions.setFirstName(response.data.surname))
               setLast(response.data.surname)

               dispatch(authActions.setCountry(response.data.country))
               setCountry(response.data.country)

               dispatch(authActions.setPhone(response.data.phone))
               setPhone(response.data.phone)

               dispatch(authActions.setPostal(response.data.postal))
               setPostalCode(response.data.postal)



               dispatch(authActions.setCompany(response.data.company))
               setCompany(response.data.company)

               dispatch(authActions.setCompCountry(response.data.company_country))
               setCompCountry(response.data.company_country)

               dispatch(authActions.setCompPostal(response.data.company_postal))
               setCompPostal(response.data.company_postal)

               dispatch(authActions.setNipVat(response.data.nip_vat))
               setNipVat(response.data.nip_vat)

               dispatch(authActions.setWww(response.data.www))
               setWww(response.data.www)

               dispatch(authActions.setCompNumb(response.data.company_number))
               setCompNumber(response.data.company_number)
           
            })
        .catch(function(error){
             
            
        })
        .finally(function(){
            
        })
        return
        }


    async function saveUser(e){
        e.preventDefault()
        let userData=JSON.stringify({
            email:email,
            type:'user',
            first:e.target.first.value,
            last:e.target.last.value,
            country:e.target.country.value,
            postal:e.target.postal.value,
            phone:e.target.phone.value

        })
     
        const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/editUser`, userData,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
            .then(function(response){
                window.location.reload()
              
           
            })
        .catch(function(error){
             
            
        })
        .finally(function(){
            
        })
        return
        }


        async function saveComp(e){
            e.preventDefault()
            let userData=JSON.stringify({
                email:email,
                type:'company',
                company:e.target.company.value,
                nip_vat:e.target.nipvat.value,
                compCountry:e.target.compCountry.value,
                company_postal:e.target.compPostal.value,
                www:e.target.www.value,
                company_number:e.target.compNumber.value,
    
            })
           
            const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/editUser`, userData,{
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':"Bearer " + String(authToken)
                }})
        
                .then(function(response){
                    
                    window.location.reload()
               
                })
            .catch(function(error){
                 
                
            })
            .finally(function(){
                
            })
            return
            }
    

    const userInfo=[
        {label:"First Name:",
       value:first,
       name:"first"
        },
        {label:"Last Name:",
        value:last,
        name:"last"
        },
        {label:"Country:",
        value:country,
        name:"country"},
        {label:"Phone Number:",
        value:phone,
        name:"phone",
        number:true},
        {label:"Postal Code:",
        name:"postal",
        value:postalCode}
    ]

    const compInfo1=[
        {label:"Company Name:",
        value:company,
        name:"company"},
        {label:"Company NIP/VAT:",
        value:nipVat,
        name:"nipvat",
        number:true},
        
        
        {label:"Website:",
        name:"www",
        value:www},
        {label:"Company Phone Number:",
        value:compNumber,
        name:"compNumber",
        number:true},

    ]

    const compInfo2=[
        
        {label:"Country of Company:",
        value:compCountry,
        name:"compCountry"},
        {label:"Company Postal Code:",
        value:compPostal,
        name:"compPostal"}
       

    ]


    useEffect(()=>{
        
        getUserInfo()
    
    },[])
   

    return(
        <div className={styles.block}>
            
            <img className={styles.tlo} src={Tlo}></img>
            <div className={`${styles.panel} ${styles.firstPanel}`}>
                <FaUserCircle className={styles.userIcon}/>
                {first && <div className={styles.firstName}>{first}</div>}
                <div className={styles.email}>{email}</div>
            </div>
            <div className={styles.line}></div>
            <div className={`${styles.panel}`}>
                <div className={styles.marginer}>
                    <div className={styles.secondLabel}>User Information</div>
                    <form onSubmit={saveUser}>
                        <div>
                            {userInfo.map(x=>
                                <div>
                                    <div className={styles.label}>{x.label}</div>
                                    <input id={styles.input} name={x.name} defaultValue={x.value} ></input>
                                </div>
                            )}
                               <div className={styles.userFiller}></div>
                        </div>
                        <button type='submit' className={styles.saveButton}>Save User Information</button>
                    </form>
                </div>
                
                
            </div>
            <div className={styles.line}></div>
            <div className={`${styles.panel}`}>
                <div className={styles.marginer}>
                    <form onSubmit={saveComp}>
                        <div className={styles.secondLabel}>Company Information</div>
                        <div>
                            {compInfo1.map(x=>
                                <div>
                                    <div className={styles.label}>{x.label}</div>
                                    <input id={styles.input} name={x.name} defaultValue={x.value}></input>
                                </div>
                            )}
                         
                        </div>
                        <div className={styles.secondCompany}>
                            {compInfo2.map(x=>

                                    <div className={styles.secondCompanyItem}>
                                        <div className={styles.postalCodeLabel}>{x.label}</div>
                                        <input id={styles.smallInput} name={x.name} defaultValue={x.value}></input>
                                    </div>

                            )}
                        </div>
                        <button type='submit' className={styles.saveButton}>Save Company Information</button>
                    </form>
                </div>
            </div>
            
        </div>
    )


}

export default UserInfo