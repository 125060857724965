import styles from "./realMetaData.module.css"
import { useDispatch,useSelector } from 'react-redux'
import { addActions } from "../../../store/addSlice"
import axios from 'axios'
import Modal from "./modal"
import { useState,useEffect } from "react"
import ModalAdd from "./modalSuc"
function RealMetaData (){

    const [modalIn,setModalIn]=useState(0)
    const [modalAddIn,setModalAddIn]=useState(0)
    const [errorInfo,setErrorInfo]=useState()

    const authToken=useSelector((state)=>state.auth.access) 

    const dispatch=useDispatch()
    
    function descChandler(e){
        dispatch(addActions.setNameDesc(e.target.value))
    }

    function refChandler(e){
        dispatch(addActions.setReference(e.target.value))
    }

    function pubChandler(e){
        dispatch(addActions.setNanoRef(e.target.value))
    }


    let name=useSelector((state)=>state.add.name.payload)
    /*if (!name){
        name='-'
    }*/
    
    let key_event=useSelector((state)=>state.add.keyEvent.payload)
    
    /*if (!key_event){
        key_event="-"
    }*/
    let aop=useSelector((state)=>state.add.aops.payload)
    /*if (!aop){
        aop="-"
    }*/
    let endpoint=useSelector((state)=>state.add.endpoints.payload)
    /*if (!endpoint){
        endpoint="-"
    }*/
    let val=useSelector((state)=>state.add.validated.payload)
    /*if (!val){
        val="-"
    }*/
    let used=useSelector((state)=>state.add.used.payload)
    /*if (!used){
        used="-"
    }*/
    let mie=useSelector((state)=>state.add.mie.payload)
    /*if (!mie){
        mie="-"
    }*/
    let nameDesc=useSelector((state)=>state.add.nameDesc.payload)
    if (!nameDesc){
        nameDesc="-"
    }
    let namType=useSelector((state)=>state.add.namType.payload)
    /*if (!namType){
        namType="-"
    }*/
    let usedRef=useSelector((state)=>state.add.usedRef.payload)
    if (!usedRef){
        usedRef="-"
    }
    let organ=useSelector((state)=>state.add.organs.payload)
    /*if (!organ){
        organ="-"
    }*/
    let reference=useSelector((state)=>state.add.reference.payload)
    if (!reference){
        reference="-"
    }
    let regulations=useSelector((state)=>state.add.regulations.payload)
    
    /*if (!regulations){
        regulations="-"
    }*/
    
    
    
    let stage=useSelector((state)=>state.add.stages.payload)
    /*if (!stage){
        stage="-"
    }*/
    let test=useSelector((state)=>state.add.tests.payload)
    /*if (!test){
        test="-"
    }*/
    let type=useSelector((state)=>state.add.type.payload)
    /*if (!type){
        type="-"
    }*/
    let addChecker=0
    if (regulations&&organ&&namType&&mie&&used&&val&&endpoint&&aop&&key_event&&name&&stage&&test&&type){
        addChecker=1
    }
    


    async function addChandler(e){
    e.preventDefault()
    const addData=JSON.stringify({aop:aop,endpoint:endpoint,is_nano_validated:val,
        is_used_for_nano:used,key_event:key_event,mie:mie,name:name,
        name_desc:nameDesc,name_type:namType,nano_used_ref:usedRef,
        organ:organ,reference:reference,regulations:regulations,
        stage:stage,testing_method_strategy:test,type:type,reference:reference})

    
    const requestList= await axios.post(`${process.env.REACT_APP_DOMAIN}/api/nam_post`,addData, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            setModalAddIn(1)
            
           
        })
        .catch(function(error){
            setErrorInfo(error.response.data)
            setModalIn(1)
            
            
            
        })
        .finally(function(){
            
        })
    
    }
 


      
    
    
    return(
        <div className={styles.block}>

            <div className={styles.row}>
                <div className={styles.rowForm}>
                    <div className={styles.label}>Description:</div>
                    <div className={styles.metaInput}>
                         <textarea onChange={descChandler} type="text" id={styles.desc} placeholder="Insert description here"></textarea>
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.rowForm}>
                    <div className={styles.label}>Reference URL:</div>
                    <div className={styles.metaInput}>
                         <input onChange={refChandler} type="text" id={styles.ref} placeholder="Insert URL name here"></input>
                    </div>
                </div>
            </div>

            <div className={styles.lastRow}>
                <div className={styles.lastRowForm}>
                    <div className={styles.label}>Publications:</div>
                    <div className={styles.metaInput}>
                        <input onChange={pubChandler} type="text" id={styles.pub} placeholder="Add doi publications separated by comma"></input>
                    </div>
                </div>
                <div className={styles.button1}>{addChecker==1 ?<button className={styles.add} onClick={addChandler}> Add </button>:<button className={styles.addH} > Add </button>}</div>
            </div>
            <div className={styles.button2}>{addChecker==1 ?<button className={styles.add} onClick={addChandler}> Add </button>:<button className={styles.addH} > Add </button>}</div>
            
            {modalIn ==1 && <Modal error={errorInfo} setModal={setModalIn} /> }
            {modalAddIn==1 && <ModalAdd name={name}/>}
        </div>
    )
}

export default RealMetaData