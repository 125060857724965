import styles from './navbar.module.css'
import Logo from './logo'
import Menu from './menu'
import jwt_decode from 'jwt-decode'
import { authActions } from '../../store/authSlice'
import { useDispatch,useSelector } from 'react-redux'
import {useEffect,useState} from 'react'
import axios from 'axios'
import Widget from './widget'
function Navbar(){
    
    
    const [namsNum,setNamsNum]=useState()
    const dispatch = useDispatch()
    const authToken=useSelector((state)=>state.auth.access) 
   
    async function getNamsNum () {
       
        
        
        
    
         
         const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/getNamsNumber`, {
             headers:{
                'Content-Type':'application/json'
             }
         })
         
             .then(function(response){
               
                 setNamsNum(response.data)
                
                
             })
             .catch(function(error){
                
             })
             .finally(function(){
                 
             })
             return
     }

     useEffect(()=>{
    getNamsNum()
      
    },[])
    

   
    


    return(
        <div className={styles.navbar}>
                <Logo/>
                <div className={styles.line}></div>
                <Widget number={namsNum}/>
                <div className={styles.secondLine}></div>
                <Menu/>
                
        </div>
    )
}

export default Navbar