import {createSlice} from '@reduxjs/toolkit'
const initialState={expH_F:0,expH_N:0}
/*Slice najpierw klonuje i potem nadpisuje dlatego nie trzeba
kazdej wlasności wpisywać jeszcze raz i mozna uzywać ++*/
const cssSlice = createSlice({
    name: "css",
    initialState:initialState,
    reducers:{
        setExpH_F(state,value) {
            state.expH_F=value
        },
        setExpH_N(state,value) {
            state.expH_N=value
        },

    }
})
export const cssActions =cssSlice.actions
export default cssSlice.reducer