import styles from './sidebar.module.css'
import { Link } from 'react-router-dom'
import {TbEdit} from "react-icons/tb"
import {MdOutlineCircle} from "react-icons/md"
import {BsTable} from "react-icons/bs"
import { SlChemistry } from "react-icons/sl";

const style = {color:"white", fontSize:"35px"}
function Sidebar() {
    return (
        <div className={styles.block}>

            <div className={styles.menu}>Menu</div>

       

            <div className={styles.manage} style={{'margin-top':'30px'}}>
                
                <div className={styles.providerText}>Manage NAM(s)</div>
            </div>

            <div className={styles.sublist}>
                <Link to="/add" className={styles.add}>
                    <MdOutlineCircle className={styles.circle}/>
                    <div className={window.location.pathname.split( '/add' )[1]===''? styles.selected : styles.addText}>Add</div>
                </Link >
                <Link to="/edit" className={styles.add}>
                    <MdOutlineCircle/>
                    <div className={window.location.pathname.split( '/edit' )[1]===''? styles.selected : styles.addText}>Edit</div>
                </Link>
            </div>

            <Link to="/addDetails" className={styles.manage}>
                <div className={window.location.pathname.split( '/addDetails' )[1]===''? styles.selected : styles.manageText}>Manage DB</div>
            </Link>

            <div className={styles.serviceProviders}>
                <div className={styles.providerText}>Manage Service</div><div className={styles.interline}> Providers</div>
            </div>

            <div className={styles.sublist}>
                <Link to="/add_provider" className={styles.add}>
                    <MdOutlineCircle/>
                    <div className={window.location.pathname.split( '/add_provider' )[1]===''? styles.selected : styles.addText}>Add</div>
                </Link >
                <Link to="/search_provider" className={styles.add}>
                    <MdOutlineCircle/>
                    <div className={window.location.pathname.split( '/search_provider' )[1]===''? styles.selected : styles.addText}>Edit</div>
                </Link>
            </div>
          
        
            
        </div>
    );
  }
  
  export default Sidebar;