import { useSelector, useDispatch } from 'react-redux'
import { useEffect,useState } from 'react'
import {authActions} from '../../../../store/authSlice'
import axios from 'axios'
import DropdownOrgan from './dropDownOrgan'
import styles from './filter.module.css'
function Organ(props) {
    const [isLoading,setIsLoading]=useState(0)
    const dispatch = useDispatch()

    const stages=props.stages
    
    const namType=props.namType
    const used=props.used
    const tests=props.tests
    const endpoints=props.endpoints
    const regulations=props.regulations
    const aops=props.aops
    const authToken=props.authToken
    const name= props.name

   
    let nameValue=[]
    if (name!=null){
    if (name.payload){
        nameValue=name.payload.map(x=>x.name)
    }
    else{
        nameValue=name.map(x=>x.name)
    }
    }
    let stagesValue=[]
    if (stages!=null){
    if (stages.payload){
        stagesValue=stages.payload.map(x=>x.code)
    }
    else{
        stagesValue=stages.map(x=>x.code)
    }}

    let namTypeValue=[]
    if (namType!=null){
    if (namType.payload){
        namTypeValue=namType.payload.map(x=>x.code)
    }
    else{
        namTypeValue=namType.map(x=>x.code)
    }}

    let usedValue=[]
    if (used!=null){
    if (used.payload){
        usedValue=used.payload.map(x=>x.code)
    }
    else{
        usedValue=used.map(x=>x.code)
    }}

    

    let endpointsValue=[]
    if (endpoints!=null){
    if (endpoints.payload){
        endpointsValue=endpoints.payload.map(x=>x.code)
    }
    else{
        endpointsValue=endpoints.map(x=>x.code)
    }}
    
    

    let regulationsValue=[]
    if (regulations!=null){
    if (regulations.payload){
        regulationsValue=regulations.payload.map(x=>x.code)
    }
    else{
        regulationsValue=regulations.map(x=>x.code)
    }}

    let aopsValue=[]
    if (aops!=null){
    if (aops.payload){
        aopsValue=aops.payload.map(x=>x.code)
    }
    else{
        aopsValue=aops.map(x=>x.code)
    }}
    

    let testsValue=[]
    if (tests!=null){
    if (tests.payload){
        testsValue=tests.payload.map(x=>x.code)
    }
    else{
        testsValue=tests.map(x=>x.code)
    }}

    
  
    let [organsValue,setOrgans]=useState([])
    const organ_link=`${process.env.REACT_APP_DOMAIN}/api/filer_get?name=${nameValue}&used=${usedValue}&type=${namTypeValue}&stage=${stagesValue}&testing=${testsValue}&endpoint=${endpointsValue}&organ=${organsValue}&aop=${aopsValue}&regulations=${regulationsValue}&filter_type=organ`
   
    async function OrganFetch(){
        
        const request=await axios.get(organ_link, {
            headers:{
                'Content-Type':'application/json'
            }})
        .then(function(response){
            setIsLoading(1)
            setOrgans(response.data)
        })
        .catch(function(error){
          
            
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return 
    }

    useEffect(()=>{
        setIsLoading(0)
        OrganFetch()
       
    },[tests,stages,regulations,endpoints,aops,namType,used,name])

    let  endpoints_list =organsValue.map((x) => ({name:x.name,code:x.name}))
   
    return(
        <div>
        <DropdownOrgan data={endpoints_list} name="organ" catalog={props.catalog} actualData={props.organs}/>

        </div>
    )
}
export default Organ