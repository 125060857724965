import styles from "./404.module.css"
import { Link } from 'react-router-dom';

import Tlo from "../landing/blob2.png"
function FourHundred(){
    
    
    return (
        <div>
           <img src={Tlo} alt="" className={styles.tlo}/>
            <div className={styles.block}>
                <div className={styles.error}>
                    <div class={styles.number}>{"404"}</div>
                    <div class={styles.face}>{":("}</div>
                </div>
                <div>Sorry, the page you are looking for does not exist.</div>
                <div class={styles.second}><div>Take me</div> <Link className={styles.link} to="/">home.</Link></div>
            </div>
        </div>

    )
}
export default FourHundred