import styles from "./register.module.css"
import axios from 'axios'
import {useState} from  'react'
import {Link, useNavigate} from "react-router-dom"
import logo from '../navbar/logoNet.png'
import chemistry from './chemistry4.png'
import Footer from "../footer/footer"
import Tlo from "../landing/blob2.png"
import eula_pdf from "./eula.pdf"
import privacy_pdf from "./privacy.pdf"
import { Checkbox } from 'primereact/checkbox';
import { MdOutlineCheck } from "react-icons/md";
function Register(){
    const navigate = useNavigate()
    const [conf,setConf]= useState(true)
    const [created,setCreated] = useState(false)
    const [serverError,setServerError]=useState(null)
    const [eula,setEula]=useState(false)
    const [privacy,setPrivacy]=useState(false)
    const [eulaError,setEulaError]=useState(false)
    const [privacyError,setPrivacyError]=useState(false)
    async function sendRegister(e){
        e.preventDefault()
        setServerError(null)
        setCreated(false)
        if (eula == true){
            setEulaError(false)
            if (privacy == true){
                setPrivacyError(false)
                if( e.target.password2.value == e.target.password.value){
                
                setConf(true)
                let data=JSON.stringify({'email':e.target.email.value,'password':e.target.password.value})
                
                const requestReg=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/register/`,data, {
                    headers:{
                        'Content-Type':'application/json'
                    }
                })

                    .then(function(response){

                        setCreated(true)
                        navigate('/login')
                    })
                    .catch(function(error){

                        setServerError(error.response.data)
                    })
                    .finally(function(){

                    })
                    return
                }
                else{
                    setConf(false)
                    return
                }
            }
            else{
                setPrivacyError(true)
            }
        }
        else{
            setEulaError(true)
        }
    }
    return(
    <div>
        <img src={Tlo} alt="" className={styles.tlo}/>
    
    <div className={styles.block}>
        <div className={styles.icon}>
          
        </div>

        <div className={styles.logBox}>

            <div className={styles.title}>
                <img src={logo} alt="Logo" className={styles.logo}/>
            </div>
            <div className={styles.registerBlock}>
                <div className={styles.registerBox}>
                    <div className={styles.register}>Register</div>
                    <div className={styles.boxMeat}>
                        <form onSubmit={sendRegister} className={styles.logForm}>

                            <div className={styles.logFormElement}>
                                <label>Email:</label>
                                <input type="email" name="email" placeholder="Enter your email address..." className={styles.email} required></input>
                            </div>
                            <div className={styles.logFormElement}>
                                <label>Password:</label>
                                <input type="password" name="password" placeholder="Enter your password..." className={styles.password} required></input>
                            </div>
                            <div className={styles.logFormElement}>
                                <label>Confirm Password:</label>
                                <input type="password" name="password2" placeholder="Enter your password confirmation..." className={styles.password} required></input>
                            </div>
                            <div className={styles.accepts}>
                                <div className={styles.checkRow}>
                              {!eulaError ?

                                    <input type="checkbox" checked={eula} onClick={(e)=>{setEula(!eula)}} className={eula? styles.check:''}></input>:
                                    <input type="checkbox" checked={eula} onClick={(e)=>{setEula(!eula)}} className={eula? styles.check:styles.checkError}></input>
                              }

                                    <div className={styles.checkLabel}>I have read and agree to the service <span><a href={eula_pdf} target="_blank" className={styles.pdfLink}>Terms of Service</a></span> </div>
                                    
                                </div>
                                <div className={styles.checkRow}>
                                {!privacyError ?
                                    <input type="checkbox" checked={privacy} onClick={(e)=>{setPrivacy(!privacy)}} className={privacy? styles.check:''}></input>:
                                    <input type="checkbox" checked={privacy} onClick={(e)=>{setPrivacy(!privacy)}} className={privacy? styles.check:styles.checkError}></input>
                                }
                                    <div className={styles.checkLabel}>I have read and agree to the service <span><a href={privacy_pdf} target="_blank" className={styles.pdfLink}>Privacy Policy</a></span></div>
                                </div>
                            </div>
                            <div className={styles.buttons}>
                                <button className={styles.registerButton} type="submit" >Register</button>
                                <div className={styles.password_reset}>
                                    <Link to="/login">
                                        <button type="button"  className={styles.password_reset_button}>
                                            <div>Back to Login</div>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className={styles.errors}>
                                {conf==false && <div className={styles.regTextPas}>Password and password confirmation don't match</div>}
                                {created==true && <div className={styles.regTextPas}>Account created, you can log in now!</div>}
                                {serverError && <div className={styles.regTextPas}>{serverError}</div>}
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            <div className={styles.iconSecond}>
          
            </div>
            
       </div>
     
      
    </div>
    </div>
    )
}
export default Register