import {createSlice} from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

const initialState={
            email:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).email:null,
            loged:false,
            coins:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).tokens:null,
            access:localStorage.getItem('authTokens')? JSON.parse(localStorage.getItem('authTokens')).access:null,
            reload:localStorage.getItem('authTokens')? JSON.parse(localStorage.getItem('authTokens')).refresh:null,
            isAdmin:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).isAdmin:null,
            isAmbasador:localStorage.getItem('authTokens')?jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).isAmb:null,
            firstName:'',
            lastName:'',
            country:'',
            postalCode:'',
            phone:null,
            company:'',
            nipVat:null,
            compCountry:'',
            compPostal:'',
            www:'',
            compNumber:null,
            isSub:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).is_sub:null,
            isActiveSub:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).is_active_sub:null,
            isTestAccount:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).is_test_account:null,
            trial:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).trial_check:null,
            isConfirmed:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).is_confirmed:null,
            subId:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).sub_id:null,

            
            
      }


      
const authSlice = createSlice({
    name: "auth",
    initialState:initialState,
    reducers:{
        setEmail(state,value){
            state.email=value.payload
        },
        setAccess(state,value){
            state.access=value.payload
        },
        setReload(state,value){
            state.reload=value.payload
        },
        setLoged(state,value){
            state.loged=value.payload
        },
        setAdmin(state,value){
            state.isAdmin=value.payload
        },
        setAmb(state,value){
            state.isAmbasador=value.payload
        },
        setCoins(state,value){
            state.coins=value.payload
        },


        setFirstName(state,value){
            state.firstName=value.payload
        },
        setLastName(state,value){
            state.lastName=value.payload
        },
        setCountry(state,value){
            state.country=value.payload
        },
        setPostal(state,value){
            state.postalCode=value.payload
        },
        setPhone(state,value){
            state.phone=value.payload
        },

        setCompany(state,value){
            state.company=value.payload
        },
        setNipVat(state,value){
            state.nipVat=value.payload
        },
        setCompCountry(state,value){
            state.compCountry=value.payload
        },
        setWww(state,value){
            state.www=value.payload
        },
        setCompNumb(state,value){
            state.compNumber=value.payload
        },
        setCompPostal(state,value){
            state.compPostal=value.payload
        },
        setIsSub(state,value){
            state.isSub=value.payload
        },

        setIsActiveSub(state,value){
            state.isActiveSub=value.payload
        },
        setIsTestAccount(state,value){
            state.isTestAccount=value.payload
        },
        setTrial(state,value){
            state.trial=value.payload
        },
        setIsConfirmed(state,value){
            state.isConfirmed=value.payload
        },
        setSubId(state,value){
            state.subId=value.payload
        }
        
        
    }
})



export const authActions =authSlice.actions
export default authSlice.reducer