import styles from "./register.module.css"
import axios from 'axios'
import {useState} from  'react'
import {Link} from "react-router-dom"
import logo from '../navbar/logoNet.png'
import chemistry from './chemistry4.png'
import Footer from "../footer/footer"
import Tlo from "../landing/blob2.png"
function ResetPassword(){
    const [loginError,setLoginError]=useState(false)
    const [conf,setConf]= useState(true)
    const [passwordErorr,setPasswordError]=useState()
    const [tokenErorr,setTokenError]=useState()
    const [success,setSucces] = useState(false)
    async function sendPasswordReset(e){
        e.preventDefault()
        setLoginError(false)
        setConf(false)
        setPasswordError()
        setSucces(false)
        if( e.target.password2.value == e.target.password.value){
        setConf(true)
        let data=JSON.stringify({'token':e.target.key.value,'password':e.target.password.value})
        const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/password_reset/confirm/`,data, {
            headers:{
                'Content-Type':'application/json'
            }
        })
        
            .then(function(response){
                
                setSucces(true)
            })
            .catch(function(error){
                setLoginError(true)
                if (error.response.data.password[0]=="This password is too short. It must contain at least 8 characters."){
                    setLoginError(false)
                    setPasswordError(error.response.data.password[0])
                    
                }
                
                
            })
            .finally(function(){
                
            })
            return
        }
    }
    return(
        <div><img src={Tlo} alt="" className={styles.tlo}/>
        <div className={styles.block}>
        <div className={styles.icon}>
           
        </div>

        <div className={styles.realResetBox}>

            <div className={styles.title}>
                <img src={logo} alt="Logo" className={styles.logo}/>
            </div>
            <div className={styles.realResetBlock}>
                <div className={styles.registerBox}>
                    <div className={styles.register}>Change your passsword</div>
                    <div className={styles.boxMeat}>
                        {!success ?
                        <form onSubmit={sendPasswordReset} className={styles.logForm}>
                        <div className={styles.logFormElement}>
                            <label>Temporary key sent in mail</label>
                            <input name="key" required className={styles.resetEmail}></input>
                        </div>

                        <div className={styles.logFormElement}>
                            <label >New password</label>
                            <input type="password" name="password" required className={styles.resetEmail}></input>
                        </div>

                        <div className={styles.logFormElement}>
                            <label className={styles.resetLabel}>Confirm password</label>
                           <input type="password" name="password2" required className={styles.resetEmail}></input>
                        </div>
                            <button type="submit" className={styles.realResetButton}>Send</button>
                            {loginError && <div className={styles.resetError}>Wrong key</div>}
                        {passwordErorr == "This password is too short. It must contain at least 8 characters." && <div className={styles.resetError}>This password is too short. It must contain at least 8 characters.</div>}
                        {conf==false && <div className={styles.resetError}>Passwords do not match</div>}
                        
                        </form>:
                        <div className={styles.resetError}>Password changed with succes, you can <Link to='/' className={styles.logIn}>log in now!</Link></div>}
                        
                    </div>
                </div>
            </div>
            <div className={styles.iconSecond}>
            
            </div>
            
       </div>
     
      <Footer/>
    </div>
    </div>
    )
}

export default ResetPassword