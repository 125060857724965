import styles from './SubPanel.module.css'
import { BsStars } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";
import { MdBlockFlipped } from "react-icons/md";
import { API_URL } from "../../Config/Config"
import {useParams} from "react-router-dom"
import { useSelector, useDispatch} from 'react-redux'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { authActions } from '../../store/authSlice';
import { TbExclamationMark } from "react-icons/tb";
import Invoice from './Invoice';
import jwt_decode from 'jwt-decode'
import blob3 from '../landing/blob3.png'
import blob5 from './5blob.png'
import { GoCheckCircle } from "react-icons/go";
function SubPanel(){
    const authToken=useSelector((state)=>state.auth.access)
    const [loading,setLoading]=useState(1)
    const testInvoices=[
        {
            amount_due:'30.00',
            amount_paid:'30.00',
            created:'123123123',
            link:'https"//kutas.pl'

        },
        {
            amount_due:'30',
            amount_paid:'0',
            created:'123123123',
            link:'https"//kutas.pl'

        },
        {
            amount_due:'30',
            amount_paid:'0',
            created:'123123123',
            link:'https"//kutas.pl'

        },
        {
            amount_due:'30',
            amount_paid:'0',
            created:'123123123',
            link:'https"//kutas.pl'

        },

    ]
   
    const dispatch=useDispatch()
    const {slug} = useParams()
    const email=useSelector((state)=>state.auth.email)
    const [activeSubs,setActiveSubs]=useState()
    const [subs,setSubs]=useState()
    const testAccount=useSelector((state)=>state.auth.isTestAccount)
    const [invioceList,setInvoiceList]=useState([])
    const [sure,setSure]=useState(false)
    
    const [day,setDay]=useState()
    const [month,setMonth]=useState()
    const [year,setYear]=useState()
    
    const [card,setCard]=useState('management')
    let data=JSON.stringify({
        email:email})
    let cancelLink=`${process.env.REACT_APP_DOMAIN}/api/stripe_sub_cancel`
    let stripe='/api/stripe_sub_panel'
    let userInvoiceLink=`${process.env.REACT_APP_DOMAIN}/api/get_user_invoices`
    let subInfoLink=`${process.env.REACT_APP_DOMAIN}/api/get_sub_info`
    let resubLink=`${process.env.REACT_APP_DOMAIN}/api/stripe_sub_resub`
    if (testAccount==true){
        userInvoiceLink=`${process.env.REACT_APP_DOMAIN}/api/test_get_user_invoices`
        subInfoLink=`${process.env.REACT_APP_DOMAIN}/api/test_get_sub_info`
        cancelLink=`${process.env.REACT_APP_DOMAIN}/api/test_stripe_sub_cancel`
        stripe='/api/test_stripe_sub_panel'
        resubLink=`${process.env.REACT_APP_DOMAIN}/api/test_stripe_sub_resub`
    }

    
    
    
    async function cancelSub(){
        const requestData= await axios.post(cancelLink,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

        .then(function(response){
           window.location.reload()

            
        })
        .catch(function(error){
             
        })
        .finally(function(){
            
        })
        return
    }

    async function reSub(){
        const requestData= await axios.post(resubLink,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

        .then(function(response){
           window.location.reload()

            
        })
        .catch(function(error){
             
        })
        .finally(function(){
            
        })
        return
    }
    
    async function getInvioces(){
        
        const requestData= await axios.post(userInvoiceLink,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

        .then(function(response){
           setInvoiceList(response.data)
   
      
        })
        .catch(function(error){
        
        })
        .finally(function(){
            
        })
        return
    } 

    async function getSubInfo(){
        
        const requestData= await axios.post(subInfoLink,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

        .then(function(response){
          
           
           setDay(new Date(response.data*1000).getDate())
           setMonth( new Date(response.data*1000).getMonth()+1)
           setYear(new Date(response.data*1000).getFullYear())
           setLoading(0)
       
        })
        .catch(function(error){
             
            setLoading(0)
        })
        .finally(function(){
            
        })
        return
    } 
    async function checkSub(){
        
        const requestData= await axios.post(`${process.env.REACT_APP_DOMAIN}/api/check_user`,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

        .then(function(response){
            setLoading(1)
           setActiveSubs(response.data[0].is_active_sub)
           setSubs(response.data[0].is_sub)
        
        })
        .catch(function(error){
             
            setLoading(1)
        })
        .finally(function(){
            
        })
        return
    }    
    

    useEffect(()=>{
        if (testAccount==true){
            
        }
        checkSub()
        getInvioces()
        getSubInfo()
        // let date = new Date(invioceList[0].created);
      
       
       
        
    },[])
    return(
        <div className={styles.block}>
            <div className={styles.blobHolder}>
                <img src={blob5} className={styles.firstBlob}></img>
                <img src={blob5} className={styles.secondBlob}></img>
                <button onClick={(e)=>{setCard('management')}} className={card=='management'?`${styles.activeCard} ${styles.card}`:`${styles.deactiveCard} ${styles.card}`} >Subscription management</button>
                <button onClick={(e)=>{setCard('history')}} className={card=='history'?`${styles.activeCard} ${styles.card}`:`${styles.deactiveCard} ${styles.card}`} >Payments history</button>
            </div>
            {card=='management' &&
            <div className={styles.infoHolder} >
                {activeSubs && subs &&
                <div className={styles.subCard}>
                    <div className={`${styles.statusSQ} ${styles.activeSQ}`}>
                        <GoCheckCircle className={styles.blocked}/>
                    </div>
                    <div className={styles.activeSubText}>
                        Your subscription is active
                    </div>
                    <button className={styles.cancelBut} onClick={cancelSub}>
                        <div className={styles.subText}>Cancel</div> 
                        
                    </button>
                </div>
                }
                {!activeSubs && subs &&
                <div className={styles.subCard}>
                    <div className={`${styles.statusSQ} ${styles.orangeSQ}`}>
                        <TbExclamationMark className={styles.blocked}/>
                    </div>
                    <div className={styles.noSubText}>
                        Your plan is active untill {day}/{month}/{year}
                    </div>
                    <button className={styles.subBut} onClick={reSub}>
                        <div className={styles.subText}>Subscribe</div>
                        <div className={styles.cost}>19.99 EUR/month</div>
                    </button>
                </div>
                }
                {!activeSubs && !subs &&
                <div className={styles.subCard}>
                    <div className={`${styles.statusSQ} ${styles.inactiveSQ}`}>
                        <MdBlockFlipped className={styles.blocked}/>
                    </div>
                    <div className={styles.noSubText}>
                        Your subscription is inactive 
                    </div>
                    <form action={`${process.env.REACT_APP_DOMAIN}${stripe}`} method="POST" name="STRIPE" className={styles.stripeForm}>
                        <input name="email" value={email} className={styles.subInput}></input>
                        <input name="slug" value={slug} className={styles.subInput}></input>
                        <button className={styles.subBut}>
                            <div className={styles.subText}>Subscribe</div>
                            <div className={styles.cost}>19.99 EUR/month</div>
                        </button>
                    </form>
                   
                </div>
                }
            </div>
            }
            {card=='history' &&
            <div className={styles.infoHolder}>
                {invioceList.map(x=>(<Invoice amountDue={x.amount_due} amountPaid={x.amount_paid} created={x.created} link={x.link} />))}
                {/* {testInvoices.map(x=>(<Invoice amountDue={x.amount_due} amountPaid={x.amount_paid} created={x.created} link={x.link} />))} */}
            </div>
            }
           
        </div>
                        
                       
                 
    )
}
export default SubPanel