import styles from "./cookiesReg.module.css"

function CookiesReg(){
    return(
        <div>
            <div>
                <div className={styles.mainHeader}>Cookie Policy</div>
            </div>
            <div className={styles.header}>What are cookies?</div>
            <div className={styles.description}>
Cookies are small pieces of data that websites store on a user's device 
(such as a computer or smartphone) to track information about the user's browsing behavior or to store user preferences.
 These data files are typically sent by a website to the user's browser and are then stored on the user's device. 
 When the user visits the same website again, the browser sends the cookie back to the website, allowing the site
  to recognize the user, remember their preferences, and provide a more personalized experience.
            </div>
            <div className={styles.header}>
                What Cookies are we using?
            </div>
            <div className={styles.header}>Necessary</div>
            <div className={styles.cookieRow}>
                <div className={styles.cookieTitle}>csrftoken</div>
                <div className={styles.cookieDesc}>A CSRF token is a security measure used to mitigate CSRF attacks.</div>
            </div>

            <div className={styles.header}>Analytics</div>
            <div>
                <div className={styles.cookieRow}>
                <div className={styles.cookieTitle}>_ga</div>
                <div className={styles.cookieDesc}>The _ga cookie is associated with Google Analytics,
                     serving to uniquely identify and track users on a website.
                      It assigns a unique client ID to each user, enabling Google Analytics
                       to distinguish between different visitors. The cookie helps in generating statistical 
                       data on user interactions, session tracking, and user engagement.
                        It has a default expiration of 2 years and is commonly used to provide website
                         owners with insights into website traffic and user behavior.</div>
                </div>

                <div className={styles.cookieRow}>
                    <div className={styles.cookieTitle}>_ga_XN12JPYM6L</div>
                    <div className={styles.cookieDesc}>
                        Google Analytics sets this cookie to store and count page views.
                    </div>
                </div>
            </div>
            
            <div className={styles.endSpace}>

            </div>
        </div>
    )
}
export default CookiesReg