import { Fragment, useState, useEffect } from "react"
import {useParams} from "react-router-dom"
import { useSelector} from 'react-redux'
import styles from './details.module.css'
import NamStatus from "./namStatus"
import General from "./general"
import axios from 'axios'
import { authActions } from '../../../store/authSlice'
import { useDispatch } from 'react-redux'
import Tlo from '../../landing/blob1.png'
import { Link } from 'react-router-dom';
import {  useNavigate } from "react-router-dom"
import { BsStars } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";
import { API_URL } from "../../../Config/Config"
import LabMenu from "./labs/labMenu"
import jwt_decode from 'jwt-decode'
import DetailsBody from "./detailsBody"
import hand from './hand.png'
import hello from './hello_there.png'
import { FaCheckCircle } from "react-icons/fa";
function Details(props) {

    const navigate = useNavigate();
    const dispatch=useDispatch()
  
    const {slug} = useParams()
    const [dataDetails,setDataDetails]= useState()
    const [isLoading,setIsLoading]=useState(1)
    const [name,setName]=useState()
    const [organs,setOrgans]=useState()
    const [endpoints,setEndpoints]=useState()
    const [aop,setAop]=useState()
    const [regulations,setRegualtions]=useState()
    const [type,setType]=useState()
    const [stage,setStage]=useState()
    const [testingMethod,setTestingMethod]=useState()
    const [keyEvent, setKeyEvent]=useState()
    const [reference,setReference]=useState()
    const [namDesc,setNamDesc]=useState()
    const [nanoUsed,setNanoUsed]=useState()
    const [nanoValidated,setNanoValidated]=useState()
    const [nonNanoValidated,setNonNanoValidated]=useState()
    const [nanoUsedRef,setNanoUsedRef]=useState()
    const [mie,setMie]=useState()
    const [actualData,setActualData]=useState()
    const [allow,setAllow]=useState(0)
    const authToken=useSelector((state)=>state.auth.access)
    const coins=useSelector((state)=>state.auth.coins)
    const isAdmin=useSelector((state)=>state.auth.isAdmin)
    const isAmb=useSelector((state)=>state.auth.isAmbasador)
    const email=useSelector((state)=>state.auth.email)
    const subs=useSelector((state)=>state.auth.isSub)
    const testAccount=useSelector((state)=>state.auth.isTestAccount)
    const confirmed=useSelector((state)=>state.auth.isConfirmed)
    const trial=useSelector((state)=>state.auth.trial)
    const [loader,setLoader]=useState(0)
    const [providers,setProviders]=useState([])
   
   const [helloThere,setHelloThere]=useState(false)
    let references=[]
   
    

    const buy_link=`${process.env.REACT_APP_DOMAIN}/api/addUserNam`
    let data=JSON.stringify({
        email:email,
        id:slug})
    async function buyNam(e){
        e.preventDefault()
        const requestData=await axios.post(buy_link,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            dispatch(authActions.setCoins(response.data))
            window.location.reload()
           
        })
        .catch(function(error){
           
        })
        .finally(function(){
            
        })
        return

    }


    const check_link=`${process.env.REACT_APP_DOMAIN}/api/get_user_nam?email=${email}&&id=${slug}`
    {/*data=JSON.stringify({
        email:email,
    id:slug})*/}
    async function checkNam(){
       
        const requestData=await axios.get(check_link, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            if (response.data=='Owns'){
                setAllow(1)
            }
         
            
    
        })
        .catch(function(error){
         
        })
        .finally(function(){
            
        })
        return

    }

    async function tokenRef(){
        data=JSON.stringify({
            email:email})
   
        const requestData= await axios.post(`${process.env.REACT_APP_DOMAIN}/api/check_user`,data, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

        .then(function(response){
           
           dispatch(authActions.setIsSub(response.data[0].is_sub))
           dispatch(authActions.setIsActiveSub(response.data[0].is_active_sub))
           dispatch(authActions.setIsConfirmed(response.data[0].is_confirmed))
           dispatch(authActions.setTrial(response.data[0].trial_check))
           dispatch(authActions.setCoins(response.data[0].tokens))

            
        })
        .catch(function(error){
       
        })
        .finally(function(){
            
        })
        return
    }



    async function getProviders(){
        data=JSON.stringify({
            id:slug
        })
        const request = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/nam_service_providers?id=${slug}`,{
            headers:{
                'Content-Type':'application/json'
        }})
        .then(function(response){
           
            setProviders(response.data.map((x,i)=>{
                x.key=i+1
                return x
            }))
            setLoader(1)
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }
    let timer=0
    const delay = ms => new Promise(res => setTimeout(res, ms));
    async function resend(){
        if (timer<5){
        data=JSON.stringify({
            email:email
        })
        timer=timer+1
        const request= await axios.post(`${process.env.REACT_APP_DOMAIN}/api/resend_conf_mail`,data,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }
        })
        .then(function(response){
            timer=timer+1
        })
    }
        if (timer===5){
            await delay(60000)
            timer=0
        }
    }
    
    function takeBack(){
        window.location='/explore'
    }

    useEffect(()=>{
        tokenRef()
        checkNam()
        window.scrollTo(0, 0)
        getProviders()
        
        setIsLoading(0)
    },[])
   
  
    return(
        <Fragment>
        <img src={Tlo} alt="" className={styles.tlo}/>

        {isLoading==0 && isAmb ?
           
               <DetailsBody />:isLoading==0 &&isAdmin?<DetailsBody/>:isLoading==0&&confirmed?<DetailsBody/>:<div></div>}
          {/* FOR ADMIN */}
          {/* {isLoading==0 && isAdmin &&
           <div className={styles.block} >
             
                <div >
                    <div className={styles.name}>{name}</div>
             
                    <div className={styles.rows}>
                        <div className={styles.namStatus}>
                            <NamStatus  used={nanoUsed} usedRef={nanoUsedRef} nanoVal={nanoValidated} nonNanoVal={nonNanoValidated} actualData={actualData}/>
                        </div>
                        <div className={styles.general}>
                            <General keyEvent={keyEvent} test={testingMethod} aop={aop} regulations={regulations} organs={organs} endpoint={endpoints}
                            desc={namDesc} reference={reference} mie={mie} type={type}/>
                        </div>
           
                    </div>
                </div>
            
                {namDesc!="-"&& namDesc!=undefined &&
                <div>
                    <div className={styles.desc}>NAM Description</div>
                    <div className={styles.descText}>{namDesc!="-"&&namDesc}</div>
                </div>
                }
              

                {references!="-" && references!=undefined &&
                <div className={styles.linkRow}>
                    {reference !="-" && reference !=undefined &&<div className={styles.subTitle}>{"Link(s)"}:</div>}
                    {reference !="-" && reference !=undefined && <div className={styles.linker}>{reference.split(',').map(x=>x!="-" &&<div className={styles.reference}>{<a target="_blank" href={x}>{x}</a>}</div>)}</div>} 
                </div>
                }
             

                {loader==1&& <LabMenu providers={providers}/>}            
                <div className={styles.backLink}>
                <button className={styles.backButton} onClick={takeBack}><div className={styles.back}>Back</div> </button>
                </div>
                <div className={styles.space}></div>
                </div>} */}
            {/*USER NAM TRIAL TOKEN*/}
            {/* {isLoading==0 && !isAdmin && allow==1 && trial && email && !isAmb && !subs &&
                <div className={styles.block} >
           
                <div >
                    <div className={styles.name}>{name}</div>
              
                    <div className={styles.rows}>
                        <div className={styles.namStatus}>
                            <NamStatus  used={nanoUsed} usedRef={nanoUsedRef} nanoVal={nanoValidated} nonNanoVal={nonNanoValidated} actualData={actualData}/>
                        </div>
                        <div className={styles.general}>
                            <General keyEvent={keyEvent} test={testingMethod} aop={aop} regulations={regulations} organs={organs} endpoint={endpoints}
                            desc={namDesc} reference={reference} mie={mie} type={type}/>
                        </div>
           
                    </div>
                </div>

                {namDesc!="-"&& namDesc!=undefined &&
               <div>
                    <div className={styles.desc}>NAM Description</div>
                    <div className={styles.descText}>{namDesc!="-"&&namDesc}</div>
                </div>
                }
            

                {references!="-" && references!=undefined &&
                <div className={styles.linkRow}>
                    {reference !="-" && reference !=undefined &&<div className={styles.subTitle}>{"Link(s)"}:</div>}
                    {reference !="-" && reference !=undefined && <div className={styles.linker}>{reference.split(',').map(x=>x!="-" &&<div className={styles.reference}>{<a target="_blank" href={x}>{x}</a>}</div>)}</div>} 
                </div>
                }

           
                <div className={styles.backLink}>
                <button className={styles.backButton} onClick={takeBack}><div className={styles.back}>Back</div> </button>
                </div>
                <div className={styles.space}></div>
                </div>} */}

            {/*LOGIN TO SEE*/}
            { !isAdmin && !email  && isLoading==0 && !isAmb &&
            <div className={styles.block} >
                
                <div className={styles.blure}>
                    <div className={styles.name}>{name}</div>
              
                    <div className={styles.rows}>
                        <div className={styles.namStatus}>
                            <NamStatus  used="buy" usedRef="subscribe to see" nanoVal="subscribe to see" nonNanoVal="subscribe to see" actualData="subscribe to see"/>
                        </div>
                        <div className={styles.general}>
                            <General keyEvent="subscribe to see" test="subscribe to see" aop="subscribe to see" regulations="subscribe to see" organs="subscribe to see"
                             endpoint="subscribe to see"
                            desc="subscribe to see" reference="subscribe to see" mie="subscribe to see" type="subscribe to see"/>
                        </div>
           
                    </div>
                </div>
                <div className={styles.modalBlure}>
                    <div className={`${styles.subModalBox} ${styles.logInBox}`} id="detToShop">
                        <div className={styles.logInSubModal}>
                            <div className={`${styles.subModalElements} ${styles.logInSubBoxTwo}`}>
                                {/* {helloThere &&<img src={hello} className={styles.helloThere}/>} */}
                                <div >
                                    <div className={styles.loginBar}>
                                        <img src={hand} className={styles.hand} onMouseEnter={(e)=>setHelloThere(true)} onMouseLeave={(e)=>setHelloThere(false)}/>
                                    </div>
                                    <div className={styles.firstLine}>
                                        <div>
                                            <div className={styles.firstLineText} style={{fontWeight:"bold", marginTop:"10px"}}>Hello there!</div>
                                            <p className={styles.firstLineText}>Our database is<span style={{color:"#94C66B", fontWeight:"bold"}}> completely free</span> and does not require any fees.
                                             However, to fully access and enjoy all the features of
nams.network, please take a moment to register or log in. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.buttonRowLogReg}>
                                    <button className={`${styles.takeBack}  ${styles.logInRegBut}`} onClick={takeBack}>Take me back</button>
                                    <Link to='/login' className={styles.loginLink}><button className={`${styles.subscribeBut} ${styles.logInRegBut}`} >Log in / Register</button></Link>
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>
                
            </div>
            }

            {/* NOT SUB AND NO TRIAL*/}
            {/* { !isAdmin && email  && !subs  && !trial && isLoading==0 && !isAmb &&
            <div className={styles.block} >
                
                <div className={styles.blure}>
                    <div className={styles.name}>{name}</div>
                
                    <div className={styles.rows}>
                        <div className={styles.namStatus}>
                            <NamStatus  used="buy" usedRef="subscribe to see" nanoVal="subscribe to see" nonNanoVal="subscribe to see" actualData="subscribe to see"/>
                        </div>
                        <div className={styles.general}>
                            <General keyEvent="subscribe to see" test="subscribe to see" aop="subscribe to see" regulations="subscribe to see" organs="subscribe to see"
                             endpoint="subscribe to see"
                            desc="subscribe to see" reference="subscribe to see" mie="subscribe to see" type="subscribe to see"/>
                        </div>
           
                    </div>
                </div>
                <div className={styles.modalBlure}>
                    <div className={styles.subModalBox} id="detToShop">
                        <div className={styles.subModalElements}>
                            <div className={styles.subModalContent}>
                                <div className={styles.subscribeModalTitle}>
                                    <BsStars className={styles.stars}/>
                                    <div className={styles.subModalTitle}>Database access subscription plan  </div>

                                </div>
                                <div className={styles.subCost}>EUR €19,99/month</div>
                                {testAccount ===true ?
                                <form action={`${API_URL}/api/test_stripe_sub`} method="POST" name="STRIPE">
                                    <input name="email" value={email} className={styles.subInput}></input>
                                    <input name="slug" value={slug} className={styles.subInput}></input>
                                    <button className={styles.subscribeBut} >Subscribe</button>
                                </form>:
                                <form action={`${API_URL}/api/stripe_sub`} method="POST" name="STRIPE">
                                    <input name="email" value={email} className={styles.subInput}></input>
                                    <input name="slug" value={slug} className={styles.subInput}></input>
                                    <button className={styles.subscribeBut} >Subscribe</button>
                                </form>
                                }

                                <div className={styles.subList}>
                                    <div className={styles.listElement}>
                                        <GiCheckMark/>
                                        <div className={styles.listElementText}>Gives full access to the database.</div> 
                                    </div>
                                    <div className={styles.listElement}>
                                        <GiCheckMark/>
                                        <div className={styles.listElementText}>Regular updates to the database contents.</div> 
                                    </div>
                                    <div className={styles.listElement}>
                                        <GiCheckMark/>
                                        <div className={styles.listElementText}>New features coming soon.</div> 
                                    </div>
                                    <button className={styles.takeBack} onClick={takeBack}>Take me back</button>
                                </div>
                            </div>
                            
                           
                        </div>
                    </div>
                </div>
                
            </div>
            } */}
            {/*TRIAL ACC auth*/}
            {/* { !isAdmin && email  && !subs  && trial && allow==0 && confirmed && isLoading==0 && !isAmb &&
            <div className={styles.block} >
                
                <div className={styles.blure}>
                    <div className={styles.name}>{name}</div>
             
                    <div className={styles.rows}>
                        <div className={styles.namStatus}>
                            <NamStatus  used="buy" usedRef="subscribe to see" nanoVal="subscribe to see" nonNanoVal="subscribe to see" actualData="subscribe to see"/>
                        </div>
                        <div className={styles.general}>
                            <General keyEvent="subscribe to see" test="subscribe to see" aop="subscribe to see" regulations="subscribe to see" organs="subscribe to see"
                             endpoint="subscribe to see"
                            desc="subscribe to see" reference="subscribe to see" mie="subscribe to see" type="subscribe to see"/>
                        </div>
                
                    </div>
                </div>
                <div className={`${styles.modalBlure} `}>
                    <div className={`${styles.subModalBox} ${styles.tokenModel}`} id="detToShop">
                        <div className={styles.subModalElements}>
                            <div className={styles.subModalContent }>
                                <div className={styles.modalText}>
                                   You have {coins} view tokens left.

                                </div>

                                {testAccount ===true  ?
                                coins==0 &&<form action={`${API_URL}/api/test_stripe_sub`} method="POST" name="STRIPE">
                                    <input name="email" value={email} className={styles.subInput}></input>
                                    <input name="slug" value={slug} className={styles.subInput}></input>
                                    <button className={styles.subscribeBut} >Subscribe</button>
                                </form>:
                                coins==0&&<form action={`${API_URL}/api/stripe_sub`} method="POST" name="STRIPE">
                                    <input name="email" value={email} className={styles.subInput}></input>
                                    <input name="slug" value={slug} className={styles.subInput}></input>
                                    <button className={styles.subscribeBut} >Subscribe</button>
                                </form>
                                }

                               {coins==0 &&
                               <div className={styles.subList}>
                                    <div className={styles.listElement}>
                                        <GiCheckMark/>
                                        <div className={styles.listElementText}>Gives full access to the database.</div> 
                                    </div>
                                    <div className={styles.listElement}>
                                        <GiCheckMark/>
                                        <div className={styles.listElementText}>Regular updates to the database contents.</div> 
                                    </div>
                                    <div className={styles.listElement}>
                                        <GiCheckMark/>
                                        <div className={styles.listElementText}>New features coming soon.</div> 
                                    </div>

                                </div>
                                }
                                <div className={styles.buttonRow}>
                                    <button className={styles.takeBack} onClick={takeBack}>Take me back</button>
                                    {coins >0 &&<button className={styles.addNam} onClick={buyNam}>Use token</button>}
                                </div>
                            </div>
                            
                            <div className={styles.space}> </div>
                        </div>
                        
                    </div>
                </div>
            
            </div>
            } */}
            {/*Not confirmed*/}
            { !isAdmin && email   && !confirmed && isLoading==0 && !isAmb &&
            <div className={styles.block} >
                
                <div className={styles.blure}>
                    <div className={styles.name}>{name}</div>
       
                    <div className={styles.rows}>
                        <div className={styles.namStatus}>
                            <NamStatus  used="buy" usedRef="subscribe to see" nanoVal="subscribe to see" nonNanoVal="subscribe to see" actualData="subscribe to see"/>
                        </div>
                        <div className={styles.general}>
                            <General keyEvent="subscribe to see" test="subscribe to see" aop="subscribe to see" regulations="subscribe to see" organs="subscribe to see"
                             endpoint="subscribe to see"
                            desc="subscribe to see" reference="subscribe to see" mie="subscribe to see" type="subscribe to see"/>
                        </div>
                
                    </div>
                </div>
                <div className={styles.modalBlure}>
                    <div className={`${styles.subModalBox} ${styles.middleModal}`} id="detToShop">
                        <div className={styles.subModalElements}>
                            <div className={styles.subModalContent}>
                            <div className={styles.loginBar}>
                                <FaCheckCircle className={styles.checkMark}/>
                            </div>
                                <div className={styles.dearUser} style={{fontWeight:"bold", marginTop:"10px"}}>Almost there! </div>
                                <div className={styles.authRow}>
                                    <p className={styles.authText}>
                                    To complete your registration, please confirm your account via the verification link sent to your email.
                                     If you didn’t receive the email, you can click <span className={styles.resend} onClick={resend}>here </span>
                                    to resend it. 
                                    </p>
                                </div>
                               

                                <div className={styles.buttonRow}>
                                    <button className={styles.takeBack} onClick={takeBack}>Take me back</button>
                                    <button className={styles.addNam} onClick={()=>{window.location.reload()}}>Refresh page</button>
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>
        
            </div>
            }
            {/*SUB ACC*/}
            {/* {isLoading==0 && !isAdmin && subs  && email && !isAmb &&
            <div className={styles.block} >
            
            <div >
                <div className={styles.name}>{name}</div>
              
                <div className={styles.rows}>
                    <div className={styles.namStatus}>
                        <NamStatus  used={nanoUsed} usedRef={nanoUsedRef} nanoVal={nanoValidated} nonNanoVal={nonNanoValidated} actualData={actualData}/>
                    </div>
                    <div className={styles.general}>
                        <General keyEvent={keyEvent} test={testingMethod} aop={aop} regulations={regulations} organs={organs} endpoint={endpoints}
                        desc={namDesc} reference={reference} mie={mie} type={type}/>
                   
                    </div>

             
                </div>
          
            </div>
            
         
            {namDesc!="-"&& namDesc!=undefined && 
           <div>
           <div className={styles.desc}>NAM Description</div>
           <div className={styles.descText}>{namDesc!="-"&&namDesc}</div>
       </div>
            }
           
            
            {references!="-" && references!=undefined &&
            <div className={styles.linkRow}>
               {reference !="-" && reference !=undefined &&<div className={styles.subTitle}>{"Link(s)"}:</div>}
                    {reference !="-" && reference !=undefined && <div className={styles.linker}>{reference.split(',').map(x=>x!="-" &&<div className={styles.reference}>{<a target="_blank" href={x}>{x}</a>}</div>)}</div>} 
            </div>
            }
        
            
            <div className={styles.backLink}>
                <button className={styles.backButton}onClick={takeBack} ><div className={styles.back}>Back</div> </button>
            </div>
            <div className={styles.space}></div>
           
            </div>}  */}

           



            

                 
        </Fragment>
    )
}
export default Details