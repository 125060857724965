import styles from "./detForm.module.css"
import DetRow from "./detRow"
import { useSelector } from "react-redux";
function DetForm(){
    const organ= useSelector((state) => state.manageEdit.organ)
    const key = useSelector((state) => state.manageEdit.key)
    const test =useSelector((state) => state.manageEdit.test)
    const endpoint = useSelector((state) => state.manageEdit.endpoint)
    const aop = useSelector((state) => state.manageEdit.aop)
    const regulations = useSelector((state) => state.manageEdit.regulations)
    const stage = useSelector((state) => state.manageEdit.stage)
    const type = useSelector((state) => state.manageEdit.type)
    const mie = useSelector((state) => state.manageEdit.mie)
   
    const namType=useSelector((state) => state.manageEdit.namType)

    const lib=[
        {
            name:"Organ/system",
            link:"organ",
            place1:"Name:",
            store:organ
        },

        {
            name:"Nam type",
            link:"namType",
            place1:"Name:",
            store:namType

        },

        {
            name:"Endpoint",
            link:"endpoint",
            place1:"Name:",
            store:endpoint
        },

        {
            name:"Document type",
            link:"type",
            place1:"Name:",
            store:type

        },
        {
            name:"Guidance/strategy",
            link:"test",
            place1:"Name:",
            place2:"Reference:",
            place3:"Description:",
            place4:"Title:",
            store:test
        },

        {
            name:"Regulations",
            link:"regulations",
            place1:"Name:",
            store:regulations
        },

        {
            name:"Stage of NAM development",
            link:"stage",
            place1:"Name:",
            store:stage
        },

        {
            name:"AOP",
            link:"aop",
            place1:"Name:",
            place2:"Reference:",
            store:aop
        },
        {
            name:"Key Event",
            link:"key_event",
            place1:"Name:",
            place2:"Reference:",
            store:key
        },
        
       
       
        
        
        
        
        
        {
            name:"MIE",
            link:"mie",
            place1:"Name:",
            place2:"Reference:",
            store:mie

        },
        ]
    return(
        <div className={styles.block}>
            <div className={styles.title}>Manage DB entries</div>

            <div className={styles.row}>
                {lib.map(x=>
                    <DetRow name={x.name} link={x.link} place1={x.place1}
                    place2={x.place2} place3={x.place3} place4={x.place4} store={x.store}/>)}
                
            </div>
        </div>
    )
}

export default DetForm