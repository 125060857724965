import styles from "./table.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { detailsActions } from "../../../../../store/detailsSlice";
import Select from "react-select";
import { authActions } from "../../../../../store/authSlice";
import Name from "./name";
import { AiOutlineCheck } from "react-icons/ai";
import { ImBlocked } from "react-icons/im";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { ImCheckboxChecked } from "react-icons/im";
import { IoMdCheckmark } from "react-icons/io";
import { ImCheckmark } from "react-icons/im";
import { FaArrowDown } from "react-icons/fa";
function Table(props) {
	const dispatch = useDispatch();
	const [regExp, setRegExp] = useState(false);
	const shopDate = Date.parse(useSelector((state) => state.env.shopDate));
	const today = new Date();
	const [isLoading, setIsLoading] = useState(0);
	const [data, setData] = useState([]);
	const [dataFiltered, setDataFiltered] = useState(data);
	const [dataMarked, setDataMarked] = useState(data);
	const [page, setPage] = useState(1);
	const [next, setNext] = useState();
	const [prev, setPrev] = useState();
	let userIds = [];
	const [userNams, setUserNams] = useState();
	const [timer, setTimer] = useState(0);
	const [allNamsCheck, setAllNamsCheck] = useState(0);
	const [allNams, setAllNams] = useState([]);

	let h_N = useSelector((state) => state.css.expH_N.payload);
	if (h_N == undefined) {
		h_N = 1;
	}

	let h_F = useSelector((state) => state.css.expH_F.payload);
	if (h_F == undefined) {
		h_F = 1;
	}

	const regExpFunc = (x) => {
		const newState = dataFiltered.map((obj) => {
			// 👇️ if id equals 2, update country property
			if (obj.id == x) {
				if (obj.regExp == false) {
					return { ...obj, regExp: true };
				} else {
					return { ...obj, regExp: false };
				}
			}

			// 👇️ otherwise return the object as is
			return obj;
		});

		setDataFiltered(newState);
	};

    const regExpFuncSecond = (x) => {
		const newState = props.selectedNames.map((obj) => {
			// 👇️ if id equals 2, update country property
			if (obj.id == x) {
				if (obj.regExp == false) {
					return { ...obj, regExp: true };
				} else {
					return { ...obj, regExp: false };
				}
			}

			// 👇️ otherwise return the object as is
			return obj;
		});

		props.setSelectedNames(newState);
	};

	function detailsChandler(x) {
		dispatch(detailsActions.setDetails(x));
	}

	function nextChandler(event) {
		event.preventDefault();
		if (page != pages[pages.length - 1]) {
			setPage(parseInt(page) + 1);
		} else {
			return;
		}
	}

	function prevChandler(event) {
		event.preventDefault();
		if (page != pages[0]) {
			setPage(parseInt(page) - 1);
		} else {
			return;
		}
	}
	function pagesNumber(length, records) {
		if (length % records != 0) {
			return length / records + 1;
		} else {
			return length / records;
		}
	}

	function pageChandler(event) {
		event.preventDefault();
		setPage(event.target.value);
	}
	const [pages, setPages] = useState(
		Array.from({ length: pagesNumber(1, 10) }, (_, i) => i + 1)
	);

	const stages = useSelector((state) => state.labsFilter.stages);

	const tests = useSelector((state) => state.labsFilter.tests);

	const endpoints = useSelector((state) => state.labsFilter.endpoints);
	const organs = useSelector((state) => state.labsFilter.organs);
	const regulations = useSelector((state) => state.labsFilter.regulations);
	const aops = useSelector((state) => state.labsFilter.aops);
	const used = useSelector((state) => state.labsFilter.used);
	const namType = useSelector((state) => state.labsFilter.namType);
	const name = useSelector((state) => state.labsFilter.name);

	let nameValue = [];
	if (name != null) {
		if (name.payload) {
			nameValue = name.payload.map((x) => x.name);
		} else {
			nameValue = name.map((x) => x.name);
		}
	}
	let stagesValue = [];
	if (stages != null) {
		if (stages.payload) {
			stagesValue = stages.payload.map((x) => x.code);
		} else {
			stagesValue = stages.map((x) => x.code);
		}
	}

	let namTypeValue = [];
	if (namType != null) {
		if (namType.payload) {
			namTypeValue = namType.payload.map((x) => x.code);
		} else {
			namTypeValue = namType.map((x) => x.code);
		}
	}

	let usedValue = [];
	if (used != null) {
		if (used.payload) {
			usedValue = used.payload.map((x) => x.code);
		} else {
			usedValue = used.map((x) => x.code);
		}
	}

	let organsValue = [];
	if (organs != null) {
		if (organs.payload) {
			organsValue = organs.payload.map((x) => x.code);
		} else {
			organsValue = organs.map((x) => x.code);
		}
	}

	let endpointsValue = [];
	if (endpoints != null) {
		if (endpoints.payload) {
			endpointsValue = endpoints.payload.map((x) => x.code);
		} else {
			endpointsValue = endpoints.map((x) => x.code);
		}
	}

	let regulationsValue = [];
	if (regulations != null) {
		if (regulations.payload) {
			regulationsValue = regulations.payload.map((x) => x.code);
		} else {
			regulationsValue = regulations.map((x) => x.code);
		}
	}

	let aopsValue = [];
	if (aops != null) {
		if (aops.payload) {
			aopsValue = aops.payload.map((x) => x.code);
		} else {
			aopsValue = aops.map((x) => x.code);
		}
	}

	let testsValue = [];
	if (tests != null) {
		if (tests.payload) {
			testsValue = tests.payload.map((x) => x.code);
		} else {
			testsValue = tests.map((x) => x.code);
		}
	}

	const authToken = useSelector((state) => state.auth.access);
	const email = useSelector((state) => state.auth.email);

	async function getAllNams() {
		setAllNamsCheck(0);
		const request = await axios
			.get(`${process.env.REACT_APP_DOMAIN}/api/nam`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(function (response) {
				setAllNams(response.data.map((x)=>{
                    x.regExp=false
                    x.is_checked=true
                    return x
                }));
               
               
				setAllNamsCheck(1);
			})
			.catch(function (error) {})
			.finally(function () {});
	}

	async function dataFilteredFetch(
		organsValue,
		stagesValue,
		testsValue,
		endpointsValue,
		aopsValue,
		regulationsValue,
		usedValue,
		namTypeValue,
		page
	) {
		/*http://127.0.0.1:8000/api/nam_list?name=&is_used_for_nano__in=${usedValue}&name_type__in=${namTypeValue}&stage__in=${stagesValue}&testing_method_strategy__in=${testsValue}&endpoint__in=${endpointsValue}&organ__in=${organsValue}&aop__in=${aopsValue}&key_event__in=&regulations=${regulationsValue}&page=${page}`*/
		/*`${process.env.REACT_APP_DOMAIN}/api/nam_list?name__in=&name_desc__in=&reference__in=&is_nano_validated__in=&is_used_for_nano__in=&aop__in=${aopsValue}&stage__in=${stagesValue}&testing_method_strategy__in=${testsValue}&end_org_id__in=&endpoint__in=${endpointsValue}&organ__in=${organsValue}&regulations=${regulationsValue}&page=${page}`*/
		const requestList = await axios
			.get(
				`${process.env.REACT_APP_DOMAIN}/api/nam_list?name=${nameValue}&is_used_for_nano=${usedValue}&name_type=${namTypeValue}&stage=${stagesValue}&testing_method_strategy=${testsValue}&endpoint=${endpointsValue}&organ=${organsValue}&aop=${aopsValue}&regulations=${regulationsValue}&key_event=&page=${page}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)

			.then(function (response) {
				setIsLoading(0);

				setDataFiltered(
					response.data.results.map((x) => {
						x.is_checked = false;
						if (userIds.includes(x.reg_id)) {
							x.marker = true;
						} else {
							x.marker = false;
						}
						x.regExp = false;
						if (props.selectedId.includes(x.id)) {
							x.is_checked = true;
						}

						return x;
					})
				);
				setNext(response.data.next);
				setPrev(response.data.previous);
				setPages(
					Array.from(
						{ length: pagesNumber(response.data.count, 10) },
						(_, i) => i + 1
					)
				);
			})
			.catch(function (error) {
				if (error.toJSON().status == 401) {
					dispatch(authActions.setEmail(null));
					dispatch(authActions.setAccess(null));
					dispatch(authActions.setReload(null));
					dispatch(authActions.setLoged(false));
					localStorage.removeItem("authTokens");
				}
			})
			.finally(function () {});
		return;
	}

	async function dataFilteredFetchPage(
		organsValue,
		stagesValue,
		testsValue,
		endpointsValue,
		aopsValue,
		regulationsValue,
		usedValue,
		namTypeValue,
		page
	) {
		/*http://127.0.0.1:8000/api/nam_list?name=&is_used_for_nano__in=${usedValue}&name_type__in=${namTypeValue}&stage__in=${stagesValue}&testing_method_strategy__in=${testsValue}&endpoint__in=${endpointsValue}&organ__in=${organsValue}&aop__in=${aopsValue}&key_event__in=&regulations=${regulationsValue}&page=${page}`*/
		/*`${process.env.REACT_APP_DOMAIN}/api/nam_list?name__in=&name_desc__in=&reference__in=&is_nano_validated__in=&is_used_for_nano__in=&aop__in=${aopsValue}&stage__in=${stagesValue}&testing_method_strategy__in=${testsValue}&end_org_id__in=&endpoint__in=${endpointsValue}&organ__in=${organsValue}&regulations=${regulationsValue}&page=${page}`*/
		const requestList = await axios
			.get(
				`${process.env.REACT_APP_DOMAIN}/api/nam_list?name=${nameValue}&is_used_for_nano=${usedValue}&name_type=${namTypeValue}&stage=${stagesValue}&testing_method_strategy=${testsValue}&endpoint=${endpointsValue}&organ=${organsValue}&aop=${aopsValue}&regulations=${regulationsValue}&key_event=&page=${page}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)

			.then(function (response) {
				setIsLoading(0);
				setDataFiltered(
					response.data.results.map((x) => {
						x.is_checked = false;
						if (userIds.includes(x.reg_id)) {
							x.marker = true;
						} else {
							x.marker = false;
						}
						x.regExp = false;
						if (props.selectedId.includes(x.id)) {
							x.is_checked = true;
						}
						return x;
					})
				);

				setNext(response.data.next);
				setPrev(response.data.previous);

				setPages(
					Array.from(
						{ length: pagesNumber(response.data.count, 10) },
						(_, i) => i + 1
					)
				);
			})
			.catch(function (error) {
				if (error.toJSON().status == 401) {
					dispatch(authActions.setEmail(null));
					dispatch(authActions.setAccess(null));
					dispatch(authActions.setReload(null));
					dispatch(authActions.setLoged(false));
					localStorage.removeItem("authTokens");
				}
			})
			.finally(function () {});
		return;
	}

	async function dataFilteredFetchFirst() {
		/*http://127.0.0.1:8000/api/nam_list?name=&is_used_for_nano__in=${usedValue}&name_type__in=${namTypeValue}&stage__in=${stagesValue}&testing_method_strategy__in=${testsValue}&endpoint__in=${endpointsValue}&organ__in=${organsValue}&aop__in=${aopsValue}&key_event__in=&regulations=${regulationsValue}&page=${page}`*/
		/*`${process.env.REACT_APP_DOMAIN}/api/nam_list?name__in=&name_desc__in=&reference__in=&is_nano_validated__in=&is_used_for_nano__in=&aop__in=${aopsValue}&stage__in=${stagesValue}&testing_method_strategy__in=${testsValue}&end_org_id__in=&endpoint__in=${endpointsValue}&organ__in=${organsValue}&regulations=${regulationsValue}&page=${page}`*/
		const requestList = await axios
			.get(`${process.env.REACT_APP_DOMAIN}/api/nam_list?page=1`, {
				headers: {
					"Content-Type": "application/json",
				},
			})

			.then(function (response) {
				setIsLoading(0);
				setDataFiltered(
					response.data.results.map((x) => {
						if (userIds.includes(x.reg_id)) {
							x.marker = true;
						} else {
							x.marker = false;
						}
						x.regExp = false;
						return x;
					})
				);

				setNext(response.data.next);
				setPrev(response.data.previous);
				setPages(
					Array.from(
						{ length: pagesNumber(response.data.count, 10) },
						(_, i) => i + 1
					)
				);
			})
			.catch(function (error) {
				if (error.toJSON().status == 401) {
					dispatch(authActions.setEmail(null));
					dispatch(authActions.setAccess(null));
					dispatch(authActions.setReload(null));
					dispatch(authActions.setLoged(false));
					localStorage.removeItem("authTokens");
				}
			})
			.finally(function () {});
		return;
	}

	async function UserList() {
		const requestList = await axios
			.get(
				`${process.env.REACT_APP_DOMAIN}/api/get_user_nam?email=${email}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + String(authToken),
					},
				}
			)

			.then(function (response) {
				userIds = response.data.map((a) => a.nam_reg_id);
			})
			.catch(function (error) {})
			.finally(function () {});
		return;
	}

	useEffect(() => {
		getAllNams();
	}, []);

	useEffect(() => {
		UserList();

		setIsLoading(1);

		setPage(1);

		/*setDataFiltered(data.filter(x=> stagesValue.includes(x.stage)&&testsValue.includes(x.testing_method_strategy)&&
            endpointsValue.includes(x.endpoint)&&organsValue.includes(x.organ)))*/
		dataFilteredFetch(
			organsValue,
			stagesValue,
			testsValue,
			endpointsValue,
			aopsValue,
			regulationsValue,
			usedValue,
			namTypeValue,
			1
		);
	}, [
		stages,
		tests,
		organs,
		endpoints,
		aops,
		regulations,
		namType,
		used,
		name,
	]);

	useEffect(() => {
		UserList();
		setIsLoading(1);
		if (page == undefined) {
		} else {
			/*setDataFiltered(data.filter(x=> stagesValue.includes(x.stage)&&testsValue.includes(x.testing_method_strategy)&&
            endpointsValue.includes(x.endpoint)&&organsValue.includes(x.organ)))*/
			dataFilteredFetchPage(
				organsValue,
				stagesValue,
				testsValue,
				endpointsValue,
				aopsValue,
				regulationsValue,
				usedValue,
				namTypeValue,
				page
			);
		}

		setPageSelected({ value: page, label: page });
	}, [page]);

	const colorStyle = {
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "30px",
		}),

		control: (styles) => ({
			...styles,
			backgroundColor: "rgb(238,238,238)",
			minHeight: "30px",
			height: "30px",
		}),
		valueContainer: (provided, state) => ({
			...provided,
			textOverflow: "ellipsis",
			width: "150px",
			maxWidth: "150px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			display: "flex",
			flexDirection: "row",
			height: "30px",
			maxHeight: "30px",
		}),
	};

	const pages_list = pages.map((x) => ({ value: x, label: x }));
	const [pageSelected, setPageSelected] = useState({ value: 1, label: 1 });
	function pageListChandler(e) {
		setPage(e.value);
	}

	function checkHandler(e) {
		e.preventDefault();
		setDataFiltered(
			dataFiltered.map((x) => {
				if (x.id == e.target.value) {
					x.is_checked = !x.is_checked;
				}
				return x;
			})
		);
		const name = dataFiltered.filter((x) => x.id == e.target.value)[0].name;
		const id = dataFiltered.filter((x) => x.id == e.target.value)[0].id;

		if (
			dataFiltered.filter((x) => x.id == e.target.value)[0].is_checked ==
			true
		) {
			

			if (props.selectedId.indexOf(id) == -1) {
				props.setSelectedId([id, ...props.selectedId]);

				props.setSelectedNames(
					allNams
						.filter((x) => [id, ...props.selectedId].includes(x.id))
				);
                
			}
		}

		if (
			dataFiltered.filter((x) => x.id == e.target.value)[0].is_checked ==
			false
		) {
			
            
			if (props.selectedId.indexOf(id) != -1) {
          
				const index = props.selectedId.indexOf(id);
				const before = props.selectedId.slice(0, index);
				const after = props.selectedId.slice(
					index + 1,
					props.selectedId.length
				);

				props.setSelectedId(before.concat(after));
                
				props.setSelectedNames(
					allNams
						.filter((x) => before.concat(after).includes(x.id))
				);
			}
		}
	}

	return (
		<div className={styles.block}>
			<div className={styles.tables}>
				<div className={styles.table_block}>
					{isLoading == 0 && h_F == 0 && (
						<table className={styles.nfTable}>
							<thead>
								<tr>
									<th className={styles.detailsTH}>Select</th>
									<th className={styles.detailsTH}>Name</th>
									<th className={styles.detailsTH}>Method</th>
									<th className={styles.detailsTH}>
										<div>Stage for</div>{" "}
										<div>NAMs development</div>
									</th>
									<th className={styles.detailsTH}>
										Applied to ENMs
									</th>
									<th className={styles.detailsTH}>
										<div>Organ/</div>
										<div>system</div>
									</th>
									<th className={styles.detailsTH}>
										<div>Guidance/</div>
										<div>strategy</div>
									</th>
									<th className={styles.detailsTH}>
										Endpoint
									</th>
									<th className={styles.detailsTH}>AOP</th>
									<th className={styles.detailsTH}>
										Regulation(s)
									</th>
								</tr>
							</thead>
							<tbody>
								{dataFiltered.map((x) => (
									<tr>
										<td className={styles.detailsTH}>
											{x.is_checked ? (
												<button
													className={styles.checker}
													value={x.id}
													onClick={checkHandler}
												>
													<ImCheckmark
														className={
															styles.checked
														}
													/>
												</button>
											) : (
												<button
													className={styles.checker}
													value={x.id}
													onClick={checkHandler}
												></button>
											)}
										</td>
										<td>{x.name}</td>
										<td className={styles.detailsTH}>
											<div className={styles.detLink}>
												{x.name_type}
											</div>
										</td>
										<td className={styles.detailsTH}>
											{x.stage}
										</td>

										{x.is_used_for_nano == "Yes" ? (
											<td className={styles.status}>
												<div className={styles.detLink}>
													<div
														className={
															styles.validated
														}
													>
														Yes
													</div>
												</div>
											</td>
										) : x.is_used_for_nano == "No" ? (
											<td className={styles.status}>
												<div className={styles.detLink}>
													<div
														className={
															styles.not_validated
														}
													>
														No
													</div>
												</div>
											</td>
										) : (
											<td className={styles.status}>
												<div
													className={
														styles.not_validated
													}
												>
													{x.is_used_for_nano}
												</div>
											</td>
										)}

										<td>
											<div className={styles.detLink}>
												{x.organ}
											</div>
										</td>
										<td className={styles.detailsTH}>
											{x.testing_method_strategy}
										</td>
										<td className={styles.detailsTH}>
											{x.endpoint}
										</td>
										<td>
											<div className={styles.detLink}>
												{x.aop}
											</div>
										</td>
										{x.regExp && (
											<td className={styles.detailsTH}>
												{x.regulations
													.split(";")
													.map((reg) => (
														<div>{reg}</div>
													))}
												<button
													className={styles.regBut}
													value={x.id}
													onClick={() =>
														regExpFunc(x.id)
													}
												>
													<RiArrowDropUpLine
														className={
															styles.regExp
														}
													/>
												</button>
											</td>
										)}
										{x.regExp == false && (
											<td className={styles.detailsTH}>
												{x.regulations
													.split(";")
													.slice(0, 3)
													.map((reg) => (
														<div>{reg}</div>
													))}
												<button
													className={styles.regBut}
													value={x.id}
													onClick={() =>
														regExpFunc(x.id)
													}
												>
													<RiArrowDropDownLine
														className={
															styles.regExp
														}
													/>
												</button>
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
					)}

					{isLoading == 0 && h_F == 1 && (
						<table className={styles.fTable}>
							<thead>
								<tr>
									<th className={styles.detailsTH}>Select</th>
									<th className={styles.detailsTH}>Name</th>
									<th className={styles.detailsTH}>Method</th>
									<th className={styles.detailsTH}>
										<div>Stage for</div>{" "}
										<div>NAMs development</div>
									</th>
									<th className={styles.detailsTH}>
										Applied to ENMs
									</th>
									<th className={styles.detailsTH}>
										<div>Organ/</div>
										<div>system</div>
									</th>
									<th className={styles.detailsTH}>
										<div>Guidance/</div>
										<div>strategy</div>
									</th>
									<th className={styles.detailsTH}>
										Endpoint
									</th>
									<th className={styles.detailsTH}>AOP</th>
									<th className={styles.detailsTH}>
										Regulation(s)
									</th>
								</tr>
							</thead>
							<tbody>
								{dataFiltered.map((x) => (
									<tr>
										<td className={styles.detailsTH}>
											{x.is_checked ? (
												<button
													className={styles.checker}
													value={x.id}
													onClick={checkHandler}
												>
													<ImCheckmark
														className={
															styles.checked
														}
													/>
												</button>
											) : (
												<button
													className={styles.checker}
													value={x.id}
													onClick={checkHandler}
												></button>
											)}
										</td>
										<td>{x.name}</td>
										<td className={styles.detailsTH}>
											<div className={styles.detLink}>
												{x.name_type}
											</div>
										</td>
										<td className={styles.detailsTH}>
											{x.stage}
										</td>

										{x.is_used_for_nano == "Yes" ? (
											<td className={styles.status}>
												<div className={styles.detLink}>
													<div
														className={
															styles.validated
														}
													>
														Yes
													</div>
												</div>
											</td>
										) : x.is_used_for_nano == "No" ? (
											<td className={styles.status}>
												<div className={styles.detLink}>
													<div
														className={
															styles.not_validated
														}
													>
														No
													</div>
												</div>
											</td>
										) : (
											<td className={styles.status}>
												<div
													className={
														styles.not_validated
													}
												>
													{x.is_used_for_nano}
												</div>
											</td>
										)}

										<td>
											<div className={styles.detLink}>
												{x.organ}
											</div>
										</td>
										<td className={styles.detailsTH}>
											{x.testing_method_strategy}
										</td>
										<td className={styles.detailsTH}>
											{x.endpoint}
										</td>
										<td>
											<div className={styles.detLink}>
												{x.aop}
											</div>
										</td>
										{x.regExp && (
											<td className={styles.detailsTH}>
												{x.regulations
													.split(";")
													.map((reg) => (
														<div>{reg}</div>
													))}
												<button
													className={styles.regBut}
													value={x.id}
													onClick={() =>
														regExpFunc(x.id)
													}
												>
													<RiArrowDropUpLine
														className={
															styles.regExp
														}
													/>
												</button>
											</td>
										)}
										{x.regExp == false && (
											<td className={styles.detailsTH}>
												{x.regulations
													.split(";")
													.slice(0, 3)
													.map((reg) => (
														<div>{reg}</div>
													))}
												<button
													className={styles.regBut}
													value={x.id}
													onClick={() =>
														regExpFunc(x.id)
													}
												>
													<RiArrowDropDownLine
														className={
															styles.regExp
														}
													/>
												</button>
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
					)}

					{isLoading == 1 && (
						<div className={styles.ring}>
							<div className={styles.loadingTable}>Loading</div>
						</div>
					)}
				</div>
			</div>
			<div className={styles.table_elements}>
				<div className={styles.page_of_pages}>
					Page {page} of {pages[pages.length - 1]}
				</div>
				<div className={styles.listWBut}>
					<div className={styles.pager}>
						<Select
							options={pages_list}
							value={pageSelected}
							styles={colorStyle}
							onChange={pageListChandler}
							allowSelectAll={false}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							menuPlacement="top"
						/>
					</div>
					<div className={styles.table_but}>
						{page != 1 ? (
							<button
								onClick={prevChandler}
								className={
									page != 1
										? styles.next_but
										: styles.next_but_dis
								}
							>
								Previous
							</button>
						) : (
							<button
								className={
									page != 1
										? styles.next_but
										: styles.next_but_dis
								}
							>
								Previous
							</button>
						)}
						<button
							onClick={nextChandler}
							className={
								page != pages[pages.length - 1]
									? styles.next_but
									: styles.next_but_dis
							}
						>
							Next
						</button>
					</div>
				</div>
			</div>

			
				
			<div className={`${styles.signText} ${styles.blobTitle}`}>Selected NAMs:</div>
		

			<div className={styles.secondTable} id='secondTable'>
				<div className={styles.table_block}>

					{allNamsCheck==1 ?
                    <table className={styles.secondTable}>
						<thead>
								<tr>
									<th className={styles.detailsTH}>Select</th>
									<th className={styles.detailsTH}>Name</th>
									<th className={styles.detailsTH}>Method</th>
									<th className={styles.detailsTH}>
										<div>Stage for</div>{" "}
										<div>NAMs development</div>
									</th>
									<th className={styles.detailsTH}>
										Applied to ENMs
									</th>
									<th className={styles.detailsTH}>
										<div>Organ/</div>
										<div>system</div>
									</th>
									<th className={styles.detailsTH}>
										<div>Guidance/</div>
										<div>strategy</div>
									</th>
									<th className={styles.detailsTH}>
										Endpoint
									</th>
									<th className={styles.detailsTH}>AOP</th>
									<th className={styles.detailsTH}>
										Regulation(s)
									</th>
								</tr>
							</thead>
						<tbody>
							{props.selectedNames.map((x) => (
								<tr>
                                <td className={styles.detailsTH}>
                                    {x.is_checked ? (
                                        <button
                                            className={styles.checker}
                                            value={x.id}
                                            onClick={checkHandler}
                                        >
                                            <ImCheckmark
                                                className={
                                                    styles.checked
                                                }
                                            />
                                        </button>
                                    ) : (
                                        <button
                                            className={styles.checker}
                                            value={x.id}
                                            onClick={checkHandler}
                                        ></button>
                                    )}
                                </td>
                                <td>{x.name}</td>
                                <td className={styles.detailsTH}>
                                    <div className={styles.detLink}>
                                        {x.name_type}
                                    </div>
                                </td>
                                <td className={styles.detailsTH}>
                                    {x.stage}
                                </td>

                                {x.is_used_for_nano == "Yes" ? (
                                    <td className={styles.status}>
                                        <div className={styles.detLink}>
                                            <div
                                                className={
                                                    styles.validated
                                                }
                                            >
                                                Yes
                                            </div>
                                        </div>
                                    </td>
                                ) : x.is_used_for_nano == "No" ? (
                                    <td className={styles.status}>
                                        <div className={styles.detLink}>
                                            <div
                                                className={
                                                    styles.not_validated
                                                }
                                            >
                                                No
                                            </div>
                                        </div>
                                    </td>
                                ) : (
                                    <td className={styles.status}>
                                        <div
                                            className={
                                                styles.not_validated
                                            }
                                        >
                                            {x.is_used_for_nano}
                                        </div>
                                    </td>
                                )}

                                <td>
                                    <div className={styles.detLink}>
                                        {x.organ}
                                    </div>
                                </td>
                                <td className={styles.detailsTH}>
                                    {x.testing_method_strategy}
                                </td>
                                <td className={styles.detailsTH}>
                                    {x.endpoint}
                                </td>
                                <td>
                                    <div className={styles.detLink}>
                                        {x.aop}
                                    </div>
                                </td>
                                {x.regExp && (
                                    <td className={styles.detailsTH}>
                                        {x.regulations
                                            .split(";")
                                            .map((reg) => (
                                                <div>{reg}</div>
                                            ))}
                                        
                                        <button
                                            className={styles.regBut}
                                            value={x.id}
                                            onClick={() =>
                                                regExpFuncSecond(x.id)
                                            }
                                        >
                                            <RiArrowDropUpLine
                                                className={
                                                    styles.regExp
                                                }
                                            />
                                        </button>
                                    </td>
                                )}
                                {x.regExp == false && (
                                    <td className={styles.detailsTH}>
                                        {x.regulations
                                            .split(";")
                                            .slice(0, 3)
                                            .map((reg) => (
                                                <div>{reg}</div>
                                            ))}
                                            
                                        <button
                                            className={styles.regBut}
                                            value={x.id}
                                            onClick={() =>
                                                regExpFuncSecond(x.id)
                                            }
                                        >
                                            <RiArrowDropDownLine
                                                className={
                                                    styles.regExp
                                                }
                                            />
                                        </button>
                                    </td>
                                )}
                               
                            </tr>
							))}
						</tbody>
					</table>
                    :
                    <div className={styles.ring}>
							<div className={styles.loadingTable}>Loading</div>
						</div>
                    }
				</div>
			</div>
        </div>
	);
}

export default Table;
