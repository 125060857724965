import styles from "./modal.module.css"
import { useSelector } from "react-redux"
import axios from 'axios'
import {useState} from 'react'

function Modal(props){
    const authToken=useSelector((state)=>state.auth.access)
    const [existsError,setExistsError]= useState(null)
    
    const openModalChandler=(e)=>{
        props.setOpenModal(0)
    }
    
    
    async function submitChandler(e){
        e.preventDefault()
        if (props.place4){
           
            let data=JSON.stringify({
                name:e.target.name.value,
                reference:e.target.reference.value,
                description:e.target.description.value,
                title:e.target.title.value})
                const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/${props.link}Post`,data, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
                   
                    props.setOpenModal(3)
                    
                   
                })
                .catch(function(error){
                    
                   
                    if (error.response.data=="Such entry exists"){
                        setExistsError(error.response.data)
                    }
                    
                })
                .finally(function(){
                    
                })
            
            
        }
        
        else if (props.place2){
         
            let data=JSON.stringify({name:e.target.name.value,reference:e.target.reference.value})
            
            const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/${props.link}Post`,data, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
                    
                    props.setOpenModal(3)
                    
                   
                })
                .catch(function(error){
                    
                   
                    if (error.response.data=="Such entry exists"){
                        setExistsError(error.response.data)
                    }
                   
                    
                })
                .finally(function(){
                    
                })
            
            
        }
        else if (props.place1){
           
            let data=JSON.stringify({name:e.target.name.value})
            const requestList=await axios.post(`${process.env.REACT_APP_DOMAIN}/api/${props.link}Post`,data, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
                
                    props.setOpenModal(3)
                   
                })
                .catch(function(error){
                    
                   
                    if (error.response.data=="Such entry exists"){
                        setExistsError(error.response.data)
                    }
                    
                })
                .finally(function(){
                    
                })
            
        }
        
        
                return
    }

    
    const prevValTest=useSelector((state) => state.manageEdit.test)
    const prevValOrgan=useSelector((state) => state.manageEdit.organ)
    const prevValKey=useSelector((state) => state.manageEdit.key)
    const prevValEndpoint=useSelector((state) => state.manageEdit.endpoint)
    const prevValAOP=useSelector((state) => state.manageEdit.aop)
    const prevValRegulations=useSelector((state) => state.manageEdit.regulations)
    const prevValStage=useSelector((state) => state.manageEdit.stage)
    const prevValType=useSelector((state) => state.manageEdit.type)
    const prevValNamType=useSelector((state) => state.manageEdit.namType)
    const prevValMIE=useSelector((state) => state.manageEdit.mie)
   

    
    
    async function editChandler(e){
        e.preventDefault()
        
      
      

        if (props.place4){
      
            let data=JSON.stringify({
                name:prevValTest.name,
                newName:e.target.name.value,
                reference:e.target.reference.value,
                description:e.target.description.value,
                title:e.target.title.value})
                const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/${props.link}Edit`,data, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
                   
                    props.setOpenModal(4)
                    
                   
                })
                .catch(function(error){
                    if (error.response.data=="Such entry exists"){
                    setExistsError(error.response.data)
                    }
                    
                })
                .finally(function(){
                    
                })
            
            
        }
        
        else if (props.place2){
            let data=[]
            if (props.link=="key_event"){
                data=JSON.stringify({name:prevValKey.name,newName:e.target.name.value,reference:e.target.reference.value})
            }

            if (props.link=="aop"){
                data=JSON.stringify({name:prevValAOP.name,newName:e.target.name.value,reference:e.target.reference.value})
            }
            if (props.link=="mie"){
                data=JSON.stringify({name:prevValMIE.name,newName:e.target.name.value,reference:e.target.reference.value})
            }
        
            
            const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/${props.link}Edit`,data, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
                   
                    props.setOpenModal(4)
                   
                })
                .catch(function(error){
                    
                    if (error.response.data=="Such entry exists"){
                    setExistsError(error.response.data)
                    }
                    
                    
                })
                .finally(function(){
                    
                })
            
            
        }
        else if (props.place1){
            let data=[]
            if (props.link=="organ"){
                data=JSON.stringify({name:prevValOrgan.name,newName:e.target.name.value})
            }

            if (props.link=="endpoint"){
                data=JSON.stringify({name:prevValEndpoint.name,newName:e.target.name.value})
            }

            if (props.link=="regulations"){
                data=JSON.stringify({name:prevValRegulations.name,newName:e.target.name.value})
            }
            
            if (props.link=="stage"){
                data=JSON.stringify({name:prevValStage.name,newName:e.target.name.value})
            }

            if (props.link=="type"){
                data=JSON.stringify({name:prevValType.name,newName:e.target.name.value})
            }

            if (props.link=="namType"){
                
                data=JSON.stringify({name:prevValNamType.name,newName:e.target.name.value})
            }
            const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/${props.link}Edit`,data, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
               
                    props.setOpenModal(4)
                   
                })
                .catch(function(error){
                    if (error.response.data=="Such entry exists"){
                    setExistsError(error.response.data)
                    }
                
                 
                 
                    
                })
                .finally(function(){
                    
                })
            
        }
        
        
                return
    }
    
    
    
    return(
        <div className={styles.modal}>
            <div className={props.place4? styles.modal_content: props.place2? styles.modal_content2: props.place1&& styles.modal_content1}>
                <div className={styles.addForm}>
                    <div className={styles.title}>{props.type} {props.name}</div>


                {props.type=="Add" &&
                    <form onSubmit={submitChandler} className={styles.realAddForm}>
                        <div className={styles.formEle}>
                            {props.place1 && <div className={styles.formRow}>
                                            <div className={styles.formLabel}>{props.place1}</div>
                                            {props.name=="AOP"  ? <input name='name' required type="number" id={styles.inputer}></input> : props.name=="MIE" ? <input id={styles.inputer} name='name' required type="number"></input> :props.name=="Key Event" ?<input id={styles.inputer} name='name' required type="number"></input>:<input id={styles.inputer} name='name' required></input>}
                                         </div>}

                            {props.place2 && <div className={styles.formRow}>
                                            <div className={styles.formLabel}>{props.place2}</div>
                                            <input name='reference' type="url" id={styles.inputer}></input>
                                         </div>}

                            {props.place3 && <div className={styles.formRowArea}>
                                            <div className={styles.formLabel}>{props.place3}</div>
                                            <textarea name='description' id={styles.inputerArea}></textarea>
                                         </div>}

                            {props.place4 && <div className={styles.formRow}>
                                            <div className={styles.formLabel}>{props.place4}</div>
                                            <input name='title' id={styles.inputer}></input>
                                         </div>}
                            {existsError && <div className={styles.error}>{existsError}</div>}
                        </div>
                        <div className={styles.buttonRow}>
                            <button type="submit" className={styles.submitButton} >Submit</button>
                            <button className={styles.closeButton} onClick={openModalChandler}>Close</button>

                        </div>
                    </form>
                }
            

                {props.type=="Eddit" &&
                <form onSubmit={editChandler} className={styles.realAddForm}>
                    <div className={styles.formEle}>
                        {props.place1 && <div className={styles.formRow}>
                                        <div className={styles.formLabel}>{props.place1}</div>
                                        <input className={styles.inputer} name='name' required defaultValue={props.store.name} id={styles.editInputer}></input>
                                     </div>}
                
                        {props.place2 && <div className={styles.formRow}>
                                        <div className={styles.formLabel}> {props.place2}</div>
                                        <input name='reference' id={styles.inputer} defaultValue={props.val2} ></input>
                                     </div>}
                                
                        {props.place3 && <div className={styles.formRowArea}>
                                        <div className={styles.formLabel}>{props.place3}</div>
                                        <textarea name='description'  defaultValue={props.val3} id={styles.inputerArea}></textarea>
                                     </div>}
                
                        {props.place4 && <div className={styles.formRow}>
                                        <div className={styles.formLabel}>{props.place4}</div>
                                        <input name='title' id={styles.inputer} defaultValue={props.val4}></input>
                                     </div>}
                         {existsError && <div className={styles.error}>{existsError}</div>}
                    </div>
                    <div className={styles.buttonRow}>
                        <button type="submit"  className={styles.submitButton}>Submit</button>
                        <button className={styles.closeButton} onClick={openModalChandler}>Close</button>
                        
                    </div>
                </form>
                }
                </div>
            </div>
        </div>
    )
}

export default Modal