import styles from "./Invoice.module.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { useState } from "react";
import { AiOutlineEuro } from "react-icons/ai";
function Invoice(props) {
	const date = new Date(props.created * 1000);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const cost =
		String(props.amountDue).slice(0, 2) +
		"," +
		String(props.amountDue).slice(-2);
	const [expand, setExpand] = useState(false);
	function expander() {
		setExpand(!expand);
	}
	return (
		<div className={styles.block}>
			<div className={styles.firstRow}>
			{props.amountDue - props.amountPaid == 0 ?<div className={`${styles.sq} ${styles.paid}`}><AiOutlineEuro className={styles.euro}/></div>:<div className={`${styles.sq} ${styles.unpaid}`}><AiOutlineEuro className={styles.euro}/></div>}
			
					<div className={styles.date}>Payment Date: <span>{day}/{month}/{year}</span></div>
					<div className={styles.cost}>{cost} EUR</div>
						
					
				
				
			</div>
			
			<div className={styles.secondRow}>
					
					{props.amountDue - props.amountPaid == 0 ? (
						<div className={styles.success}>Paid</div>
					) : (
						<div className={styles.fail}>Payment failed</div>
					)}
					<div>
					<a href={props.link} target="_blank">
							<div className={styles.descLink}>
                                <div> Get Invoice</div>
								<FaExternalLinkAlt
									className={styles.linkIcon}
								/>
							</div>
						</a>
					</div>
			</div>
			
		</div>
	);
}
export default Invoice;
