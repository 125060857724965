import Sidebar from "./sidebar/sidebar"
import ManageMenu from "./manageMenu/manageMenu"
import { Fragment } from "react"
import styles from "./manage.module.css"
import Tlo from '../landing/blob2.png'
function Manage(){
    return (
        <Fragment>
            <img src={Tlo} alt="" className={styles.tlo}/>
            <div className={styles.block}>
                <Sidebar/>
                <div className={styles.menu}>
                    <ManageMenu />
                </div>
            </div>
        </Fragment>
    )
}

export default Manage