import  Select  from "react-select";
import axios from "axios";
import {useState,useEffect} from "react"
import styles from "./selector.module.css"
import { components } from "react-select";
import { useSelector } from "react-redux";
import { authActions } from '../../../store/authSlice'
import { addActions } from "../../../store/addSlice";
import { useDispatch} from 'react-redux'
import { MultiSelect } from 'primereact/multiselect';
function MultiSelectorAdd(props){
        const dispatch=useDispatch()
        const[data,setData] =useState()
        const link=props.link
       
       
        const [selectedCities,setSelectedCities]=useState()
        
       
       
        const authToken=useSelector((state)=>state.auth.access)
        async function dataFetch(link){
            const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/${link}`, {
              headers:{
                  'Content-Type':'application/json',
                  'Authorization':"Bearer " + String(authToken)
              }})
        
            .then(function(response){
                
                setData(response.data.map((x)=>({value:x.name,label:x.name})))
                
                
                
               
            })
            .catch(function(error){
               
              if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
            })
            .finally(function(){
                
            })
            return
        }
        
        useEffect(()=>{
            dataFetch(link)
            
            
        },[])
    
    
       
    
        const handleChange = (selected) => {
           
            setSelectedCities(selected.value)
           
           
           
            dispatch(addActions.setRegulations(selected.value))
              
            
            
        }

        
    
        return(
            <div className={styles.bar}>
                
              <MultiSelect
                placeholder="Select"
                options={data}
                className={styles.mypanel2}
                 onChange={handleChange}
               filter
               display="chip"
              value={selectedCities}
              />
            
            
            
            </div>
            
        )
    
}

export default MultiSelectorAdd