import {createSlice} from '@reduxjs/toolkit'
const initialState={
    organ:[], key:[], test:[], endpoint:[], aop:[], regulations:[], stage:[], type:[], mie:[], namType:[]
      }

const manageEditSlice = createSlice({
    name: "manageEdit",
    initialState:initialState,
    reducers:{
        setOrgan(state,value){
            state.organ=value.payload
        },
        setKey(state,value){
            state.key=value.payload
        },
        setTest(state,value){
            state.test=value.payload
        },
        setEndpoint(state,value){
            state.endpoint=value.payload
        },
        setAop(state,value){
            state.aop=value.payload
        },
        setRegulations(state,value){
            state.regulations=value.payload
        },
        setStage(state,value){
            state.stage=value.payload
        },
        setType(state,value){
            state.type=value.payload
        },
        setMie(state,value){
            state.mie=value.payload
        },
        setNamType(state,value){
            state.namType=value.payload
        }
        
        
        
    }
})
export const manageEditActions =manageEditSlice.actions
export default manageEditSlice.reducer