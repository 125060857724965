import  Select  from "react-select";
import axios from "axios";
import {useState,useEffect} from "react"
import styles from "./selector.module.css"
import { useSelector } from "react-redux";
import { authActions } from '../../../../store/authSlice'
import { editActions } from "../../../../store/editSlice";
import { useDispatch } from 'react-redux'
import { Dropdown } from 'primereact/dropdown';
function Selector(props){
    const dispatch=useDispatch()
    const[data,setData] =useState()
    const link=props.link
    const [val,setVal]=useState(props.value)
    const [it,setIt]=useState(0)
    const [selectedCities, setSelectedCities] = useState();
    
   
    const authToken=useSelector((state)=>state.auth.access)
    
    async function dataFetch(link){
     if (props.link!='nam'){
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/${link}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
            .then(function(response){
                
                setData(response.data.map((x)=>({name:x.name,code:x.name})))
                
           
            })
        .catch(function(error){
             
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return
    }
    else{
        if (props.name=="Nano Validated"){
            let endpoints_list =[{name:'Yes',code:'Yes'},{name:'No',code:'No'},{name:'-',code:'-'}]
            
       
            setData(endpoints_list)
        }
    }
    } 

    
    useEffect(()=>{
      
        dataFetch(link)
        
    },[])


    

    const handleChangeTest = (selected) => {
       
        setSelectedCities(selected.value)
        setIt(1)
        
        if (props.name=="AOP"){
        dispatch(editActions.setAops(selected.value.code))
        }
        
        if (props.name=="Endpoint"){
            dispatch(editActions.setEndpoints(selected.value.code))
            }
        if (props.name=="Applied to ENMs"){
        dispatch(editActions.setUsed(selected.value.code))
        }
        if (props.name=="Nano Validated"){
        dispatch(editActions.setVal(selected.value.code))
        }
        if (props.name=="Key Event"){
        dispatch(editActions.setKeyEvent(selected.value.code))
        }
        if (props.name=="MIE"){
        dispatch(editActions.setMie(selected.value.code))
        }
        if (props.name=="NAM Type"){
        dispatch(editActions.setNamType(selected.value.code))
        }
        if (props.name=="Organ/system"){
        dispatch(editActions.setOrgans(selected.value.code))
        }
        
        if (props.name=="Stage of NAM development"){
        dispatch(editActions.setStages(selected.value.code))
        }
        if (props.name=="Guidance/strategy"){
        dispatch(editActions.setTests(selected.value.code))
        }
        if (props.name=="Type"){
            dispatch(editActions.setType(selected.value.code))
            }
        
        
    }

    return(
        <div className={styles.bar}>
            {it==0 ?
           <Dropdown value={{name:props.value,code:props.value}}  onChange={handleChangeTest} options={data} optionLabel="name" display="chip" filter
       placeholder="Select"  className={styles.mypanel}/>
       :
       <Dropdown value={selectedCities} onChange={handleChangeTest} options={data} optionLabel="name" display="chip" filter
       placeholder="Select"  className={styles.mypanel}/>
            }
        </div>
    )
}

export default Selector