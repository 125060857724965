import styles from "./modal.module.css"
import {IoMdCheckmarkCircle} from 'react-icons/io'
function ModalAdd(props){

    function okChandler(e){
        window.location.reload()
    }
    const style={color:"green",fontSize:'40px'}
    return(
        <div className={styles.modal}>
            <div className={styles.modal_content}>
                <IoMdCheckmarkCircle style={style}/>
                <div>Record {props.name} was added to base</div>
                <button className={styles.OkGood} onClick={okChandler}>Ok</button>
            </div>
        </div>
    )
}
export default ModalAdd