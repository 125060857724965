import styles from "./mainForm.module.css"
import {useState} from 'react'
import MainFormSelect from "./mainFormSelect"
import { useDispatch } from "react-redux"
import { editActions } from "../../../../store/editSlice"
function MainForm(props){
    const dispatch=useDispatch()
   
   function nameChandler(e){
    dispatch(editActions.setName(e.target.value))
    
   }
    return(
        <div className={styles.formBlock}>
            <div className={styles.row}>
                <div className={styles.label}>Name:</div>
                <div className={styles.name}>
                    <input maxLength="4999" type="text" id={styles.name_in} defaultValue={props.name} onChange={nameChandler} placeholder="Insert NAM name here"/>
                </div>
            </div>
            
            <MainFormSelect aop={props.aop} endpoints={props.endpoints} nanoValidated={props.nanoValidated} 
            nanoUsed={props.nanoUsed} keyEvent={props.keyEvent} mie={props.mie} namDesc={props.namDesc}  namType={props.namType}
            nanoUsedRef={props.nanoUsedRef}  organ={props.organ} reference={props.reference}  regulations={props.regulations}
             stage={props.stage} testingMethod={props.testingMethod}  type={props.type}
            
            />
        </div>
    )
}

export default MainForm