import styles from "./testModal.module.css"
function TestModal(props){
    const testModalChandler=(e)=>{
        props.setTestModal(0)
    }
    return(
        <div className={styles.moda}>
            <div className={styles.modalBlure}>
                <div className={`${styles.subModalBox} ${styles.logInBox}`} id="detToShop">
                    <div className={styles.subModalElements}>
                        <div className={styles.subModalContent}>
                            <div className={styles.row}>
                                <div className={styles.title}>Name:</div>
                                <div className={styles.nameText}>{props.testName}</div>
                            </div>
                            {props.testDesc!='-' && props.testDesc!=undefined &&
                            <div className={styles.row}>
                                <div className={styles.title}>Description:</div>
                                <div className={styles.descText}>{props.testDesc}</div>
                            </div>
                            }
                            {props.testRef!=undefined &&
                            <div className={styles.row}>
                                <div className={styles.title}>Link:</div>
                                <div className={styles.refText}><a href={props.testRef} target="_blank" >{props.testRef} </a></div>
                            </div>
                            }
                            <div className={styles.buttonRow}>
                            <button className={styles.button} onClick={testModalChandler}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestModal