import { Fragment } from "react"
import EditFilter from "./editMenu/editFilter"
import Sidebar from "./sidebar/sidebar"
import styles from "./manage.module.css"
import Tlo from '../landing/blob2.png'
function EditManage(){
    return(
        <Fragment>
            <img src={Tlo} alt="" className={styles.tlo}/>
            <div className={styles.block}>
                <Sidebar/>
                <div className={styles.menu}>
                    <EditFilter/>
                </div>
            </div>

        </Fragment>
    )
}

export default EditManage