import styles from "./NewsItem.module.css"
import { BsFillBookmarkFill, BsFillPencilFill, BsStarFill, BsStar } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { Button, Row, Col } from "react-bootstrap";
import axios from 'axios';
import ModalEdit from "./ModalEdit"
import { useState } from "react"
import swal from 'sweetalert';


function NewsItem({newsItem, NewsFetch, authToken, news, editable}){

    const [modalIsOpen, setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const deleteHandler = async (e) => {
        const body = {
            
            'id':newsItem.id
          }
        const config = {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            },
          };

          swal({
            title: "Are you sure?",
            text: `Do you want to delete this news: ${newsItem.title}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then( async (willDelete) => {
            if (willDelete) {
                await axios.put(`${process.env.REACT_APP_DOMAIN}/api/news_test_delete`,body, config);
                NewsFetch()
                swal("Chosen news was deleted", {
                icon: "success",
                });
            } else {
                swal("Chosen news will not be deleted",
                {
                icon: 'info',
                });
            }
          });
            
      };

      const starHandler = async (e) => {
        const config = {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            },
          };

          const body = {
            "title": newsItem.title,
            "date": newsItem.date,
            "text": newsItem.text,
            "stared": !newsItem.stared,
            "author": newsItem.author,
            'id':newsItem.id
          }
          
          if ((news.filter( el => {return el.stared == true}).length>=3) &&  (newsItem.stared==false)) {
            swal({
                title: "To many stars",
                text: "The maximum number of news you can distinguished with a star is 3",
                icon: "error",
                dangerMode: true,
              })
        } else {
            const { data } = await axios.put(`${process.env.REACT_APP_DOMAIN}/api/news_test_star`,body, config);
            NewsFetch()
        }
    
      };

    return (
            <div className={styles.row}>
                <Col>
                    <p className={styles.head}> <BsFillBookmarkFill className={styles.icon}/> {newsItem.date} - {newsItem.title}</p>
                    <p className={styles.newsText}>{newsItem.text}</p>
                </Col>
                <Col className={styles.outer} xs={2}>
                    <ModalEdit
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        newsItem={newsItem}
                        NewsFetch={NewsFetch}
                        >
                    </ModalEdit>
                    {editable!=false &&<div className={styles.verticalC}>
                        <button onClick={openModal} className={styles.icnBtn}><BsFillPencilFill className={styles.icons}/></button>
                        <button onClick={deleteHandler} className={styles.icnBtn}><FaTrash className={styles.icons}/></button>
                        {(newsItem.stared) ? (<button onClick={starHandler} className={styles.icnBtn}><BsStarFill className={styles.icons}/></button>
                        )  : (<button onClick={starHandler} className={styles.icnBtn}><BsStar className={styles.icons}/></button>)}
                    </div>
                    }
                </Col>         
            </div>

    )
}

export default NewsItem