import styles from "./editFilter.module.css"
import Filters from "./Filters"
import EditTable from "./table"

function EditFilter(){
   
    return(
        <div className={styles.editBox}>

                <div className={styles.title}>Search and select NAM to edit</div>

                <Filters />
                <EditTable />
        </div>
    )
}

export default EditFilter