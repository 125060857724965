import styles from "./modal.module.css"
import {IoMdCheckmarkCircle} from 'react-icons/io'
function ModalAdd(props){
    const style={color:"green",fontSize:'40px'}

    function okChandler(e){
        window.location.reload()
    }
    return(
    <div className={styles.modal}>
            <div className={styles.modal_content_info}>
                <IoMdCheckmarkCircle style={style}/>
                <div>Record was {props.type} to base</div>
                <button className={styles.OkGood} onClick={okChandler}>Ok</button>
            </div>
    </div>
                )

}
export default ModalAdd