
import {createSlice} from '@reduxjs/toolkit'
const initialState={regulations:JSON.parse(localStorage.getItem('regulations')), organs:JSON.parse(localStorage.getItem('organ')),
                         endpoints:JSON.parse(localStorage.getItem('endpoints')), aops:JSON.parse(localStorage.getItem('aops')),
                         stages:JSON.parse(localStorage.getItem('stage')), tests:JSON.parse(localStorage.getItem('test')),
                         name:JSON.parse(localStorage.getItem('name')), used:JSON.parse(localStorage.getItem('used')),
                         namType:JSON.parse(localStorage.getItem('type'))}
/*Slice najpierw klonuje i potem nadpisuje dlatego nie trzeba
kazdej wlasności wpisywać jeszcze raz i mozna uzywać ++*/
const FiltersSlice = createSlice({
    name: "filters",
    initialState,
    reducers:{
        setRegulations(state,value) {
            state.regulations=value
        },
        setOrgans(state,value) {
            state.organs=value
        },
        setEndpoints(state,value) {
            state.endpoints=value
        },
        setAops(state,value) {
            state.aops=value
        },
        setStages(state,value) {
            state.stages=value
        },
        setTests(state,value) {
            state.tests=value
        },
        
        setName(state,value){
            state.name=value
        },
        setUsed(state,value){
            state.used=value
        },
        setNamType(state,value){
            state.namType=value
        }
    }
})
export const filterActions =FiltersSlice.actions
export default FiltersSlice.reducer






