
import {createSlice} from '@reduxjs/toolkit'
const initialState={aops:'',endpoints:'', validated:'',used:'',
                    keyEvent:'',mie:'',name:'',nameDesc:'',namType:'',
                    usedRef:'',organs:'',reference:'',regulations:'',  stages:'',
                     tests:'',type:'',
                    }
/*Slice najpierw klonuje i potem nadpisuje dlatego nie trzeba
kazdej wlasności wpisywać jeszcze raz i mozna uzywać ++*/
const addSlice = createSlice({
    name: "add",
    initialState:initialState,
    reducers:{
        setAops(state,value) {
            state.aops=value
        },
        setEndpoints(state,value) {
            state.endpoints=value
        },
        setVal(state,value) {
            state.validated=value
        },
        setUsed(state,value){
            state.used=value
        },
        setKeyEvent(state,value){
            state.keyEvent=value
        },
        setMie(state,value){
            state.mie=value
        },
        setName(state,value){
            state.name=value
        },
        setNameDesc(state,value){
            state.nameDesc=value
        },
        setNamType(state,value){
            state.namType=value
        },
        setNanoRef(state,value){
            state.usedRef=value
        },
        setOrgans(state,value) {
            state.organs=value
        },
        setReference(state,value){
            state.reference=value
        },
        setRegulations(state,value) {
            state.regulations=value
        },
        setStages(state,value) {
            state.stages=value
        },
        setTests(state,value) {
            state.tests=value
        },
        setType(state,value){
            state.type=value
        },
        
        
       
    }
})
export const addActions =addSlice.actions
export default addSlice.reducer