import styles from './footer.module.css'
import { useSelector, useDispatch } from 'react-redux'
import logo from '../landing/logo_small.png'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {TbWorld} from 'react-icons/tb'
import {BsTelephone} from 'react-icons/bs'
import {CiMail} from 'react-icons/ci'
function Footer(){
    
    const iconStyles={color:'rgb(177, 177, 177)', fontSize:'40px'}
    const smallIcons={color:'rgb(177, 177, 177)', fontSize:'25px'}
    return(
     <div className={styles.footer}>
        <div className={styles.content}>
            <img src={logo} alt='logo' className={` ${styles.logo} `}></img>
            <div className={styles.icons}>
                <div><a target="_blank" href="https://pl.linkedin.com/company/qsar-lab" ><AiOutlineLinkedin className={styles.iconStyles}/></a></div>
                <a target="_blank" href="https://pl.linkedin.com/company/qsar-lab" ><div className={styles.linked}>https://www.linkedin.com/company/qsar-lab/</div></a>
            </div>
            
            <div className={styles.contact}>
                    <div className={styles.contacters}>
                        <div> <a target="_blank" href="https://www.qsarlab.com" ><TbWorld className={styles.smallIcons} /></a></div>
                        <a target="_blank" href='https://www.qsarlab.com' className={styles.a}><div>https://www.qsarlab.com</div></a>
                    </div>
                    <div className={styles.contacters}>
                        <div> <CiMail className={styles.smallIcons} /></div>
                        <div>nams.network@qsarlab.com</div>
                    </div>
                    <div className={styles.contacters}>
                        <div><BsTelephone className={styles.smallIcons} /></div>
                        <div className={styles.phone}>+48 795 160 760</div>
                    </div>
                    
            </div>
            
        </div>

        {/* <div className={styles.content2}>
            <img src={logo} alt='logo' className={` ${styles.logo} `}></img>
            <div className={styles.icons}>
                    <div className={styles.contacters}>
                        <div><BsTelephone className={styles.smallIcons} /></div>
                        <div className={styles.phone}>+48 795 160 760</div>
                    </div>
               
               
            </div>
            
            <div className={styles.contact}>
                    <div className={styles.contacters}>
                        <div> <a target="_blank" href="https://www.qsarlab.com" ><TbWorld className={styles.smallIcons} /></a></div>
                        <a target="_blank" href='https://www.qsarlab.com' className={styles.a}><div>https://www.qsarlab.com</div></a>
                    </div>
                    <div className={styles.contacters}>
                        <div><a target="_blank" href="https://outlook.office.com/mail/deeplink/compose/?mailtouri=mailto%3amailto%3acontact%40qsarlab.com" ><CiMail className={styles.smallIcons} /></a></div>
                        <a target="_blank" href='https://outlook.office.com/mail/deeplink/compose/?mailtouri=mailto%3amailto%3acontact%40qsarlab.com' className={styles.a}><div>contact@qsarlab.com</div></a>
                    </div>
                    <div><a target="_blank" href="https://pl.linkedin.com/company/qsar-lab" ><AiOutlineLinkedin className={styles.iconStyles}/></a></div>
                    
            </div>
            
        </div> */}
        
     </div>
        

    )
    

}

export default Footer