import styles from "./menu.module.css"
import MainForm from "./mainForm"
import axios from 'axios'
import {useParams} from "react-router-dom"
import {useState,useEffect} from 'react'
import { useSelector } from "react-redux"
import { authActions } from '../../../../store/authSlice'
import { useDispatch } from 'react-redux'
import { editActions } from "../../../../store/editSlice"
function Menu (){
    const dispatch=useDispatch()
    const {slug} = useParams()
    
    const [data,setData]=useState()

    const [name,setName]=useState()
    const [organs,setOrgans]=useState()
    const [endpoints,setEndpoints]=useState()
    const [aop,setAop]=useState()
    const [regulations,setRegualtions]=useState("")
    
    const [stage,setStage]=useState()
    const [testingMethod,setTestingMethod]=useState()
    const [keyEvent, setKeyEvent]=useState()
    const [reference,setReference]=useState()
    const [namDesc,setNamDesc]=useState()
    const [nanoUsed,setNanoUsed]=useState()
    const [nanoValidated,setNanoValidated]=useState()
   
    const [nanoUsedRef,setNanoUsedRef]=useState()
    const [namType,setNamType]=useState()
    const [mie,setMie]=useState()
    const [type,setType]=useState()

    const authToken=useSelector((state)=>state.auth.access)
    async function dataFetch(){
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/name_id?id=${slug}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
           
            setAop(response.data[0].aop)
            dispatch(editActions.setAops(response.data[0].aop))

            setEndpoints(response.data[0].endpoint)
            dispatch(editActions.setEndpoints(response.data[0].endpoint))
            
            setNanoValidated(response.data[0].is_nano_validated)
            dispatch(editActions.setVal(response.data[0].is_nano_validated))
            
            setNanoUsed(response.data[0].is_used_for_nano)
            
            dispatch(editActions.setUsed(response.data[0].is_used_for_nano))
            
            setKeyEvent(response.data[0].key_event)
            dispatch(editActions.setKeyEvent(response.data[0].key_event))
            
            setMie(response.data[0].mie)
            dispatch(editActions.setMie(response.data[0].mie))
            
            setName(response.data[0].name)
            dispatch(editActions.setName(response.data[0].name))
            
            setNamDesc(response.data[0].name_desc)
            dispatch(editActions.setNameDesc(response.data[0].name_desc))
            
            setNamType(response.data[0].name_type)
            dispatch(editActions.setNamType(response.data[0].name_type))
            
            setNanoUsedRef(response.data[0].nano_used_ref)
            dispatch(editActions.setNanoRef(response.data[0].nano_used_ref))
            
            setOrgans(response.data[0].organ)
            dispatch(editActions.setOrgans(response.data[0].organ))
            
            setReference(response.data[0].reference)
            dispatch(editActions.setReference(response.data[0].reference))
            
            setRegualtions(response.data[0].regulations)
            const testRegulations=response.data[0].regulations
            dispatch(editActions.setRegulations(response.data[0].regulations))
            
            setStage(response.data[0].stage)
            dispatch(editActions.setStages(response.data[0].stage))
            
            setTestingMethod(response.data[0].testing_method_strategy)
            dispatch(editActions.setTests(response.data[0].testing_method_strategy))
            
            setType(response.data[0].type)
            dispatch(editActions.setType(response.data[0].type))
            
           
        })
        .catch(function(error){
             
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return
    }


    const newName=useSelector((state) => state.edit.name.payload)
    const newAop=useSelector((state) => state.edit.aops.payload)
    const newEndpoint=useSelector((state) => state.edit.endpoints.payload)
    const newVal=useSelector((state) => state.edit.validated.payload)
    const newUsed=useSelector((state) => state.edit.used.payload)
    const newKeyEvent=useSelector((state) => state.edit.keyEvent.payload)
    const newMie=useSelector((state) => state.edit.mie.payload)
    const newNameDesc=useSelector((state) => state.edit.nameDesc.payload)
    const newNamType=useSelector((state) => state.edit.namType.payload)
    const newUsedRef=useSelector((state) => state.edit.usedRef.payload)
    const newOrgans=useSelector((state) => state.edit.organs.payload)
    const newReference=useSelector((state) => state.edit.reference.payload)
    const newRegulations=useSelector((state) => state.edit.regulations.payload)
    
    
   
    const newStage=useSelector((state) => state.edit.stages.payload)
    const newTest=useSelector((state) => state.edit.tests.payload)
    const newType=useSelector((state) => state.edit.type.payload)


    async function editPut(){
        const putData=JSON.stringify({id:slug,aop:newAop,endpoint:newEndpoint,is_nano_validated:newVal,
                                    is_used_for_nano:newUsed,key_event:newKeyEvent,mie:newMie,name:newName,
                                    name_desc:newNameDesc,name_type:newNamType,nano_used_ref:newUsedRef,
                                    organ:newOrgans,reference:newReference,regulations:newRegulations,
                                    stage:newStage,testing_method_strategy:newTest,type:newType,reference:newReference})
            
        
        const requestList=await axios.put(`${process.env.REACT_APP_DOMAIN}/api/nam_edit`,putData, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':"Bearer " + String(authToken)
                    }})
            
                .then(function(response){
             
                    window.location.reload()
                    
                   
                })
                .catch(function(error){
                    
                     
                  
                    
                })
                .finally(function(){
                    
                })
    }

    function descChandler(e){
        dispatch(editActions.setNameDesc(e.target.value))
    }
    function refChandler(e){
        dispatch(editActions.setReference(e.target.value))
    }
    function usedRefChandler(e){
        dispatch(editActions.setNanoRef(e.target.value))
    }
   
    useEffect(()=>{
        dataFetch()
    },[])
    return(
        <div className={styles.block}>
            <div className={styles.window}>
                <div className={styles.editing}>
                    Editing
                </div>

                <div className={styles.editingNam}>
                    Edit NAM primary details
                </div>

                <MainForm name={name} aop={aop} endpoints={endpoints} nanoValidated={nanoValidated} nanoUsed={nanoUsed}
                 keyEvent={keyEvent} mie={mie} organ={organs}   regulations={regulations}
                 stage={stage} testingMethod={testingMethod} reference={reference}
                namDesc={namDesc}   type={type} namType={namType}
                nanoUsedRef={nanoUsedRef}/>

                <div className={styles.editingNam2}>Edit metadata</div>

                <div className={styles.indent}>

                    <div className={styles.row}>
                        <div className={styles.rowForm}>
                            <div className={styles.label}>Description:</div>
                            <div className={styles.metaInput}>
                                 <textarea maxLength="49999" onChange={descChandler} type="text" id={styles.desc} placeholder="Insert description here"
                                 defaultValue={namDesc}></textarea>
                            </div>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.rowForm}>
                            <div className={styles.label}>Reference URL:</div>
                            <div className={styles.metaInput}>
                                 <textarea maxLength="4999" onChange={refChandler} type="text" id={styles.desc} placeholder="Insert NAM reference links separated by semicolon"
                                 defaultValue={reference}></textarea>
                            </div>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.rowForm}>
                            <div className={styles.label}>Publications:</div>
                            <div className={styles.metaInput}>
                                <textarea maxLength="4999" onChange={usedRefChandler} defaultValue={nanoUsedRef} type="text" id={styles.desc} placeholder="  Add doi publications separated by semicolon"></textarea>
                            </div>
                        </div>
                        <div className={styles.button1}><button onClick={editPut} className={styles.add}>Edit</button></div>
                    </div>
                    <div className={styles.button2}><button onClick={editPut} className={styles.add}>Edit</button></div>
                    <div className={styles.break}></div>
                </div>
            </div>
        </div>
    )
}

export default Menu