import Sidebar from "./sidebar/sidebar"
import styles from "./manage.module.css"
import DetForm from "./detMenu/detForm"
import Tlo from '../landing/blob2.png'
function DetManage(){
    return(
        <div className={styles.block}>
            <Sidebar/>
            <div className={styles.menu}>
                    <img src={Tlo} alt="" className={styles.tlo}/>
                    <DetForm/>
            </div>
        </div>
    )
}
export default DetManage