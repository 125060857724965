import  Select  from "react-select";
import axios from "axios";
import {useState,useEffect} from "react"
import styles from "./selector.module.css"
import { useSelector } from "react-redux";
import {authActions} from "../../../store/authSlice";
import { Dropdown } from 'primereact/dropdown';
import { addActions } from "../../../store/addSlice";
import { useDispatch } from 'react-redux'
function SelectorAdd(props){
    const dispatch=useDispatch()
    const[data,setData] =useState()
    const link=props.link
    const [val,setVal]=useState(props.value)
    const [it,setIt]=useState(0)
    const [selectedCities,setSelectedCities]=useState()
    const authToken=useSelector((state)=>state.auth.access)
    
    async function dataFetch(link){
        if(link!="nam"){
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/${link}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
          
            
            setData(response.data.map((x)=>({value:x.name,label:x.name})))
           
            
        })
        .catch(function(error){
             
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return
    }
    else{
            
        if (props.name=="Nano Validated"){
            let endpoints_list =[{value:'Yes',label:'Yes'},{value:'No',label:'No'},{value:'-',label:'-'}]
            
       
            setData(endpoints_list)
        }
    }
    }
    
    useEffect(()=>{
        dataFetch(link)
        
    },[])



    const handleChange = (selected) => {
        
        setSelectedCities(selected.value)
        setIt(1)
        
        if (props.name=="AOP"){
        dispatch(addActions.setAops(selected.value))
        
        }
        
        if (props.name=="Endpoint"){
            dispatch(addActions.setEndpoints(selected.value))
            }
        if (props.name=="Applied to ENMs"){
        dispatch(addActions.setUsed(selected.value))
        }
        if (props.name=="Nano Validated"){
        dispatch(addActions.setVal(selected.value))
        }
        if (props.name=="Key Event"){
        dispatch(addActions.setKeyEvent(selected.value))
        }
        if (props.name=="MIE"){
        dispatch(addActions.setMie(selected.value))
        }
        if (props.name=="NAM Type"){
        dispatch(addActions.setNamType(selected.value))
        }
        if (props.name=="Organ/system"){
        dispatch(addActions.setOrgans(selected.value))
        }
        
        if (props.name=="Stage of NAM development"){
        dispatch(addActions.setStages(selected.value))
        }
        if (props.name=="Guidance/strategy"){
        dispatch(addActions.setTests(selected.value))
        }
        if (props.name=="Type"){
            dispatch(addActions.setType(selected.value))
            }
        
        
    }

    return(
        <div className={styles.bar}>
            
          <Dropdown
             
            options={data}
            
            onChange={handleChange}
            className={styles.mypanel}
            filter
            placeholder="Select"
          value={selectedCities}
          />
        </div>
    )
}

export default SelectorAdd