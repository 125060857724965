import { Fragment,useState ,useEffect } from "react"
import styles from "./general.module.css"
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import {AiFillQuestionCircle} from "react-icons/ai"
import TestModal from "./testModal"
import { useSelector } from "react-redux"
import { authActions } from '../../../store/authSlice'
import { filterActions } from "../../../store/filterSlice"
import { useDispatch } from 'react-redux'
import AopKeMie from "./aopKeMie"
function General(props) {
    const dispatch=useDispatch()
    const navigate = useNavigate();
    let references=[]
    const [aopRef,setAopRef] = useState()
    const [mieRef,setMieRef] = useState()
    const [keyEventRef,setKeyEventRef] = useState()
    const [testRef,setTestRef]=useState()
    const [testDesc,setTestDesc]=useState()
    const authToken=useSelector((state)=>state.auth.access)

    function backExp(){
       
    }

    if (props.reference){
    references=props.reference.split(',')
    }
    
    async function dataDet(){
        const requestAOP=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/aop?name=${props.aop}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            setAopRef(response.data[0].reference)
            
          
           
        })
        .catch(function(error){
            
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })

        const requestMIE=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/mie?name=${props.mie}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            setMieRef(response.data[0].reference)
            
          
           
        })
        .catch(function(error){
            
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })



        const requestKey=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/key_event?name=${props.keyEvent}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            setKeyEventRef(response.data[0].reference)
            
          
           
        })
        .catch(function(error){
            
           
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })


        const requestTest=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/test?name=${props.test}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            setTestRef(response.data[0].reference)
            setTestDesc(response.data[0].description)
            
          
           
        })
        .catch(function(error){
          
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return
    }

    useEffect(()=>{
        dataDet()
    },[props.aop,props.test])

    const styleQ = {color:"rgb(27,68,128)", fontSize:"20px"}
    const [testModal,setTestModal]=useState(0)
    const testModalChandler=(e)=>{
        if(testModal===0){
            setTestModal(1)
        }
        if(testModal===1){
            setTestModal(0)
        }
        
    }
    return(
        <Fragment>
        <div className={styles.block}>
         <div>
            {props.organs!=undefined &&
            
            <div className={styles.details}>
                Details
            </div>
            }
            {props.organs!="-" && props.organs!=undefined &&
            <div className={styles.row}>
                <div className={styles.subTitle}><div>Organ/</div><div>system:</div></div>
                <div className={styles.genElement}>{props.organs}</div>
            </div>
            }

            {props.endpoint!="-"&& props.endpoint!=undefined &&
            <div className={styles.row}>
                <div className={styles.subTitle}>Endpoint:</div>
                <div className={styles.genElement}>{props.endpoint}</div>
            </div>
            }       
            {props.regulations!="-"&& props.regulations!=undefined &&
            <div className={styles.row}>
                <div className={styles.subTitle}>Regulation(s):</div>
                <div className={styles.genElementReg}>{props.regulations.split(';').map(reg=><div>{reg}</div>)}</div>
                
            </div>
            }

            {props.test!="-"&& props.test!=undefined &&
            <div className={styles.row}>
                <div className={styles.subTitle}><div>Guidance/</div><div>strategy:</div></div>
                <div className={styles.genElement}>{props.test}</div>
                <div className={styles.question}>

                {testRef!="-"&&  testRef!=undefined && <button className={styles.Qbutton} onClick={testModalChandler}><AiFillQuestionCircle className={styles.qMark}/></button>}
                {testRef=="-"&& testDesc!="-"&& testDesc!=undefined &&<button className={styles.Qbutton} onClick={testModalChandler}><AiFillQuestionCircle className={styles.qMark}/></button>}
                {testModal==1&&<TestModal testModal={testModal} setTestModal={setTestModal} testRef={testRef}
                                testDesc={testDesc} testName={props.test}/>}
                </div>
            </div>
            }

            {props.type!='-' && props.type!=undefined &&
            <div className={styles.row}>
                <div className={styles.subTitle}><div className={styles.subTitle}>Document</div> <div>type:</div></div>
                <div className={styles.genElementType}>{props.type}</div>
            
            </div>
            }

            
        </div>
        <div>
            <div className={styles.breakAop}></div>
            {props.aop!='-'?
            <AopKeMie aop={props.aop} aopRef={aopRef} keyEvent={props.keyEvent} keyEventRef={keyEventRef} mie={props.mie} mieRef={mieRef}/>:
            props.keyEvent!='-'?
            <AopKeMie aop={props.aop} aopRef={aopRef} keyEvent={props.keyEvent} keyEventRef={keyEventRef} mie={props.mie} mieRef={mieRef}/>:
            props.mie!='-' &&
            <AopKeMie aop={props.aop} aopRef={aopRef} keyEvent={props.keyEvent} keyEventRef={keyEventRef} mie={props.mie} mieRef={mieRef}/>
            }
        </div>
        </div>
        </Fragment>
       
    )
}

export default General