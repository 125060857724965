import { useSelector,useDispatch } from "react-redux"
import styles from "./accounts.module.css"
import axios from "axios"
import { useEffect } from "react"
import { authActions } from "../../store/authSlice"
import { useState } from "react"
import UserBlock from "./userBlock"
import  Select  from "react-select";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect"
function Accounts(){
    const dispatch=useDispatch()
    const [users,setUsers]=useState([])
    const authToken=useSelector((state)=>state.auth.access)
    const [next,setNext] = useState()
    const [prev,setPrev] = useState()
    const [page,setPage] = useState(1)
    const [pages,setPages] = useState(Array.from({length: pagesNumber(1,10)}, (_, i) => i + 1))
    const pages_list =pages.map((x) => ({value:x,label:x}))
    const [pageSelected,setPageSelected] = useState({value:1,label:1})
    const [email, setEmail] =useState('')
    const [roles,setRole]=useState([])
    const [emailOptions,setEmailOptions]=useState([])
    const all=[{label:"All Users",value:''}]

    const rolesOptions=[
        {label:'Admin', value:"is_admin"},
        {label:'Ambasador', value:"is_ambasador"},
        {label:'Moderator', value:"is_moderator"},
        {label:"User", value:"is_user"}
    ]
    function pagesNumber(length,records){
        if (length%records!=0){

            return (length/records) +1
        }
        else{
            return length/records
        }
    }

    async function getEmails(){
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/account_email_filter?roles=${roles}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
      
          .then(function(response){
             
            setEmailOptions(all.concat(response.data.map((x)=>({label:x.email,value:x.email}))))
          })
          .catch(function(error){
          
            if (error.toJSON().status==401){
              dispatch(authActions.setEmail(null))
          dispatch(authActions.setAccess(null))
          dispatch(authActions.setReload(null))
          dispatch(authActions.setLoged(false))
          localStorage.removeItem('authTokens')
          }
          })
          .finally(function(){
              
          })
          return
      }

    async function getAccounts(){
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/account_list?email=${email}&roles=${roles}`, {
          headers:{
              'Content-Type':'application/json',
              'Authorization':"Bearer " + String(authToken)
          }})
    
        .then(function(response){
            setPages(Array.from({length: pagesNumber(response.data.count,10)}, (_, i) => i + 1))
            setNext(response.data.next)
            setPrev(response.data.previous)
            setUsers(response.data.results)
            
        })
        .catch(function(error){
        
          if (error.toJSON().status==401){
            dispatch(authActions.setEmail(null))
        dispatch(authActions.setAccess(null))
        dispatch(authActions.setReload(null))
        dispatch(authActions.setLoged(false))
        localStorage.removeItem('authTokens')
        }
        })
        .finally(function(){
            
        })
        return
    }

    async function getNext(){
        const requestList=await axios.get(next, {
          headers:{
              'Content-Type':'application/json',
              'Authorization':"Bearer " + String(authToken)
          }})
    
        .then(function(response){
            setPage(page+1)
            setPageSelected({value:page+1,label:page+1})
            setPages(Array.from({length: pagesNumber(response.data.count,10)}, (_, i) => i + 1))
            setNext(response.data.next)
            setPrev(response.data.previous)
            setUsers(response.data.results)
        })
        .catch(function(error){
     
          if (error.toJSON().status==401){
            dispatch(authActions.setEmail(null))
        dispatch(authActions.setAccess(null))
        dispatch(authActions.setReload(null))
        dispatch(authActions.setLoged(false))
        localStorage.removeItem('authTokens')
        }
        })
        .finally(function(){
            
        })
        return
    }


    async function getPrev(){
        const requestList=await axios.get(prev, {
          headers:{
              'Content-Type':'application/json',
              'Authorization':"Bearer " + String(authToken)
          }})
    
        .then(function(response){
            setPage(page-1)
            setPageSelected({value:page-1,label:page-1})
            setPages(Array.from({length: pagesNumber(response.data.count,10)}, (_, i) => i + 1))
            setNext(response.data.next)
            setPrev(response.data.previous)
            setUsers(response.data.results)
        })
        .catch(function(error){
    
          if (error.toJSON().status==401){
            dispatch(authActions.setEmail(null))
        dispatch(authActions.setAccess(null))
        dispatch(authActions.setReload(null))
        dispatch(authActions.setLoged(false))
        localStorage.removeItem('authTokens')
        }
        })
        .finally(function(){
            
        })
        return
    }

    
    const colorStyle= {
    
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px'
          }),
      
        control: (styles) => ({ ...styles, backgroundColor: "rgb(238,238,238)",
        minHeight: '30px',
        height: '30px',}),
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            width:"100px",
            maxWidth: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexDirection:"row",
            height:"30px",
            maxHeight: "30px"
          })
    }

    async function pageListChandler(e){
        setPage(e.value)
        setPageSelected({value:e.value,label:e.value})
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/account_list?page=${e.value}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
      
          .then(function(response){
              setPages(Array.from({length: pagesNumber(response.data.count,10)}, (_, i) => i + 1))
              setNext(response.data.next)
              setPrev(response.data.previous)
              setUsers(response.data.results)
          })
          .catch(function(error){
           
            if (error.toJSON().status==401){
              dispatch(authActions.setEmail(null))
          dispatch(authActions.setAccess(null))
          dispatch(authActions.setReload(null))
          dispatch(authActions.setLoged(false))
          localStorage.removeItem('authTokens')
          }
          })
          .finally(function(){
              
          })
          return
    }

    useEffect(()=>{
       
        getEmails()
    },[roles])


    useEffect(()=>{
        getAccounts()
        
          
    },[email,roles])

    useEffect(()=>{
        getAccounts()
        
          
    },[])
        

    return(

        <div className={styles.block}>
            <div className={styles.pageTitle}>Accounts</div>
            <div className={styles.filters}>
                <Dropdown className={`${styles.dropdown} ${styles.firstDropdown}`} placeholder="Select email" filter value={email} options={emailOptions} onChange={(e)=>setEmail(e.value)}></Dropdown>
                <MultiSelect className={`${styles.dropdown} ${styles.secondDropdown}`} placeholder="Select Role" filter value={roles} options={rolesOptions} onChange={(e)=>setRole(e.value)} display="chip"></MultiSelect>
            </div>
            <div className={styles.table}>
                {users.map((x)=> <UserBlock email={x.email} date={x.creation_date} company={x.company} id={x.email} admin={x.is_superuser} moderator={x.is_moderator} ambasador={x.is_ambasador}/>)}
            </div>
            <div className={styles.buttons}>
                    <div className={styles.pager}>
                        <Select

                            options={pages_list}
                            value={pageSelected}
                            styles={colorStyle}
                            onChange={pageListChandler}
                            allowSelectAll={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            menuPlacement="top"
                        />
                    </div>
                
                    {prev ? <button className={`${styles.prevBut}`} onClick={getPrev}>Prev</button>:<button className={`${styles.prevBut} ${styles.deactivated}`}>Prev</button>}
                    {next ?<button className={`${styles.nextBut}`} onClick={getNext}>Next</button>:<button className={`${styles.nextBut} ${styles.deactivated}`}>Next</button>}
            </div>
            <div className={styles.break}></div>
        </div>

    )
}
export default Accounts