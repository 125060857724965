import {useState,useEffect} from "react"
import axios from "axios"
import  Select  from "react-select";
import styles from "./select.module.css"
import { useSelector } from "react-redux";
import { authActions } from '../../../store/authSlice'
import { useDispatch} from 'react-redux'
import { manageEditActions } from "../../../store/manageEditSlice";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
function Selector(props){
    const dispatch=useDispatch()
    const [it,setIt]=useState(0)
    const[data,setData] =useState()
    const link=props.link
    const [selectedCities, setSelectedCities] = useState();
    const authToken=useSelector((state)=>state.auth.access)
    async function dataFetch(link){
        
        const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/${link}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            
            setData(response.data.map((x)=>({name:x.name,code:x.name})))
        
           
        })
        .catch(function(error){
           
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return
    }
    
    useEffect(()=>{
        dataFetch(link)
    },[])


    

    const handleChangeTest = (selected) => {
        setSelectedCities(selected.value
            )
        if (props.name=="Organ/system"){
         
            dispatch(manageEditActions.setOrgan(selected.value))
        }
        else if (props.name=="Key Event") {
            
            dispatch(manageEditActions.setKey(selected.value))
        }

        else if (props.name=="Guidance/strategy") {
         
            dispatch(manageEditActions.setTest(selected.value))
        }

        else if (props.name=="Endpoint") {
          
            dispatch(manageEditActions.setEndpoint(selected.value))
        }

        else if (props.name=="AOP") {
       
            dispatch(manageEditActions.setAop(selected.value))
        }

        else if (props.name=="Regulations") {
           
            dispatch(manageEditActions.setRegulations(selected.value))
        }

        else if (props.name=="Stage of NAM development") {
        
            dispatch(manageEditActions.setStage(selected.value))
        }

        else if (props.name=="Document type") {
   
            dispatch(manageEditActions.setType(selected.value))
        }

        else if (props.name=="MIE") {
       
            dispatch(manageEditActions.setMie(selected.value))
        }

        else if (props.name=="Nam type") {
       
            dispatch(manageEditActions.setNamType(selected.value))
        }
    }

    return(
        <div className={styles.bar}>
           
          <Dropdown value={selectedCities}  onChange={handleChangeTest} options={data} optionLabel="name" display="chip" filter
       placeholder="Select"  className={styles.mypanel}/>
        </div>
    )
}

export default Selector