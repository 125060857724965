import {createSlice} from '@reduxjs/toolkit'
const initialState={regulations:JSON.parse(localStorage.getItem('regulationsEdit')), organs:JSON.parse(localStorage.getItem('organEdit')),
endpoints:JSON.parse(localStorage.getItem('endpointsEdit')), aops:JSON.parse(localStorage.getItem('aopsEdit')),
stages:JSON.parse(localStorage.getItem('stageEdit')), tests:JSON.parse(localStorage.getItem('testEdit')),
name:JSON.parse(localStorage.getItem('nameEdit')), used:JSON.parse(localStorage.getItem('usedEdit')),
namType:JSON.parse(localStorage.getItem('typeEdit'))}
/*Slice najpierw klonuje i potem nadpisuje dlatego nie trzeba
kazdej wlasności wpisywać jeszcze raz i mozna uzywać ++*/
const ManageFiltersSlice = createSlice({
    name: "manageFilters",
    initialState:initialState,
    reducers:{
        setRegulations(state,value) {
            state.regulations=value
        },
        setOrgans(state,value) {
            state.organs=value
        },
        setEndpoints(state,value) {
            state.endpoints=value
        },
        setAops(state,value) {
            state.aops=value
        },
        setStages(state,value) {
            state.stages=value
        },
        setTests(state,value) {
            state.tests=value
        },
        
        setName(state,value){
            state.name=value
        },
        setUsed(state,value){
            state.used=value
        },
        setNamType(state,value){
            state.namType=value
        }
    }
})
export const manageFilterActions =ManageFiltersSlice.actions
export default ManageFiltersSlice.reducer