import {RiArrowDropDownLine,RiArrowDropUpLine} from 'react-icons/ri'
import styles from "./detRow.module.css"
import Selector from "./select"
import {Fragment, useState} from "react"
import Modal from './modal'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'
import ModalAdd from './modalAdd'
function DetRow(props){

    const authToken=useSelector((state)=>state.auth.access)
    
    const [drop,setDrop]=useState(0)
    const [openModal,setOpenModal]=useState(0)
    const [useEdit, setUseEdit] = useState(0)
    const [val1,setVal1]=useState(null)
    const [val2,setVal2]=useState(null)
    const [val3,setVal3]=useState(null)
    const [val4,setVal4]=useState(null)
    function clickChandler (){
        if (drop==0){
            setDrop(1)
        }

        if (drop==1){
            setDrop(0)
        }
    }

    function addModalChandler(e){
        setOpenModal(1)
    }

    function edditModalChandler(e){
       
        setOpenModal(2)
        
    }

    function infoAddModalChandler(e){
       
        setOpenModal(3)
        
    }

    async function getEdit(){
        const requestEdit=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/${props.link}?name=${props.store.code}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            const obLen=Object.keys(response.data[0]).length
            
            if (obLen==2){
                setVal1(response.data[0].name)
            }
            if (obLen==3){
                setVal1(response.data[0].name)
                setVal2(response.data[0].reference)
                
            }

            if (obLen==5){
                setVal1(response.data[0].name)
                setVal2(response.data[0].reference)
                setVal3(response.data[0].description)
                setVal4(response.data[0].title)
                
                
            }
          
           
        })
        .catch(function(error){
   
            
            
        })
        .finally(function(){
            
        })
        return
    }

    useEffect(()=>{
        getEdit()
        
    },[props.store])

    return(
        <Fragment>
            <div className={styles.row}>
                <button className={styles.rowBut} onClick={clickChandler}>
                    {drop==0? <RiArrowDropDownLine/> : <RiArrowDropUpLine/>}
                    {props.name=="Organ/system" ? <div className={styles.organ}>{props.name}</div>:
                    props.name=="Guidance/strategy" ?<div className={styles.guidance}>{props.name}</div>:
                    props.name=="Stage of NAM development" ?<div className={styles.stage}>{props.name}</div>:
                    <div className={styles.name}>{props.name}</div>}
                </button>
                <Selector link={props.link} name={props.name}/>
            </div>
            {drop==1 && 
                <div className={styles.buttons}>
                    <button onClick={addModalChandler} className={styles.addBut}>Add</button>
                    {openModal==1 && <Modal setOpenModal={setOpenModal} type="Add" name={props.name} link={props.link} place1={props.place1}
                    place2={props.place2} place3={props.place3} place4={props.place4}/>}
                    {openModal==2 && <Modal setOpenModal={setOpenModal} type="Eddit" name={props.name} link={props.link} place1={props.place1}
                    place2={props.place2} place3={props.place3} place4={props.place4} store={props.store} val1={val1} val2={val2} val3={val3} val4={val4} />}
                    {openModal==3 && <ModalAdd type={"added"}/>}
                    {openModal==4 && <ModalAdd type={"edited"}/>}
                    {props.store!="" &&<button onClick={edditModalChandler} className={styles.addBut}>Edit</button>}
                    {props.store=="" &&<button className={styles.blockedEdit}>Edit</button>}
                </div>
            }
        </Fragment>
    )
}


export default DetRow