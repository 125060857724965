import styles from "./accountDetails.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AccountLine from "./accountLine";
import UserStatus from "./userStatus";
import { Link } from "react-router-dom";
import { MdArrowLeft } from "react-icons/md";
function AccountDetails() {
	const { slug } = useParams();

	const authToken = useSelector((state) => state.auth.access);

	const [first, setFirst] = useState();
	const [last, setLast] = useState();

	const [country, setCountry] = useState();

	const [postalCode, setPostalCode] = useState();

	const [phone, setPhone] = useState();

	const [company, setCompany] = useState();

	const [nipVat, setNipVat] = useState();

	const [compCountry, setCompCountry] = useState();

	const [compPostal, setCompPostal] = useState();

	const [www, setWww] = useState();

	const [compNumber, setCompNumber] = useState();

	const [tokens, setTokens] = useState();

	const [isSub, setIsSub] = useState();

	const [isActiveSub, setIsActiveSub] = useState();

	const [isAdmin, setIsAdmin] = useState();

	const [isModerator, setIsModerator] = useState();

	const [isAmbasador, setIsAmbasador] = useState();

	const [trial, setTrial] = useState();

	const [confirmed, setConfirmed] = useState();

	async function getUserInfo() {
		const requestList = await axios
			.get(`${process.env.REACT_APP_DOMAIN}/api/getUser?email=${slug}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + String(authToken),
				},
			})

			.then(function (response) {
				setTokens(response.data.tokens);

				setIsSub(response.data.is_sub);

				setIsActiveSub(response.data.is_active_sub);

				setIsAdmin(response.data.is_superuser);

				setIsModerator(response.data.is_moderator);

				setIsAmbasador(response.data.is_ambasador);

				setTrial(response.data.trial_check);

				setConfirmed(response.data.is_confirmed);

				setFirst(response.data.name);

				setLast(response.data.surname);

				setCountry(response.data.country);

				setPhone(response.data.phone);

				setPostalCode(response.data.postal);

				setCompany(response.data.company);

				setCompCountry(response.data.company_country);

				setCompPostal(response.data.company_postal);

				setNipVat(response.data.nip_vat);

				setWww(response.data.www);

				setCompNumber(response.data.company_number);
			})
			.catch(function (error) {
				
			})
			.finally(function () {});
		return;
	}

	useEffect(() => {
		getUserInfo();
	}, []);

	return (
		<div className={styles.block}>
			<div className={styles.firstSection}>
				<Link to="/accounts" className={styles.back}>
					<MdArrowLeft className={styles.arrow} /> Go back to Accounts
				</Link>
				<div className={styles.accountBox}>
						<div className={styles.label}>Account infromation:</div>
						<UserStatus
							tokens={tokens}
							isSub={isSub}
							isActiveSub={isActiveSub}
							isAdmin={isAdmin}
							isModerator={isModerator}
							isAmbasador={isAmbasador}
							trial={trial}
							confirmed={confirmed}
							setIsAdmin={setIsAdmin}
							setIsModerator={setIsModerator}
							setIsAmbasador={setIsAmbasador}
							email={slug}
							setConfirmed={setConfirmed}
						/>
				</div>
			</div>
			<div className={styles.secondSection}>
				<div className={styles.row}>
					<div className={styles.userBox}>
						<div className={`${styles.label} ${styles.labelMargin}`}>User information:</div>
						<div className={styles.infoBox}>
							<AccountLine label="Email:" data={slug} />
							<AccountLine label="First Name:" data={first} />
							<AccountLine label="Last Name:" data={last} />
							<AccountLine label="Country:" data={country} />
							<AccountLine
								label="Postal Code:"
								data={postalCode}
							/>
							<AccountLine label="Phone Number:" data={phone} />
						</div>
					</div>
					<div className={styles.userBox}>
						<div className={`${styles.label} ${styles.labelMargin}`}>Company information:</div>
						<div className={styles.infoBox}>
							<AccountLine label="Company:" data={company} />
							<AccountLine
								label="Company NIP/VAT:"
								data={nipVat}
							/>
							<AccountLine
								label="Country of Company:"
								data={compCountry}
							/>
							<AccountLine
								label="Company Postal Code:"
								data={compPostal}
							/>
							<AccountLine label="Website:" data={www} />
							<AccountLine
								label="Company Phone Number:"
								data={compNumber}
							/>
						</div>
					</div>
					
				</div>
				<div className={styles.break}></div>
			</div>
		</div>
	);
}
export default AccountDetails;
