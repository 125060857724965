import { components } from "react-select";
import  Select  from "react-select";
import { useSelector, useDispatch } from 'react-redux'
import {manageFilterActions} from  '../../../store/manageFilterSlice'
import { addActions } from "../../../store/addSlice";
import {useEffect} from 'react'
import styles from "./dropdown_F.module.css"
import { MultiSelect } from 'primereact/multiselect';
import {useEffetc,useState} from 'react'
function DropdownOrgan(props) {
    const dispatch = useDispatch()
    
    const [selectedCities, setSelectedCities] = useState();
    const name=props.name
    const [isStart,setIsStart]=useState(0)
    let dataSes=null
    if (name=='organ'){
        dataSes=JSON.parse(localStorage.getItem('organEdit'))
    }
    if (name=='test'){
        dataSes=JSON.parse(localStorage.getItem('testEdit'))
    }
    if (name=='regulations'){
        dataSes=JSON.parse(localStorage.getItem('regulationsEdit'))
    }
    if (name=='stage'){
        dataSes=JSON.parse(localStorage.getItem('stageEdit'))
    }
    if (name=='endpoints'){
        dataSes=JSON.parse(localStorage.getItem('endpointsEdit'))
    }
    if (name=='aops'){
        dataSes=JSON.parse(localStorage.getItem('aopsEdit'))
    }
    if (name=='type'){
        dataSes=JSON.parse(localStorage.getItem('typeEdit'))
    }
    if (name=='used'){
        dataSes=JSON.parse(localStorage.getItem('usedEdit'))
    }
    if (name=='name'){
        dataSes=JSON.parse(localStorage.getItem('nameEdit'))
    }
    
    const handleChangeTest  = (selected) => {
        
       
        setSelectedCities(selected.value
             )
       
        
       
      
       if (props.name==="organ"){
       
            if (selected.length!=0){
              
                dispatch(manageFilterActions.setOrgans(selected.value))
                localStorage.setItem('organEdit', JSON.stringify(selected.value))
            
            }
            else{   
             
                dispatch(manageFilterActions.setOrgans())  
                localStorage.removeItem('organEdit')
                
            }
        }

        if (props.name==="name"){
       
            if (selected.length!=0){
              
                dispatch(manageFilterActions.setName(selected.value))
                localStorage.setItem('nameEdit', JSON.stringify(selected.value))
                
            
            }
            else{   
             
                dispatch(manageFilterActions.setName())  
                localStorage.removeItem('nameEdit')
                
            }
        }

        if (props.name==="test"){
            if (selected.length!=0){
                dispatch(manageFilterActions.setTests(selected.value))
                localStorage.setItem('testEdit', JSON.stringify(selected.value))
       
            }
            else{   
                dispatch(manageFilterActions.setTests())  
                localStorage.removeItem('testEdit')
            }
        }

        if (props.name==="regulations"){

          if (selected.length!=0){
              dispatch(manageFilterActions.setRegulations(selected.value))
              localStorage.setItem('regulationsEdit', JSON.stringify(selected.value))
          
          }
          else{   
              dispatch(manageFilterActions.setRegulations())  
              localStorage.removeItem('regulationsEdit')
          }
      }

      if (props.name==="stage"){
   
        if (selected.length!=0){
            dispatch(manageFilterActions.setStages(selected.value))
            localStorage.setItem('stageEdit', JSON.stringify(selected.value))
   
        }
        else{   
            dispatch(manageFilterActions.setStages())  
            localStorage.removeItem('stageEdit')
        }
    }

    if (props.name==="endpoints"){
  
      if (selected.length!=0){
          dispatch(manageFilterActions.setEndpoints(selected.value))
          localStorage.setItem('endpointsEdit', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(manageFilterActions.setEndpoints())  
          localStorage.removeItem('endpointsEdit')
      }
  

    }

    if (props.name==="aops"){
   
      if (selected.length!=0){
          dispatch(manageFilterActions.setAops(selected.value))
          localStorage.setItem('aopsEdit', JSON.stringify(selected.value))
      
      }
      else{   
          dispatch(manageFilterActions.setAops())  
          localStorage.removeItem('aopsEdit')
      }
  

    }

    if (props.name==="type"){
   
      if (selected.length!=0){
          dispatch(manageFilterActions.setNamType(selected.value))
          localStorage.setItem('typeEdit', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(manageFilterActions.setNamType())  
          localStorage.removeItem('typeEdit')
      }
  

    }


    if (props.name==="used"){
   
      if (selected.length!=0){
          dispatch(manageFilterActions.setUsed(selected.value))
          localStorage.setItem('usedEdit', JSON.stringify(selected.value))
     
      }
      else{   
          dispatch(manageFilterActions.setUsed())  
          localStorage.removeItem('usedEdit')
      }
  

    }

    


    

    }

    const colorStyle= {
    
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
      
        control: (styles) => ({ ...styles, backgroundColor: "rgb(238,238,238)",
        minHeight: '30px',
        height: '30px',}),
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            width:"250px",
            maxWidth: "250px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexDirection:"row",
            height:"30px",
            maxHeight: "30px"
          })
    }

    useEffect(()=>{
       
        
        
        
        
        
      
    },[props.data])
    
    return(
        <div>
           {isStart==0 && <div><MultiSelect value={dataSes} onChange={handleChangeTest} options={[...new Set(dataSes.concat(props.data).map(item => item.name))].sort().map(item=>({name:item,code:item}))} optionLabel="name" display="chip"
       placeholder="Select"  className={styles.mypanel} filter/></div>}
            {isStart==1 && <div>
                <MultiSelect value={selectedCities} onChange={handleChangeTest} options={[...new Set(dataSes.concat(props.data).map(item => item.name))].map(item=>({name:item,code:item}))} optionLabel="name" display="chip"
       placeholder="Select"  className={styles.mypanel} />
                </div>}
          
        </div>
    )
}
export default DropdownOrgan