import styles from "./filters.module.css"
import {useState, useEffect} from 'react'
import {RiArrowDropDownLine,RiArrowDropUpLine} from 'react-icons/ri'
import FiltersList from "./FiltersList"
import { manageFilterActions } from "../../../store/manageFilterSlice"
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '../../../store/authSlice'
import Name from "./name"
function Filters(props){


    
    const authToken=useSelector((state)=>state.auth.access)
    const stages= useSelector((state) => state.manageFilter.stages)
    const organs= useSelector((state) => state.manageFilter.organs)
   
    const endpoints= useSelector((state) => state.manageFilter.endpoints)
    const tests= useSelector((state) => state.manageFilter.tests)
    const regulations = useSelector((state)=>state.manageFilter.regulations)
    const aops= useSelector((state)=>state.manageFilter.aops)
    const used= useSelector((state)=>state.manageFilter.used)
    const namType= useSelector((state)=>state.manageFilter.namType)
    const name= useSelector((state)=>state.manageFilter.name)


    let nameValue=[]
    if (name!=null){
    if (name.payload){
        nameValue=name.payload.map(x=>x.name)
    }
    else{
        nameValue=name.map(x=>x.code)
    }}
  
    
    const dispatch = useDispatch()
    const [filterSearch,setFilterSearch]=useState(0)
    function filterChandler(){
        if (filterSearch==0){
            setFilterSearch(1)
        }

        if (filterSearch==1){
            setFilterSearch(0)
        }
        
    }

    
    
    return(
    <div className={styles.block}>

        <div className={styles.rowName}>
            <div className={styles.title}>Search by name:</div>
            <Name stages={stages} endpoints={endpoints} tests={tests} regulations={regulations} aops={aops} used={used} namType={namType} authToken={authToken} organs={organs}/>
        </div>

        <div className={styles.row}>
            <div className={styles.title}>Search by filter:</div>
            <button onClick={filterChandler} className={styles.filterButton}>
                {filterSearch==0 ? <RiArrowDropDownLine/>:<RiArrowDropUpLine/>}
                <div>Click to expand</div>
            </button>
            
        </div>
        {filterSearch==1 &&
            <FiltersList name={name}  stages={stages} endpoints={endpoints} tests={tests} regulations={regulations} aops={aops} used={used} namType={namType} authToken={authToken} organs={organs}/>
        }

      
    </div>
    )
}

export default Filters