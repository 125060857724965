
import styles from "./filter.module.css"
import Organ from "./organ";
import Test from "./testing";
import Regulations from "./regulations";
import Stage from "./stage";
import Endpoints from "./endpoints";
import Aop from "./aop";
import NamType from "./namType";
import Used from "./used";
function FiltersList(props){
    
    
    return(
        
        <div className={styles.row}>
               <div className={styles.column}>
                <div className={styles.rowInRow2}>
                    <div>Organ/system:</div>
                    <Organ name={props.name} stages={props.stages} endpoints={props.endpoints} tests={props.tests} regulations={props.regulations} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} organ={props.organ}/>
                </div>

                <div className={styles.rowInRow2}>
                    <div className={styles.guidance}>Guidance/strategy:</div>
                    <Test name={props.name} stages={props.stages} endpoints={props.endpoints} organs={props.organs} regulations={props.regulations} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} tests={props.tests}/>
                </div>


                <div className={styles.rowInRow2}>
                    <div>Endpoint:</div>
                    <Endpoints name={props.name} regulations={props.regulations} stages={props.stages} organs={props.organs} tests={props.tests} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} endpoints={props.endpoints}/>
                </div>

                <div className={styles.rowInRow2}>
                    <div>AOP:</div>
                    <Aop name={props.name} regulations={props.regulations} stages={props.stages} organs={props.organs} tests={props.tests} endpoints={props.endpoints} used={props.used} namType={props.namType} authToken={props.authToken} aops={props.aops}/>
                 
                </div>      
                 
                 
                 
                 
                 
                </div>
            
            <div className={styles.column}>

                 <div className={styles.rowInRow2}>
                    <div>Regulation(s):</div>
                    <Regulations name={props.name} stages={props.stages} endpoints={props.endpoints} organs={props.organs} tests={props.tests} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} regulations={props.regulations}/>
                 </div>
                 <div className={styles.rowInRow2}>
                    <div>Method Type:</div>
                    <NamType name={props.name} regulations={props.regulations} stages={props.stages} organs={props.organs} tests={props.tests} endpoints={props.endpoints} used={props.used} aops={props.aops} authToken={props.authToken} namType={props.namType}/>
                 </div>

                 <div className={styles.rowInRow2}>
                    <div>Applied:</div>
                    <Used name={props.name} regulations={props.regulations} endpoints={props.endpoints} organs={props.organs} test={props.tests} aops={props.aops} stages={props.stages} namType={props.namType} authToken={props.authToken} used={props.used}/>
                 </div>
                 <div className={styles.rowInRow2}>
                    <div>Current stage for ENMs:</div>
                    <Stage name={props.name} regulations={props.regulations} endpoints={props.endpoints} organs={props.organs} tests={props.tests} aops={props.aops} used={props.used} namType={props.namType} authToken={props.authToken} stages={props.stages}/>
                 </div>
            </div>
        </div>
    )
}

export default FiltersList