import Modal from "react-modal";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import styles from "./ModalNews.module.css"

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width:'500px',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

function ModalNews(props){
    
    const AppElem = document.getElementById('root');
    Modal.setAppElement(AppElem);

    const authToken=useSelector((state)=>state.auth.access)

    const author=useSelector((state)=>state.auth)

    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [date, setDate] = useState("");
    // const [star, setStar] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
       

        let jsonBody = JSON.stringify({
            title: title,
            date: date,
            text: text,
            author: author.email,
            stared: false
        })
        

        const config = {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            },
          };
          //https://nams.network/news/
          //http://127.0.0.1:8000/api/news_test
          await axios.post(`${process.env.REACT_APP_DOMAIN}/api/news_test`, jsonBody, config)
        
          await axios.get(`${process.env.REACT_APP_DOMAIN}/api/news_test_get`, config)
            .then(function(response){
                props.setNews(response.data)
                setText("")
                setTitle("")
                setDate("")
            }).catch(function(error){
                 
            })

        props.onRequestClose(); 
      };

    return (
        <div>
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                style={customStyles}
            >
                <Form onSubmit={submitHandler}>
                    <h4 className={styles.txt}>
                        Add News
                    </h4>
                    <Form.Group className={`${styles.wrapDiv} mb-3`} controlId="formTitle">
                        <Form.Label className={styles.label}>Title: </Form.Label>
                        <Form.Control 
                        type="text"
                        className={styles.field}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter Title"
                        required
                        />
                    </Form.Group>
                    <Form.Group className={`${styles.wrapDiv} mb-3`} controlId="formDate">
                        <Form.Label className={styles.label}>Date: </Form.Label>
                        <Form.Control 
                        type="date"
                        className={styles.field}
                        value={date} 
                        onChange={(e) => setDate(e.target.value)}
                        placeholder="Date"
                        max={new Date("3000-01-01")}
                        required
                        />
                    </Form.Group>
                    <Form.Group className={`${styles.wrapDiv} mb-3`} controlId="formText">
                        <Form.Label className={styles.label}>Text: </Form.Label>
                        <Form.Control
                        as="textarea"
                        className={styles.area} 
                        value={text} 
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Enter text" 
                        required
                        />
                    </Form.Group>
                    <div className={`${styles.wrapDiv} ${styles.btns}`}>
                        <Button className={styles.buttonSave} variant="primary" type="submit">SAVE</Button>
                        <Button className={styles.buttonCancel} onClick={props.onRequestClose}>CANCEL</Button>
                    </div>
                </Form>
                
            </Modal>
        </div>

    )
}

export default ModalNews