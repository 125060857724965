import { Fragment } from "react"
import styles from "./mainForm.module.css"
import SelectorAdd from "./selectorAdd"
import MultiSelectorAdd from "./multiSelectiorAdd"
function MainForm(){
    
    const labels1 = [
        {id:1,label: "Organ/system:",link: "organ",name:"Organ/system"},
        {id:2, label:"NAM type:",link:"namType",name:"NAM Type"},
        {id:3,label:"Endpoint:",link: "endpoint",name:"Endpoint"},
        {id:4,label:"Document type:",link: "type",name:"Type"},
        {id:5,label:"Guidance/strategy:",link: "test",name:"Guidance/strategy"},
        {id:6,label:"Regulation(s):",link: "regulations",name:"regulations"}
           
    ]

    const labels2=[
        {id:7,label:"Stage of NAM development:",link: "stage",name:"Stage of NAM development"},
        {id:8,label: "Applied to ENMs:",link: "applied",name:"Applied to ENMs"},
        {id:9,label:"Nano-validated:",link: "nam",name:"Nano Validated"},
        {id:10,label:"AOP:",link: "aop",name:"AOP"},
        {id:11,label:"Key Event:",link: "key_event",name:"Key Event"},
        {id:12,label:"MIE:",link: "mie",name:"MIE"},
        
    ]
    


    return(
        <Fragment>
            <div className={styles.row}>
                <div className={styles.column}>
                    
                            {labels1.map(label=>                 
                                 <div className={styles.rowInRow2}>
                                   {label.label=="Organ/system:" ? <div className={styles.organ}>{label.label}</div>:label.label=="Guidance/strategy:" ? <div className={styles.guidance}>{label.label}</div>:
                                   <div >{label.label}</div>}
                                   {label.link=="regulations" ? <MultiSelectorAdd link={label.link}  name={label.name}/>:
                                    <SelectorAdd  link={label.link} value={label.value} name={label.name}/>}
                                  
                                 </div>          
                                        )}
                </div>
           
                        
                   
                

                

                <div className={styles.column}>
                    
                            {labels2.map(label=> 
                                <div className={styles.rowInRow}>
                                    {label.label=="Applied to ENMs:" ? <div className={styles.applied}>{label.label}</div>:label.label=="Stage of NAM development:" ? <div className={styles.stage}>{label.label}</div>:
                                   <div >{label.label}</div>}
                                    {label.link=="regulations" ? <MultiSelectorAdd link={label.link}  name={label.name}/>:
                                    <SelectorAdd  link={label.link} value={label.value} name={label.name}/>
                                
                                    }
                                </div>
                            )}
                </div>
                            
                        
                        
            </div>

                    
                    
              
           
           
        </Fragment>
    )
}
export default MainForm