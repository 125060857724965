import styles from "./Filter.module.css"
import { MultiSelect } from "primereact/multiselect"
function Filter(props){
    return(
        <div className={styles.block}>
            <div className={styles.search}>Search</div>
            <div className={styles.filterList}>
                
                <div className={styles.filterItem}>
                    <div className={styles.filterLabel}>
                        Name:
                    </div>
                    <MultiSelect className={styles.select} options={props.nameOptions} value={props.name} onChange={(e)=>(props.setName(e.value))} optionLabel="name" display="chip"/>
                </div>
                <div className={styles.filterItem}>
                    <div className={styles.filterLabel}>
                        Country:
                    </div>
                    <MultiSelect className={styles.select} options={props.countryOptions} value={props.country} onChange={(e)=>(props.setCountry(e.value))} optionLabel="name" display="chip"/>
                </div>
                    
             
            </div>
        </div>
    )
}
export default Filter