 import styles from "./manageMenu.module.css"
 import MetaDataProvider from "./metaDataProvider"
 import Filter from "../../explore/menu/filter/filter"
 import RealMetaData from "./realMetaData"
 import { useEffect } from "react"
 import { addActions } from "../../../store/addSlice"
import { useDispatch } from "react-redux"
 function  ManageMenu(){
    const dispatch=useDispatch()
    useEffect(() => {
        dispatch(addActions.setName(null))
        dispatch(addActions.setOrgans(null))
        dispatch(addActions.setAops(null))
        dispatch(addActions.setVal(null))
        dispatch(addActions.setUsed(null))
        dispatch(addActions.setNamType(null))
        dispatch(addActions.setTests(null))
        dispatch(addActions.setRegulations(null))
        dispatch(addActions.setMie(null))
        dispatch(addActions.setStages(null))
        dispatch(addActions.setType(null))
        dispatch(addActions.setKeyEvent(null))
        dispatch(addActions.setEndpoints(null))
      },[]);
    
    return (
        <div className={styles.menuBlock}>
            <div className={styles.title}>Add new NAM</div>
            <div className={styles.subtitle}>Set NAM primary details:</div>
            <MetaDataProvider/>
            
            <div className={styles.subtitle2}>Provide metadata:</div>
            <RealMetaData/>
            <div className={styles.break}></div>
        </div>
    )
 }

 export default ManageMenu